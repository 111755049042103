import { StyleSheet, TouchableOpacity } from "react-native";
import React from "react";
import { Text } from "../Themed";
import Colors from "../../constants/Colors";

interface HeaderProps {
  label: string;
  onPress: () => void;
  active: boolean;
}

const FilterPill: React.FC<HeaderProps> = ({
  label,
  onPress,
  active,
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.body, active && styles.active]}>
      <Text style={[styles.label, active && styles.activeLabel]}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  body: {
    borderWidth: 2,
    borderColor: Colors.light.brown,
    borderRadius: 10,
    paddingVertical: 3,
    paddingHorizontal: 10,
    marginHorizontal: 4,
  },
  active: {
    backgroundColor: Colors.light.brown,
  },
  label: {
    fontFamily: "spectral-700",
    color: Colors.light.brown,
    fontSize: 16,
  },
  activeLabel: {
    color: 'white'
  },
});

export default FilterPill;
