import React, { useState } from "react";
import { View, Text, TouchableOpacity, Modal } from "react-native";
import Colors from "../../constants/Colors";

const CustomAlert = ({
  visible,
  pressOne,
  pressTwo,
  title,
  message,
  optionOne,
  optionTwo,
}) => {
  return (
    <Modal visible={visible} transparent>
      <View style={styles.modalContainer}>
        <View style={styles.alertContainer}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.message}>{message}</Text>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              pressOne();
            }}
          >
            <Text style={styles.buttonText}>{optionOne}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              pressTwo();
            }}
          >
            <Text style={styles.buttonText}>{optionTwo}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};
export default CustomAlert;

const styles = {
  modalContainer: {
    flex: 1,

    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  alertContainer: {
    maxWidth: 460,
    backgroundColor: Colors.light.background,
    padding: 16,
    borderRadius: 8,
    width: "80%",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
  },
  message: {
    marginBottom: 16,
    textAlign: "center",
  },
  button: {
    backgroundColor: Colors.light.brown,
    padding: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 8,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
};
