import { Feather } from "@expo/vector-icons";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextInput, StyleSheet, TouchableOpacity, View } from "react-native";
import Colors from "../../constants/Colors";
import { Text } from "../Themed";

interface Props {
  required?: boolean;
  name: string;
  placeholder: string;
  control: any;
  errors: any;
  autoCapitalize?: "none" | "sentences" | "words" | "characters";
  keyboardType?: "default" | "email-address" | "numeric" | "phone-pad";
  secureEntry?: boolean;
  multiline?: boolean;
  height?: number;
  search?: boolean;
  testID?: string;
  onBlur?: () => void;
  onSubmitEditing?: () => void;
  returnKeyType?: "done" | "go" | "next" | "search" | "send";
  textContentType?;
  maxLength?: number;
}

const Input: React.FC<Props> = ({
  required,
  name,
  placeholder,
  control,
  errors,
  autoCapitalize = "sentences",
  keyboardType = "default",
  secureEntry = false,
  multiline = false,
  height = 40,
  search = false,
  testID = "input",
  onBlur = () => {},
  returnKeyType = null,
  onSubmitEditing = () => {},
  textContentType = "none",
  maxLength = null,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<TextInput>(null);

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, value } }) => (
          <View
            style={{
              height: 80,
              width: "100%",
            }}
          >
            <Text style={styles.label}>
              {placeholder}{" "}
              {errors[name] && (
                <Text style={styles.error}>*This is required</Text>
              )}
            </Text>
            <View style={styles.iconContainer}>
              {secureEntry ? (
                <TouchableOpacity
                  onPress={() => setShowPassword(!showPassword)}
                  style={styles.seePassword}
                  hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                >
                  <Feather
                    name={!showPassword ? "eye" : "eye-off"}
                    size={18}
                    color={Colors.light.brown}
                  />
                </TouchableOpacity>
              ) : null}
              <TextInput
                ref={inputRef}
                testID={testID}
                returnKeyType={returnKeyType}
                onSubmitEditing={onSubmitEditing}
                multiline={multiline}
                style={[styles.input, { height: height ? height : 40 }]}
                onBlur={() => onBlur(value)}
                onChangeText={onChange}
                placeholderTextColor={"#bdb4ae"}
                value={value}
                placeholder={placeholder}
                autoCapitalize={autoCapitalize}
                keyboardType={keyboardType}
                secureTextEntry={secureEntry ? !showPassword : false}
                textContentType={textContentType}
                maxLength={maxLength}
              />
            </View>
            {search ? (
              <TouchableOpacity
                onPress={() => {
                  if (value !== "") {
                    onChange("");
                  } else {
                    inputRef.current?.focus();
                  }
                }}
                style={styles.searchIcon}
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
              >
                <Feather
                  name={value?.length > 0 ? "x" : "search"}
                  size={20}
                  color={Colors.light.brown}
                />
              </TouchableOpacity>
            ) : null}
          </View>
        )}
        name={name}
      />
    </>
  );
};

const styles = StyleSheet.create({
  searchIcon: {
    position: "absolute",
    right: 12,
    top: 36,
  },
  seePassword: {
    position: "absolute",
    top: 11,
    right: 10,
    zIndex: 10,
  },
  iconContainer: {
    width: "100%",
  },
  input: {
    width: "100%",
    marginBottom: 12,
    padding: 10,
    borderWidth: 1.5,
    borderColor: "#736B65",
    borderRadius: 3,
    backgroundColor: "#fff",
  },
  label: {
    fontSize: 16,
    color: "#736B65",
    textAlign: "left",
    marginBottom: 2,
    width: "100%",
    fontFamily: "spectral-600",
  },
  error: {
    color: Colors.light.danger,
    fontSize: 12,
  },
});

export default Input;
