import React from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Dimensions,
} from "react-native";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Dropdown from "../../components/Dropdown";
import instanceAXIOS from "../../services/api";
import Toast from "react-native-toast-message";
import { useGetUsersByRole } from "../../hooks/users/useGetUsersByRole";
import { useQueryClient } from "react-query";

const width = Dimensions.get("window").width;

export default function AddUser({ navigation }: RootTabScreenProps<"TabOne">) {
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [rolesOpen, setRolesOpen] = React.useState(false);
  const [locationOpen, setLocationOpen] = React.useState(false);
  const [role, setRole] = React.useState(null);
  const [roles, setRoles] = React.useState([
    { label: "Admin", value: "admin" },
    { label: "Manager", value: "manager" },
    { label: "Staff", value: "staff" },
    { label: "Client", value: "client" },
  ]);
  const [location, setLocation] = React.useState(null);
  const [locations, setLocations] = React.useState([
    { label: "Utah", value: "utah" },
    { label: "Georgia", value: "georgia" },
  ]);
  const [managersOpen, setManagersOpen] = React.useState(false);
  const [manager, setManager] = React.useState(null);
  const [managers, setManagers] = React.useState([
    { label: "Unassigned", value: "" },
  ]);
  const { data: adminAndManagers } = useGetUsersByRole(
    1,
    50,
    ["Admin", "Manager"],
    "adminAndManagers"
  );

  React.useEffect(() => {
    adminAndManagers &&
      setManagers([
        { label: "Unassigned", value: null },
        ...adminAndManagers?.items?.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.uuid,
        })),
      ]);
  }, [adminAndManagers]);

  React.useEffect(() => {
    setValue("role", role);
  }, [role]);

  React.useEffect(() => {
    setValue("assignedToUserUuid", manager);
  }, [manager]);

  React.useEffect(() => {
    setValue("location", location);
  }, [location]);

  const onSubmit = (data: AddUserProps) => {
    instanceAXIOS
      .post("/User", {
        firstName: data?.firstName,
        lastName: data.lastName,
        email: data.email.toLowerCase(),
        role: data.role,
        location: data.location,
        assignedToUserUuid: data.assignedToUserUuid,
      })
      .then((response) => {
        setValue("firstName", "");
        setValue("lastName", "");
        setValue("email", "");
        setRole(null);
        setManager(null);
        setLocation(null);
        navigation.goBack();
        Toast.show({
          type: "success",
          props: {
            message: "User created successfully",
          },
        });
        queryClient.invalidateQueries("assignedUsers");
      });
  };

  return (
    <KeyboardAvoidingView style={styles.container} behavior={"height"}>
      <Header goBack />
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.innerContainer}>
          <View style={styles.inner}>
            <Input
              name={"firstName"}
              placeholder={"First Name"}
              control={control}
              errors={errors}
              required
            />
            <Input
              name={"lastName"}
              placeholder={"Last Name"}
              control={control}
              errors={errors}
              required
            />
            <Input
              name="email"
              placeholder="Email"
              autoCapitalize="none"
              control={control}
              errors={errors}
              required
              keyboardType="email-address"
            />
            <Dropdown
              name={"role"}
              label={"Roles"}
              control={control}
              errors={errors}
              open={rolesOpen}
              setOpen={setRolesOpen}
              value={role}
              items={roles}
              setValue={setRole}
              setItems={setRoles}
              width={width - 40}
              required
            />
            <View style={{ height: 10 }} />
            {!rolesOpen && managers && role !== "client" && (
              <Dropdown
                name={"assignedToUserUuid"}
                label={"Manager"}
                control={control}
                errors={errors}
                open={managersOpen}
                setOpen={setManagersOpen}
                value={manager}
                items={managers}
                setValue={setManager}
                setItems={setManagers}
                required={false}
                width={width - 40}
              />
            )}
            <View style={{ height: 10 }} />
            {!managersOpen && !rolesOpen && role !== "client" && (
              <Dropdown
                name={"location"}
                label={"Location"}
                control={control}
                errors={errors}
                open={locationOpen}
                setOpen={setLocationOpen}
                value={location}
                items={locations}
                setValue={setLocation}
                setItems={setLocations}
                required={true}
                width={width - 40}
              />
            )}
          </View>
          {!managersOpen && !rolesOpen && !locationOpen && (
            <Button label={"Save User"} onPress={handleSubmit(onSubmit)} />
          )}
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 10,
  },
  innerContainer: {
    flex: 1,
    alignItems: "center",
    width: "100%",
  },
  buttonContainer: {
    marginTop: 15,
    width: "100%",
    alignItems: "center",
  },
  title: {
    fontSize: 13,
    textAlign: "center",
    fontWeight: "600",
  },
  inner: {
    backgroundColor: "#fff",
    marginBottom: 10,
    width: "100%",
    padding: 10,
    borderRadius: 6,
  },
  managerInner: {
    backgroundColor: "#fff",
    height: "50%",
    width: "100%",
    justifyContent: "space-evenly",
    padding: 10,
    borderRadius: 6,
  },
  flatListContainer: {
    flex: 1,
    justifyContent: "space-between",
    padding: 10,
  },
  error: {
    color: "red",
    fontSize: 12,
    marginTop: 4,
  },
});

export type AddUserProps = {
  firstName: string;
  lastName: string;
  email: string;
  role: [string];
  location: string;
  assignedToUserUuid: string;
};
