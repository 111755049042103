import { Platform, Dimensions, StyleSheet } from "react-native";
import React, { FC } from "react";
import { Text, View } from "../Themed";
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { Image, ImageContentFit } from "expo-image";
import Colors from "../../constants/Colors";
import { TouchableOpacity } from "react-native-gesture-handler";

interface ExpoImageProps {
  source: string;
  cacheKey: string;
  _height?: number;
  _width?: number;
  onRefresh?: () => void;
  onLoadEnd?: () => void;
  contentFit?: ImageContentFit;
  placeholder?: string;
  transition?: number;
  priority?: "high" | "low";
}

const ExpoImage: FC<ExpoImageProps> = ({
  source,
  cacheKey,
  _height,
  _width,
  onRefresh = null,
  onLoadEnd = null,
  contentFit = "cover",
  placeholder = "L9PPouRkQ.I9t3?wxuDhMdV@yEog",
  priority = "high",
  transition = 100,
  ...rest
}) => {
  const offset = useSharedValue(0);
  const [showRefresh, setShowRefresh] = React.useState(false);

  const _onProgress = (p: any) => {
    offset.value = withTiming(p.progress, { duration: 100 });
  };

  const _setRefresh = () => {
    setShowRefresh(true);
  };

  const progressAnimatedStyle = useAnimatedStyle(() => {
    return {
      width: offset?.value * (_width ? _width - 20 : -20),
      opacity:
        Platform.OS === "ios"
          ? interpolate(offset?.value, [0, 0.9, 1], [1, 1, 0])
          : 0,
    };
  });

  return (
    <>
      <Image
        style={[
          styles.image,
          {
            height: _height,
            width: _width,
          },
        ]}
        priority={priority}
        cachePolicy="memory"
        source={{ uri: source, cacheKey: cacheKey }}
        placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
        contentFit={contentFit}
        transition={transition}
        onProgress={(p) => {
          _onProgress(p);
        }}
        onLoadEnd={() => {
          onLoadEnd && onLoadEnd();
        }}
        onError={(e) => {
          _setRefresh();
        }}
      />
      {showRefresh && onRefresh ? (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text>Unable to load image.</Text>
          <Text>Try to refresh the page.</Text>
          <TouchableOpacity
            style={{
              backgroundColor: Colors.light.navy,
              padding: 8,
              paddingHorizontal: 16,
              marginTop: 18,
              borderRadius: 8,
            }}
            onPress={() => {
              if (onRefresh) {
                onRefresh();
              }
            }}
          >
            <Text
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              Refresh
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
      <Animated.View
        style={[
          progressAnimatedStyle,
          {
            position: "absolute",
            bottom: 20,
            borderRadius: 15,
            left: 10,
            right: 0,
            height: 30,
            backgroundColor: Colors.light.navy,
            zIndex: 30,
            alignItems: "center",
            flexDirection: "row",
          },
        ]}
      >
        <Text
          style={{
            paddingLeft: 8,
            color: "white",
            fontWeight: "bold",
          }}
        >
          Loading..
        </Text>
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  image: {

    borderRadius: 6,
    marginBottom: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
});

export default ExpoImage;
