import React, { FC, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Keyboard,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import { instanceAXIOS } from "../../services/api";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";
import Header from "../../components/Header";
import { useQueryClient } from "react-query";
import toastConfig from "../../util/ToastConfig";

const { width } = Dimensions.get("window");

interface UpdateEmailProps {}

const UpdateEmail: FC<UpdateEmailProps> = ({ route }) => {
  const { goBack } = useNavigation();
  const queryClient = useQueryClient();

  const { userEmail, userUuid } = route.params;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("email", userEmail);
  }, [userEmail]);

  const _updateEmail = (data: any) => {
    instanceAXIOS
      .post(`/User/UpdateEmail`, {
        email: data.email.toLowerCase(),
        uuid: userUuid,
      })
      .then((res) => {
        Toast.show({
          type: "success",
          props: {
            message: "User Email updated",
          },
        });
        queryClient.invalidateQueries("orderSummary");
        queryClient.invalidateQueries("orderDetails");
        Alert.alert(
          "Email updated successfully, Registration email sent to new email."
        );
        goBack();
      })
      .catch((error) => {
        Alert.alert("Unable to update email", `User with email ${data.email} already exists`);
      });
  };

  return (
    <Pressable onPress={() => Keyboard.dismiss()} style={styles.body}>
      <Toast config={toastConfig} />
      <View style={styles.width}>
        <Header isModal goBack title={"Update Email"} />
      </View>
      <Input
        keyboardShouldPersistTaps="handled"
        required
        name="email"
        placeholder="Email"
        control={control}
        errors={errors}
        autoCapitalize="none"
        keyboardType="email-address"
      />
      <View style={styles.buttonContainer}>
        <Button
          backgroundColor={Colors.light.brown}
          label={"Save"}
          onPress={() => {
            handleSubmit(_updateEmail)();
          }}
        />
      </View>
    </Pressable>
  );
};
export default UpdateEmail;

const styles = StyleSheet.create({
  body: {
    alignItems: "center",
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 20,
  },
  width: {
    width: width,
    marginBottom: 100,
  },
  buttonContainer: {
    position: "absolute",
    bottom: 40,
    justifyContent: "center",
    alignItems: "center",
  },
});
