import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Alert,
  Platform,
  Dimensions,
} from "react-native";
import { Modalize } from "react-native-modalize";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Toast from "react-native-toast-message";
import ActionButton from "../../components/ActionButton";
import Header from "../../components/Header";
import Input from "../../components/Input";
import UploadFrame from "../../components/UploadFrame";
import Colors from "../../constants/Colors";
import { useClientApproveDisapprove } from "../../hooks/orders/useClientApproveDisapprove";
import { useGetConversationUsers } from "../../hooks/messaging/useGetConversationUsers";
import Context from "../../context";
import { useQueryClient } from "react-query";
import addUserToConversation from "../../hooks/messaging/useAddUserToConversation";
import useSendMessage from "../../hooks/messaging/useSendMessage";
import { capitalizeFirstLetter } from "../../services/utils";

const { width, height } = Dimensions.get("window");

const Props = {};

const ClientFrameApproval: React.FC<Props> = ({ route }) => {
  const { uuid, frames, orderUuid, order } = route.params;
  const { user } = useContext(Context);
  const queryClient = useQueryClient();
  const modalizeRef = useRef<Modalize>(null);
  const insets = useSafeAreaInsets();
  const { goBack } = useNavigation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const _status = watch("status");

  const addUserToConversationMutation = addUserToConversation();
  const sendMessageMutation = useSendMessage();
  const [conversationUser, setConversationUser] = useState<any>(null);
  const {
    data: conversationUsers,
    isLoading: usersLoading,
    refetch: refecthConversationUsers,
  } = useGetConversationUsers(orderUuid);

  useEffect(() => {
    if (conversationUsers?.length > 0) {
      conversationUsers?.map((_user: any) => {
        if (_user?.userUuid === user?.uuid) {
          setConversationUser(_user);
        }
      });
    } else {
      setConversationUser(null);
    }
  }, [conversationUsers]);

  const _joinConversation = async (message) => {
    if (user) {
      addUserToConversationMutation.mutateAsync(
        {
          orderUuid: orderUuid,
          userUuid: user.uuid,
        },
        {
          onSuccess: (data) => {
            setConversationUser(data);
            _sendMessage(message, data);
          },
          onError: (error) => {
            console.log(error);
          },
        }
      );
    }
  };

  const clientApproveDisapprove = useClientApproveDisapprove();

  useEffect(() => {
    setValue("orderProductUuid", uuid);
  }, [uuid]);

  const _sendMessage = async (data: any, _user: any) => {
    const formData = new FormData();
    formData.append("Message", data);
    formData.append("OrderConversationUserUuid", _user.uuid);
    formData.append("OrderUuid", _user.orderUuid);
    formData.append("Attachment", null);
    formData.append("FileName", null);
    formData.append("FileExtension", null);
    formData.append("IsImage", false);
    setTimeout(() => {
      sendMessageMutation.mutate({
        formData: formData,
        message: data.input,
        orderConversationUserUuid: _user.uuid,
        orderUuid: _user.orderUuid,
        attachment: null,
        fileName: null,
        fileExtension: null,
        isImage: false,
        conversationUuid: _user.orderUuid,
        name: `${capitalizeFirstLetter(
          _user?.firstName
        )} ${capitalizeFirstLetter(_user?.lastName)}`,
      });
    }, 5000);
    goBack();
  };

  const onSubmit = (data: any) => {
    clientApproveDisapprove.mutate({
      orderProductUuid: uuid,
      status: data?.status,
      comment: data?.comment,
    });
    if (conversationUser === null) {
      _joinConversation(
        `Order: ${order?.shopifyOrderNumber} \nProduct: ${
          order?.productTitle
        } \nStatus: ${capitalizeFirstLetter(data?.status)} \nComment: \n${
          data?.comment
        }`
      );
    } else {
      _sendMessage(
        `Order: ${order?.shopifyOrderNumber} \nProduct: ${
          order?.productTitle
        } \nStatus: ${capitalizeFirstLetter(data?.status)} \nComment: \n${
          data?.comment
        }`,
        conversationUser
      );
    }

    if (data?.status === "disapproved") {
      if (Platform.OS !== "web") {
        Alert.alert(
          "We will review comments and notify you when a new design is uploaded. If we have any additional questions we may use our direct messaging within this app to reach you. Estimated within 48 hours."
        );
      } else {
        window.alert(
          "We will review comments and notify you when a new design is uploaded. If we have any additional questions we may use our direct messaging within this app to reach you. Estimated within 48 hours."
        );
      }
    }
  };

  return (
    <View style={styles.body}>
      <Header goBack />
      <ScrollView
        style={{
          marginTop: 30,
        }}
      >
        <UploadFrame
          hideUpload
          uploads={frames}
          name={""}
          orderProductUuid={""}
          imageUploadType={"Bouquet"}
        />
        <View style={{ paddingHorizontal: 20, paddingBottom: 140 }}>
          <View style={{ marginTop: 0 }}>
            <Text style={styles.title}>Frame Approval</Text>
            <Text style={styles.subtitle}>
              Your frame has been completed! Preserving your special moment
              through fine art is such an honor. Please respond with an approval
              of this design, or a disapproval.
            </Text>
            <Text style={styles.subtitle}>
              All disapprovals require comments explaining what you would like
              changed. We want this to be perfect for you, and will do our best
              to accommodate to your requests. Disapprovals may increase your
              frame completion time.
            </Text>
          </View>
        </View>
      </ScrollView>
      {Platform.OS !== "web" ? (
        <View
          style={{
            paddingTop: 10,
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: 80 + insets.bottom,
            backgroundColor: Colors.light.background,
            borderTopColor: Colors.light.brown,
            borderTopWidth: 0.5,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: -2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 12.62,
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <ActionButton
              label="Approve"
              color={Colors.light.green}
              icon={<Feather name="check" color="white" size={20} />}
              onPress={() => {
                setValue("status", "approved");
                modalizeRef.current?.open();
              }}
            />
            <ActionButton
              label="Disapprove"
              color={Colors.light.navy}
              icon={<Feather name="x" color="white" size={20} />}
              onPress={() => {
                setValue("status", "disapproved");
                modalizeRef.current?.open();
              }}
            />
          </View>
        </View>
      ) : (
        <View
          style={{
            flexDirection: "row",
            paddingBottom: 100,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <ActionButton
              label="Approve"
              color={Colors.light.green}
              icon={<Feather name="check" color="white" size={20} />}
              onPress={() => {
                setValue("status", "approved");
                modalizeRef.current?.open();
              }}
            />
            <ActionButton
              label="Disapprove"
              color={Colors.light.navy}
              icon={<Feather name="x" color="white" size={20} />}
              onPress={() => {
                setValue("status", "disapproved");
                modalizeRef.current?.open();
              }}
            />
          </View>
        </View>
      )}

      <Modalize
        ref={modalizeRef}
        modalHeight={height - 100}
        modalStyle={{
          backgroundColor: Colors.light.background,
        }}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: 20,
          }}
        >
          <View style={{ marginTop: 30 }}>
            <Text style={styles.title}>
              {_status === "disapproved" ? "Disapprove Frame" : "Approve Frame"}
            </Text>
            <Text style={styles.subtitle}>
              {_status === "disapproved"
                ? "Please let us know why you disapprove of the frame."
                : "Please leave a comment for the frame."}
            </Text>
            <Input
              name={"comment"}
              placeholder="Feedback"
              control={control}
              errors={errors}
              required
              multiline
              height={200}
            />
          </View>
          <View style={{ marginTop: 180, flexDirection: "row" }}>
            <ActionButton
              label="Submit"
              color={Colors.light.navy}
              onPress={() => {
                handleSubmit(onSubmit)();
              }}
            />
          </View>
        </View>
      </Modalize>
    </View>
  );
};

export default ClientFrameApproval;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
  },
  container: {
    marginTop: 40,
    fontSize: 18,
    marginLeft: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    maxWidth: Platform.OS !== "web" ? width : 800,
    alignSelf: "center",
    width: "100%",
  },
  title: {
    marginBottom: 6,
    fontSize: 24,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: "spectral-400",
    color: Colors.light.brown,
    marginBottom: 20,
  },
  input: {
    marginTop: 20,
  },
});
