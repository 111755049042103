import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetGmail = (getGmailInput: GetGmailInput) => {
  const fetchEmails = async (pageParam: { pageParam: [string] }) => {
    try {
      const emailsToImpersonateAndNextPageTokens = {};

      for (let i = 0; i < getGmailInput?.emailToImpersonate?.length; i++) {
        emailsToImpersonateAndNextPageTokens[
          getGmailInput?.emailToImpersonate[i]
        ] = "1";
      }

      if (getGmailInput?.isSearch && getGmailInput?.searchTerm === "") {
        return;
      }

      const res = await instanceAXIOS.post(`/Email/Gmail`, {
        emailsToImpersonateAndNextPageTokens:
          pageParam?.pageParam || emailsToImpersonateAndNextPageTokens,
        pageSize: getGmailInput?.pageSize,
        searchTerm: getGmailInput?.searchTerm || null,
      });

      return res.data;
    } catch (err) {
      Toast.show({
        type: "error",
        props: {
          message: "There was an error fetching emails",
        },
      });
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    refetch,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(["gmailEmails", getGmailInput], fetchEmails, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || !lastPage.nextPageTokens) {
        return undefined;
      }

      let _tokens = lastPage?.nextPageTokens;

      for (let key in _tokens) {
        if (_tokens[key] === null) {
          delete _tokens[key];
        }
      }

      if (_tokens.length === 0) {
        return undefined;
      }

      return _tokens;
    },
  });

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    isLoading,
  };
};

export interface GetGmailInput {
  emailToImpersonate: [string];
  query: string;
  pageSize: number;
  searchTerm?: string;
  isSearch?: boolean;
}
