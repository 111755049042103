import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrderDetails = (uuid: string) => {
  return useQuery(["orderDetails", uuid], () => getOrderDetails(uuid), {
    cacheTime: 240000,
    staleTime: 240000,
  });
};

const getOrderDetails = async (uuid: string) => {
  const { data } = await instanceAXIOS.get(`/OrderProducts/Details/${uuid}`);
  return data;
};
