import React from "react";
import { Platform, StyleSheet } from "react-native";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import { Calendar } from "react-native-calendars";
import Colors from "../../constants/Colors";
import { useGetEvents } from "../../hooks/calendar/useGetEvents";
import ListItem from "../../components/ListItem/Index";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";

export default function CalendarScreen({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const timezoneOffsetInMinutes =
    Platform.OS === "ios"
      ? new Date().getTimezoneOffset()
      : Math.floor((new Date().getTimezoneOffset() / 60) * -60);
  const [monthYear, setMonthYear] = React.useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [events, setEvents] = React.useState([]);
  const { data, isLoading, isFetched } = useGetEvents(monthYear);
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
  };
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    let date = formatDate(new Date());
    setSelected(date);
  }, []);

  React.useEffect(() => {
    if (data && selected) {
      if (data[selected]) {
        //remove any with uploadType: "client"
        const filtered = data[selected]?.filter(
          (item) => item.uploadType !== "client"
        );

        setEvents(filtered);
      } else {
        setEvents([]);
      }
    }
  }, [selected, isFetched]);

  React.useEffect(() => {
    if (data) {
      Object.keys(data).map((key) => {
        data[key].selected = false;
        data[key].marked = true;
        data[key].selectedColor = Colors.light.blue;
      });
    }
  }, [data]);

  const displayDate = (date: Date) => {
    const dateString = date?.substring(0, 10);
    return `${dateString.substring(5, 7)}/${dateString.substring(
      8,
      10
    )}/${dateString.substring(0, 4)}`;
  };

  const displayDatePlusOne = (date: Date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth();
    const day = d.getDate() + 1 < 10 ? `0${d.getDate() + 1}` : d.getDate() + 1;

    return `${month}/${day}/${year}`;
  };

  const dateFormat = (date: string) => {
    const d = new Date(date);
    const formattedDate = `${d.getMonth() + 1}/${d.getDate()}/${d
      .getFullYear()
      .toString()
      .substr(-2)}`;
    return formattedDate;
  };

  const bouquet = {
    key: "bouquet",
    color: Colors.light.blue,
    selectedDotColor: "blue",
  };
  const frame = {
    key: "frame",
    color: Colors.light.pink,
    selectedDotColor: "pink",
  };
  const florist = {
    key: "florist",
    color: Colors.light.limeGreen,
    selectedDotColor: "green",
  };

  const getDates = () => {
    if (data) {
      let obj = {};
      const getUploadTypes = (key: string) => {
        let arr = [];
        data[key].map((item) => {
          if (item.uploadType === "bouquet") {
            arr.push(bouquet);
          } else if (item.uploadType === "frame") {
            arr.push(frame);
          } else if (item.uploadType === "florist") {
            arr.push(florist);
          }
        });

        arr = arr.filter(
          (thing, index, self) =>
            self.findIndex((t) => t.key === thing.key) === index
        );
        return arr;
      };

      Object.keys(data).map((key) => {
        obj[key] = {
          marked: true,
          dots: getUploadTypes(key),
        };
      });
      return obj;
    }
  };

  const getCount = (events: any, eventKey: string) => {
    let count = 0;
    events.map((item) => {
      if (item.uploadType === eventKey) {
        count++;
      }
    });
    return count;
  };

  return (
    <View style={styles.container}>
      <Header profile />
      <View style={styles.calendarContainer}>
        <Calendar
          onDayPress={(day) => {
            selected != day.dateString && setSelected(day.dateString);
          }}
          markingType={"multi-dot"}
          markedDates={getDates()}
          timezonOffsetInMinutes={timezoneOffsetInMinutes}
          theme={{
            todayTextColor: Colors.light.navy,
            arrowColor: Colors.light.navy,
            monthTextColor: "black",
            textMonthFontSize: 20,
            textDayFontFamily: "spectral-600",
            textMonthFontFamily: "spectral-600",
            textDayHeaderFontFamily: "spectral-600",
            textDayFontWeight: "400",
            textMonthFontWeight: "400",
            textDayHeaderFontWeight: "400",
            textDayFontSize: 16,
            textDayHeaderFontSize: 16,
            todayDotColor: "red",
          }}
          monthFormat={"MMMM yyyy"}
          disableMonthChange={false}
          hideDayNames={false}
          onPressArrowLeft={(subtractMonth) => subtractMonth()}
          onPressArrowRight={(addMonth) => addMonth()}
          onMonthChange={(month) =>
            setMonthYear({
              month: month.month,
              year: month.year,
            })
          }
          disableAllTouchEventsForDisabledDays={true}
          enableSwipeMonths={true}
          style={styles.calendar}
        />
        <View style={styles.dateWrapper}>
          <Text style={styles.title}>
            {selected ? displayDatePlusOne(selected) : null}
          </Text>
        </View>
        <View style={styles.countContainer}>
          <View
            style={[
              styles.rowContainer,
              { backgroundColor: Colors.light.navy },
            ]}
          >
            <Text style={styles.countText}>{`Bouquets: ${getCount(
              events,
              "bouquet"
            )}`}</Text>
          </View>
          <View style={{ width: 10 }} />
          <View style={styles.rowContainer}>
            <Text style={styles.countText}>{`Frames: ${getCount(
              events,
              "frame"
            )}`}</Text>
          </View>
          <View style={{ width: 10 }} />
          <View
            style={[
              styles.rowContainer,
              { backgroundColor: Colors.light.limeGreen },
            ]}
          >
            <Text style={styles.countText}>{`Florist: ${getCount(
              events,
              "florist"
            )}`}</Text>
          </View>
        </View>
        <View style={styles.taskContainer}>
          <FlashList
            data={events}
            contentContainerStyle={styles.flatList}
            renderItem={({ item }) => (
              <ListItem
                orderNumber={item?.shopifyOrderNumber}
                orderStatus={item?.uploadType}
                orderDate={""}
                orderName={`${item.clientFirstName} ${item?.clientLastName}`}
                eventDate={""}
                orderProductUuid={item?.valetKey?.orderProductUuid}
                orderNotes={""}
                image={item?.valetKey?.blobUri}
                isImage={item?.valetKey?.isImage}
                calendar
                imageUuid={item?.valetKey?.orderProductUploadUuid}
                orderUuid={null}
              />
            )}
            estimatedItemSize={100}
            keyExtractor={(item) =>
              `_upload_${item?.valetKey?.orderProductUploadUuid}`
            }
            isLoading={isLoading}
            loadingComponent={<LoadingState />}
            ListEmptyComponent={
              <LoadingState
                empty
                emptyIcon={"calendar"}
                emptyText={"No Events"}
              />
            }
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  calendar: {
    borderRadius: 4,
  },
  countContainer: {
    width: "100%",
    height: 30,
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flatList: {
    paddingBottom: 100,
  },
  rowContainer: {
    flexDirection: "row",
    backgroundColor: Colors.light.pink,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,

    flex: 1,
  },
  countText: {
    color: "white",
    fontFamily: "spectral-600",
    fontSize: 14,
  },

  calendarContainer: { flex: 1, width: "100%", marginTop: 10, padding: 10 },
  dateWrapper: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 10,
    borderRadius: 4,
  },
  title: {
    fontSize: 16,
    fontFamily: "spectral-700",
  },
  eventText: {
    fontSize: 16,
    fontFamily: "spectral-400",
  },
  taskContainer: {
    backgroundColor: "#fff",
    marginTop: 10,
    flex: 1,
    borderRadius: 4,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  emptyState: {
    textAlign: "center",
    fontSize: 20,
    fontFamily: "spectral-400",
    // color: Colors.light.gray,
  },
});
