import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

interface ModalHeaderProps {
  headerText: string;
  goBack?: boolean;
  goBackCustom?: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  headerText,
  goBack,
  goBackCustom,
}) => {
  const navigaiton = useNavigation();

  return (
    <View style={styles.container}>
      {goBack ? (
        <TouchableOpacity
          onPress={() => {
            goBackCustom ? goBackCustom() : navigaiton.goBack();
          }}
          hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}
          style={styles.button}
        >
          <Feather name="arrow-left" size={24} color={Colors.light.brown} />
        </TouchableOpacity>
      ) : null}
      <Text style={styles.text}>{headerText}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    backgroundColor: Colors.light.background,
  },
  button: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  text: {
    fontSize: 20,
    color: Colors.light.brown,
    fontFamily: "spectral-600",
  },
});

export default ModalHeader;
