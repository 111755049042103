import React, { FC, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Keyboard,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import { instanceAXIOS } from "../../services/api";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";
import Header from "../../components/Header";
import { useQueryClient } from "react-query";

const { width } = Dimensions.get("window");

interface AddNoteProps {}

const AddNote: FC<AddNoteProps> = ({ route }) => {
  const { goBack } = useNavigation();
  const queryClient = useQueryClient();

  const { note, orderUuid } = route.params;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("note", note);
  }, [note]);

  const _AddNote = (data: any) => {
    instanceAXIOS
      .patch(`/Orders/Note`, {
        orderUuid: orderUuid,
        note: data.note,
      })
      .then((res) => {
        Toast.show({
          type: "success",
          props: {
            message: "Note Updated",
          },
        });
        queryClient.invalidateQueries("orderSummary");
        goBack();
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          props: {
            message: "Error updating note",
          },
        });
      });
  };

  return (
    <Pressable onPress={() => Keyboard.dismiss()} style={styles.body}>
      <View style={styles.width}>
        <Header isModal goBack title={"Shopify Note"} />
      </View>
      <Input
        keyboardShouldPersistTaps="handled"
        required
        name="note"
        placeholder="Shopify Note"
        multiline
        height={200}
        control={control}
        errors={errors}
        keyboardType="default"
      />
      <View style={styles.buttonContainer}>
        <Button
          backgroundColor={Colors.light.brown}
          label={"Save"}
          onPress={() => {
            handleSubmit(_AddNote)();
          }}
        />
      </View>
    </Pressable>
  );
};
export default AddNote;

const styles = StyleSheet.create({
  body: {
    alignItems: "center",
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 20,
  },
  width: {
    width: width,
    marginBottom: 40,
  },
  buttonContainer: {
    position: "absolute",
    bottom: 40,
    justifyContent: "center",
    alignItems: "center",
  },
});
