import React from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  View,
  Platform,
  Text,
  Alert,
} from "react-native";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";
import Colors from "../../constants/Colors";
import ModalBody from "../../components/ModalBody";
import instanceAXIOS from "../../services/api";
import ModalHeader from "../../components/ModalHeader";
import Header from "../../components/Header";
import CustomAlert from "../../components/CustomAlert";

export default function CheckRegistrationStatus() {
  const [visible, setVisible] = React.useState(false);
  const [visibleTwo, setVisibleTwo] = React.useState(false);
  const navigate = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.email = data.email.toLowerCase();
    instanceAXIOS
      .post("/Authentication/CheckRegistrationStatus", data)
      .then((res) => {
        if (res?.data === true) {
          if (Platform.OS === "web") {
            setVisible(true);
          } else {
            Alert.alert(
              "Email Found",
              `Please login using your email and password \n \n If you forgot your password, please click "Forgot Password"`,

              [
                {
                  text: "Forgot Password",
                  onPress: () => {
                    navigate.navigate("ForgotPassword");
                  },
                },
                {
                  text: "Login",
                  onPress: () => {
                    navigate.navigate("Login");
                  },
                },
              ]
            );
          }
        } else {
          if (Platform.OS === "web") {
            setVisibleTwo(true);
          } else {
            Alert.alert(
              "Email Not Found",
              `We could not find your email in our system. \n \n Please register with the code from your welcome email, or check another email`,
              [
                {
                  text: "Register",
                  onPress: () => {
                    navigate.navigate("Login");
                  },
                },
                {
                  text: "Check other email",
                  onPress: () => {
                    setVisibleTwo(false);
                  },
                },
              ]
            );
          }
        }
      })
      .catch((err) => {
        if (Platform.OS === "web") {
          setVisibleTwo(true);
        } else {
          Alert.alert(
            "Email Not Found",
            `We could not find your email in our system. \n \n Please register with the code from your welcome email, or check another email`,
            [
              {
                text: "Register",
                onPress: () => {
                  navigate.navigate("Login");
                },
              },
              {
                text: "Check other email",
                onPress: () => {
                  setVisibleTwo(false);
                },
              },
            ]
          );
        }
      });
  };

  return (
    <>
      <View style={styles.body}>
        <Header goBack title="Check Registration Status" />
        <KeyboardAvoidingView style={styles.flex} behavior={"height"}>
          <View style={styles.container}>
            <Text style={styles.prompt}>
              Enter your email address to see if you have registered with us
              before
            </Text>
            <Input
              control={control}
              errors={errors}
              name="email"
              required
              placeholder="Email"
              autoCapitalize="none"
              keyboardType="email-address"
            />
            <Button
              label={"Check Email"}
              onPress={handleSubmit(onSubmit)}
              backgroundColor={Colors.light.lightGreen}
            />
          </View>
        </KeyboardAvoidingView>
      </View>
      <CustomAlert
        visible={visible}
        optionOne={"Forgot Password"}
        optionTwo={"Login"}
        title={"Email Found"}
        message={`Please login using your email and password \n \n If you forgot your password, please click "Forgot Password"`}
        pressOne={() => {
          setVisible(false);
          navigate.navigate("ForgotPassword");
        }}
        pressTwo={() => {
          setVisible(false);
          navigate.navigate("Login");
        }}
      />
      <CustomAlert
        visible={visibleTwo}
        optionOne={"Register"}
        optionTwo={"Check other email"}
        title={"Email Not Found"}
        message={`We could not find your email in our system. \n \n Please register with the code from your welcome email, or check another email`}
        pressOne={() => {
          navigate.navigate("Login");
        }}
        pressTwo={() => {
          setVisibleTwo(false);
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    backgroundColor: Colors.light.background,
    justifyContent: "center",
  },
  container: {
    marginTop: 80,
    marginHorizontal: 40,
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: Platform.OS !== "web" ? window.window.width : 400,
    alignSelf: "center",
    width: "90%",
  },
  flex: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  prompt: {
    fontFamily: "spectral-600",
    fontSize: 16,
    textAlign: "center",
    width: "90%",
    marginBottom: 20,
  },
});
