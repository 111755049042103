import React, { useContext } from "react";
import { StyleSheet, View, Text } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import FlashList from "../../components/FlashList";
import Header from "../../components/Header";
import LoadingState from "../../components/LoadingState";
import Colors from "../../constants/Colors";
import Context from "../../context";
import ProductItem from "./ProductItem";

const data = [
  {
    id: 1034,
    name: "16 / 20 - Solid white / Abstract",
    status: "Order Received",
  },
  {
    id: 1034,
    name: "18 / 24 - Natural wood / Abstract",
    status: "Almost Ready to Frame",
  },
  {
    id: 1034,
    name: "16 / 20 - Solid white / Abstract",
    status: "Preparing to be Shipped",
  },
];

export default function Client() {
  return (
    <View style={styles.body}>
      <Header goBack />
      <Text style={styles.container}>{`Products in Order #${data[0].id}`}</Text>
      <View style={styles.flex}>
        <FlashList
          data={data}
          estimatedItemSize={100}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyIcon="package"
              emptyText="No products found"
            />
          }
          renderItem={({ item }) => (
            <ProductItem id={item.id} name={item.name} status={item.status} />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: { flex: 1, backgroundColor: Colors.light.background },
  container: {
    marginTop: 40,
    fontSize: 18,
    marginLeft: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  flex: { flex: 1 },
});
