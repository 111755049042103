import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  Platform,
  TouchableOpacity,
} from "react-native";
import React, { useEffect } from "react";
import Animated, {
  Extrapolate,
  interpolate,
  interpolateColor,
  useAnimatedStyle,
} from "react-native-reanimated";
import Colors from "../../../constants/Colors";
const { width } = Dimensions.get("window");
import * as Haptics from "expo-haptics";

interface CircleProps {
  index: number;
  progress: any;
  data: any;
  onPress: (index: number) => void;
}

const Circle: React.FC<CircleProps> = ({ index, progress, data, onPress }) => {
  useEffect(() => {
    let completeTo = data.findIndex((item: any) => item.completed === true);
    if (index <= completeTo) {
      setTimeout(() => {
        if (index === completeTo) {
          if (Platform.OS !== "web") {
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
          }
        } else {
          if (Platform.OS !== "web") {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
          }
        }
      }, 660 + index * (2000 / completeTo));
    }
  }, [data]);

  const intWidth = 1 / data.length;

  const circleBackgroundAnimatedStyle = useAnimatedStyle(() => {
    const backgroundColorInterpolationLight = interpolateColor(
      progress.value,
      [intWidth * index, intWidth * (index + 0.1)],
      [Colors.light.background, Colors.light.clientGreen]
    );

    return { backgroundColor: backgroundColorInterpolationLight };
  });

  const circleRingAnimatedStyle = useAnimatedStyle(() => {
    const backgroundColorInterpolationLight = interpolateColor(
      progress.value,
      [intWidth * index, intWidth * (index + 0.1)],
      [Colors.light.brown, Colors.light.background]
    );

    return { backgroundColor: backgroundColorInterpolationLight };
  });

  const circleTextAnimatedStyle = useAnimatedStyle(() => {
    const backgroundColorInterpolationLight = interpolateColor(
      progress.value,
      [intWidth * index, intWidth * (index + 0.1)],
      [Colors.light.brown, Colors.light.background]
    );

    return { color: backgroundColorInterpolationLight };
  });

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{ cursor: "pointer" }}
      onPress={() => {
        if (Platform.OS !== "web") {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
        }
        onPress(index);
      }}
    >
      <Animated.View
        style={[
          {
            height: 32,
            width: 32,
            borderRadius: 25,
            justifyContent: "center",
            alignItems: "center",
          },
          circleRingAnimatedStyle,
        ]}
      >
        <Animated.View
          style={[styles.circleStep, circleBackgroundAnimatedStyle]}
        >
          <Animated.Text style={[styles.number, circleTextAnimatedStyle]}>
            {index + 1}
          </Animated.Text>
        </Animated.View>
      </Animated.View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  circleStep: {
    height: 28,
    width: 28,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  number: {
    textAlign: "center",
    color: Colors.light.background,
    fontSize: 14,
    height: 21,
    width: 24,
    fontFamily: "spectral-800",
  },
});

export default Circle;
