import { StyleSheet, View, Text, Dimensions, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import Colors from "../../../constants/Colors";
import Animated, {
  Easing,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from "react-native-reanimated";
import Circle from "./circle";
const { width } = Dimensions.get("window");

interface ProgressBarProps {
  data: any;
  step: number;
  onPress: (index: any) => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ data, step, onPress }) => {
  const offset = useSharedValue(0);
  const [aniWidth, setAniWidth] = useState(width);

  useEffect(() => {
    if (Platform.OS === "web") {
      setAniWidth(500);
    } else {
      setAniWidth(width);
    }
    offset.value = withDelay(
      660,
      withTiming(step ? step / 10 : 0.1, {
        duration: 2000,
        easing: Easing.linear,
      })
    );
  }, [step]);

  const animatedProgressStyles = useAnimatedStyle(() => {
    return {
      width: interpolate(
        offset.value,
        [0.1, 0.9],
        [0, aniWidth - (Platform.OS !== "web" ? 60 : 120)]
      ),
    };
  });

  return (
    <View style={styles.body}>
      <View style={[styles.line, { width: aniWidth - 60 }]} />
      <Animated.View style={[styles.progressLine, animatedProgressStyles]} />
      <View style={[styles.container, { width: aniWidth - 60 }]}>
        {data.map((item: any, index: number) => {
          return (
            <Circle
              key={index}
              index={index}
              progress={offset}
              data={data}
              onPress={(index) => {
                onPress(index);
              }}
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: { flex: 1, backgroundColor: Colors.light.background },
  container: {
    marginTop: -21,
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  progressLine: {
    height: 10,
    backgroundColor: Colors.light.clientGreen,
    position: "absolute",
    left: 30,
    top: 0,
    borderRadius: 5,
    zIndex: -1,
  },
  line: {
    alignSelf: "center",
    height: 10,
    borderRadius: 3,
    backgroundColor: "transparent",
    borderWidth: 2,
    borderColor: Colors.light.brown,
  },
  circleStep: {
    height: 24,
    width: 24,
    borderRadius: 25,
    backgroundColor: Colors.light.background,
    justifyContent: "center",
    alignItems: "center",
    borderColor: Colors.light.brown,
    borderWidth: 2,
  },
  number: {
    textAlign: "center",
    color: Colors.light.brown,
    fontSize: 14,
    height: 21,
    width: 24,
    fontFamily: "spectral-800",
  },
});

export default ProgressBar;
