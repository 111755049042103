import { Alert, Dimensions, StyleSheet, View } from "react-native";
import React, { useContext, useEffect, useRef } from "react";
import { Modalize } from "react-native-modalize";
import ModalHeader from "../../../components/ModalHeader";
import Colors from "../../../constants/Colors";
import FlashList from "../../../components/FlashList";
import UserListItem from "../../../components/UserListItem";
import LoadingState from "../../../components/LoadingState";
import { useAssignOrder } from "../../../hooks/orders/useAssignOrder";
import Context from "../../../context";
import { useGetAssignedUsers } from "../../../hooks/users/useGetAssignedUsers";

const { height } = Dimensions.get("window");

interface UpdateOrderOwnerProps {
  currentOwner: string;
  orderUuid: string;
}

const UpdateOrderOwner: React.FC<UpdateOrderOwnerProps> = ({
  currentOwner,
  orderUuid,
}) => {
  const _modalizeRef = useRef<Modalize>(null);
  const { setAssignedToModal, user } = useContext(Context);

  const { data, isLoading } = useGetAssignedUsers(user?.uuid);

  const orderOwner = useAssignOrder();

  useEffect(() => {
    if (_modalizeRef) {
      setAssignedToModal(_modalizeRef);
    }
  }, [_modalizeRef]);

  return (
    <Modalize
      ref={_modalizeRef}
      modalHeight={height * 0.9}
      modalStyle={styles.modal}
      scrollViewProps={{
        showsVerticalScrollIndicator: false,
        scrollEnabled: false,
      }}
      withReactModal
      HeaderComponent={<ModalHeader headerText={"Assigned To"} />}
    >
      <View style={styles.body}>
        <View style={styles.list}>
          <FlashList
            data={data}
            renderItem={({ item }) => (
              <UserListItem
                name={`${item?.firstName} ${item?.lastName}`}
                assigned={item?.uuid === currentOwner}
                onPress={() => {
                  Alert.alert(
                    'Notice',
                    'Updating the assigned user will assign the location of this order product to the new assigned users location. Are you sure you want to proceed?',
                    [
                      {
                        text: 'Cancel',
                        style: 'cancel',
                      },
                      {
                        text: 'OK',
                        onPress: () => {
                          orderOwner.mutate({
                            orderPrductUuid: orderUuid,
                            userUuid: item?.uuid,
                          });
                          _modalizeRef.current?.close();
                        },
                      },
                    ],
                  );
                }}
              />
            )}
            estimatedItemSize={20}
            isLoading={isLoading}
            loadingComponent={<LoadingState />}
            ListEmptyComponent={
              <LoadingState empty emptyIcon="user" emptyText="No users found" />
            }
          />
        </View>
      </View>
    </Modalize>
  );
};

const styles = StyleSheet.create({
  modal: {
    backgroundColor: Colors.light.background,
  },
  body: {
    height: height * 0.7,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.background,
  },
  list: {
    backgroundColor: "white",
    width: "98%",
    flex: 1,
  },
});

export default UpdateOrderOwner;
