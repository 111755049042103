import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import React from "react";
import Colors from "../../../constants/Colors";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import StatusPill from "../../../components/StatusPill";
import { useNavigation } from "@react-navigation/native";

interface ProductItemProps {
  name: string;
  status: string;
  order: any;
}

const ProductItem: React.FC<ProductItemProps> = ({ name, status, order }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={[
        styles.button,
        { height: order?.status === "frameCompleted" ? 180 : 120 },
      ]}
      onPress={() => {
        navigation.navigate("ClientProductDetails", { order: order });
      }}
    >
      {order?.status === "frameCompleted" ? (
        <View
          style={{
            height: 60,
            marginBottom: -20,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            zIndex: -1,
            backgroundColor: Colors.light.red,
            marginHorizontal: 10,
            flexDirection: "row",
            paddingLeft: 10,
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <Feather name="alert-triangle" size={24} color="white" />
          <Text
            style={{
              fontSize: 16,
              fontFamily: "spectral-700",
              color: "white",
              marginLeft: 10,
            }}
          >
            Frame Approval Requested
          </Text>
          <Feather name="chevron-right" size={18} color="white" />
        </View>
      ) : null}
      <View style={styles.row}>
        <View style={styles.containerLeft}>
          <MaterialCommunityIcons
            name="flower-tulip-outline"
            size={24}
            color={Colors.light.brown}
            style={styles.icon}
          />
          <View style={styles.container}>
            <View style={styles.row2}>
              <Text style={styles.title}>{`Order Number:`}</Text>
              <Text style={styles.item}>{`#${order?.shopifyOrderNumber}`}</Text>
              <Text
                style={styles.itemQuantity}
              >{`Quantity: ${order?.quantity}`}</Text>
            </View>
            <View style={styles.row2}>
              <Text style={styles.title}>{`Item:`}</Text>
              <Text
                numberOfLines={1}
                style={styles.item}
              >{`${order?.productTitle} - ${order?.variantTitle}`}</Text>
            </View>

            <View style={styles.rowText}>
              <StatusPill status={status} />
            </View>
          </View>
        </View>
        <View style={styles.containerRight}>
          <Feather
            name="chevron-right"
            size={24}
            color={Colors.light.brown}
            onPress={() =>
              navigation.navigate("ClientProductDetails", { order: order })
            }
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    marginHorizontal: 10,
    cursor: "pointer",
  },
  row: {
    backgroundColor: Colors.light.background,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    flex: 1,
    margin: 10,
    borderWidth: 0,
    borderColor: Colors.light.brown,
    borderRadius: 10,
    paddingVertical: 4,
    flexDirection: "row",
  },
  containerLeft: {
    flexDirection: "row",
    width: "90%",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginLeft: 20,
    marginRight: 10,
  },
  container: {
    justifyContent: "space-between",
    flex: 1,
    height: 100,
    paddingVertical: 10,
    width: "100%",
  },
  name: {
    fontSize: 14,
    fontWeight: "800",
    color: Colors.light.brown,
    marginLeft: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: "800",
    color: Colors.light.brown,
    marginLeft: 8,
  },
  item: {
    fontSize: 14,
    fontWeight: "600",
    color: Colors.light.brown,
    marginLeft: 8,
    maxWidth: "80%",
    flex: 1,
  },
  itemSub: {
    fontSize: 12,
    fontWeight: "600",
    color: Colors.light.brown,
    marginLeft: 16,
  },
  rowText: {
    flexDirection: "row",
    marginLeft: 8,
  },
  containerRight: {
    flex: 1,
    justifyContent: "flex-end",
    marginRight: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  row2: {
    flexDirection: "row",
    width: "100%",
  },
  itemQuantity: {
    fontSize: 12,
    color: Colors.light.brown,
    marginLeft: 8,
    fontWeight: "600",
    maxWidth: "80%",
    flex: 1,
  },
});

export default ProductItem;
