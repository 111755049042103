import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import React, { ReactNode } from "react";
import Colors from "../../constants/Colors";

interface ActionButtonProps {
  onPress?: () => void;
  label: string;
  icon?: ReactNode;
  color?: string;
  disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onPress = null,
  label,
  icon = null,
  color = Colors.light.brown,
  disabled = false,
}) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      style={[styles.container, { backgroundColor: color }]}
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
    >
      {icon}
      <Text style={styles.label}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    margin: 8,
    padding: 8,
    borderRadius: 8,
  },
  label: {
    color: Colors.light.background,
    fontSize: 12,
    paddingLeft: 6,
    fontFamily: "spectral-600",
  },
});

export default ActionButton;
