import React, { FC, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import Colors from "../../../constants/Colors";
import { Feather } from "@expo/vector-icons";

interface DetailItemExpandProps {
  title: string;
  values: string[];
}

const DetailItemExpand: FC<DetailItemExpandProps> = ({ title, value }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const _onPress = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <View style={styles.itemRow}>
      <TouchableOpacity onPress={() => _onPress()} style={styles.textRow}>
        <Text style={styles.itemTitle}>{title}</Text>
        <Feather name={!isExpanded ? "chevron-up" : "chevron-down"} size={24} />
      </TouchableOpacity>
      {isExpanded &&
        value.map((value, index) => (
          value && value !== 'null, null' ? (
          <Text
            key={index?.toString()}
            adjustsFontSizeToFit
            numberOfLines={1}
            style={styles.itemValue}
          >
            {value}
          </Text>
          ) : null
        ))}
    </View>
  );
};
export default DetailItemExpand;

const styles = StyleSheet.create({
  itemRow: {
    borderBottomColor: "#ddd",
    paddingVertical: 2,
    padding: 20,
  },
  textRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  itemTitle: {
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  itemValue: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 6,
    color: Colors.light.brown,
  },
});
