import React, { useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import WebView from "react-native-webview";
import Colors from "../../../constants/Colors";
import { Text } from "../../../components/Themed";
const { width } = Dimensions.get("window");

interface EmailItemProps {
  index: number;
  html: any;
}

const EmailItem: React.FC<EmailItemProps> = ({ index, html }) => {
  const [height, setHeight] = useState(0);

  const onMessage = (event: any) => {
    const contentHeight = Number(event.nativeEvent.data);
    setHeight(contentHeight);
  };

  const injectedJavaScript = `
    window.ReactNativeWebView.postMessage(
      document.documentElement.scrollHeight.toString()
    );
    true;
  `;

  const viewportMetaTag = `<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">`;

  const htmlWithViewport = `${viewportMetaTag}${html}`;

  return (
    <View
      style={{ borderBottomColor: Colors.light.brown, borderBottomWidth: 1 }}
    >
      <WebView
        key={index}
        style={{ width: width, height: height }}
        originWhitelist={["*"]}
        containerStyle={{ width: width, height: height }}
        onMessage={onMessage}
        injectedJavaScript={injectedJavaScript}
        javaScriptEnabled={true}
        scrollEnabled={false}
        horizontalScrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        source={{ html: htmlWithViewport }}
      />
    </View>
  );
};

const styles = StyleSheet.create({});

export default EmailItem;
