import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";
import queryClient from "../../services/reactQuery";

const updateSocial = async (_data: any) => {
  const res = await instanceAXIOS.post(`/User/SocialMediaLinks`, _data);
  return res.data;
};

export const useUpdateSocial = () => {
  return useMutation((_data: any) => updateSocial(_data), {
    onSuccess: (data: any) => {
      Toast.show({
        type: "success",
        props: {
          message: "Socials updated successfully",
        },
      });
      queryClient.invalidateQueries("orderDetails");
      queryClient.invalidateQueries("orderSummary");
      return data;
    },
    onError: (error) => {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to update socials",
        },
      });
    },
  });
};
