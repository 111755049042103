import { View, StyleSheet, Dimensions } from "react-native";
import React, { useContext, useState } from "react";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Button from "../../components/Button";
import Avatar from "../../components/Avatar";
import Context from "../../context";
import * as ImagePicker from "expo-image-picker";
import instanceAXIOS from "../../services/api";
// import { useUpload } from "../../hooks/uploads/useUpload";
import { useNavigation } from "@react-navigation/native";

const { width } = Dimensions.get("window");

interface UpdateAvatarProps {}

const UpdateAvatar: React.FC<UpdateAvatarProps> = ({ route }) => {
  const { user } = useContext(Context);
  const [selectedImage, setSelectedImage] = useState(null);
  // const upload = useUpload();
  const {goBack} = useNavigation()

  const _pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [3, 3],
      videoMaxDuration: 120,
      base64: true,
      quality: 0.1,
    });

    const _image = result?.assets[0];
    setSelectedImage(_image);
  };

  const _takeImage = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [3, 3],
      videoMaxDuration: 120,
      quality: 0.1,
    });

    const _image = result?.assets[0];
    setSelectedImage(_image);
  };

  const _saveAvatar = async () => {
    //This is commented until staff items are added to web
    // const res = await instanceAXIOS.post(`/Uploads/UserAvatar`);
    // upload.mutateAsync({
    //   fileName: selectedImage?.fileName,
    //   uri: selectedImage?.uri,
    //   type: selectedImage?.type,
    //   fileSize: selectedImage?.fileSize,
    //   uploadKey: res?.data,
    // });
    // goBack()
  };

  return (
    <View style={styles.body}>
      <View style={styles.width}>
        <Header isModal goBack title={"Update Avatar"} />
      </View>
      <View style={styles.container}>
        {selectedImage ? (
        <Avatar
          image={selectedImage?.uri}
          large
          name={`${user?.firstName} ${user?.lastName}`}
          shadow
        />
        ): null}
        <View style={styles.spaceLarge} />
        <Button
          backgroundColor={Colors.light.brown}
          label={"Take Photo"}
          onPress={() => {
            _takeImage();
          }}
        />
        <View style={styles.space} />
        <Button
          backgroundColor={Colors.light.brown}
          label={"Pick Photo"}
          onPress={() => {
            _pickImage();
          }}
        />
        <View style={styles.spaceLarger} />
        <Button
          backgroundColor={
            selectedImage ? Colors.light.navy : Colors.light.lightBrown
          }
          label={"Save"}
          isDisabled={!selectedImage}
          onPress={() => {
            _saveAvatar();
          }}
        />
      </View>
    </View>
  );
};

export default UpdateAvatar;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: "space-between",
    alignItems: "center",
    width: width,
    paddingBottom: 40,
  },
  width: {
    width: width,
  },
  space: {
    height: 10,
  },
  spaceLarge: {
    height: 40,
  },
  spaceLarger: {
    height: 60,
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
  },
});
