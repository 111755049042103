import React, { useState, useEffect } from "react";
import { Dimensions, StyleSheet, Text } from "react-native";
import Button from "../../components/Button";
import DropDownButton from "../../components/DropDownButton";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import { useGetEmailTemplates } from "../../hooks/email/useGetEmailTemplates";
import Dropdown from "../../components/Dropdown";
import { useForm } from "react-hook-form";
import instanceAXIOS from "../../services/api";
import Toast from "react-native-toast-message";
const { width } = Dimensions.get("window");

const statusData = [
  { label: "Order Received", value: "OrderReceived" },
  { label: "Bouquet Received", value: "BouquetReceived" },
  { label: "Checked On", value: "CheckedOn" },
  { label: "Progress", value: "Progress" },
  { label: "Almost Ready to Frame", value: "AlmostReadyToFrame" },
  { label: "Ready to Frame", value: "ReadyToFrame" },
  { label: "Frame Completed", value: "FrameCompleted" },
  { label: "Preparing to be Shipped", value: "PreparingToBeShipped" },
  { label: "Shipped", value: "Shipped" },
  { label: "No Response", value: "NoResponse" },
  { label: "Approved", value: "Approved" },
  { label: "Disapproved", value: "Disapproved" },
  { label: "Ready To Seal", value: "ReadyToSeal" },
];

export default function BulkEmail({
  navigation,
  route,
}: RootTabScreenProps<"TabOne">) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [template, setTemplate] = useState(null);
  const [statuses, setStatuses] = useState(statusData);
  const [templates, setTemplates] = useState([]);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [person, setPerson] = useState(null);
  const [orderProduct, setOrderProduct] = useState(null);
  const { data: emailTemplates } = useGetEmailTemplates(100);

  useEffect(() => {
    if (route?.params?.person) {
      setPerson(route?.params?.person);
      setOrderProduct(route?.params?.orderProduct);
    }
  }, [route]);

  useEffect(() => {
    if (emailTemplates) {
      const items = emailTemplates.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setTemplates(items);
    }
  }, [emailTemplates]);

  const sendBulkEmail = () => {
    if (!status || !template) {
      Toast.show({
        type: "error",
        props: {
          message: "Please select a status and template",
        },
      });
      return;
    }
    console.log("status", status);
    console.log("template", template);
    // commenting out until API is ready
    instanceAXIOS
      .post(`/Email/SendOrderProductStatusEmails/${status}/${template}`, {})
      .then(() => {
        Toast.show({
          type: "success",
          props: {
            message: "Emails Sent successfully",
          },
        });
      })
      .catch((err) => {
        Toast.show({
          type: "error",
          props: {
            message: "Error sending emails",
          },
        });
      });
    setStatus(null);
    setTemplate(null);
  };

  const sendEmail = () => {
    if (!template) {
      Toast.show({
        type: "error",
        props: {
          message: "Please select a template",
        },
      });
      return;
    }
    instanceAXIOS
      .post(
        `/Email/SendOrderProductStatusTemplate/${orderProduct?.uuid}/${template}`,
        {}
      )
      .then(() => {
        Toast.show({
          type: "success",
          props: {
            message: "Email Sent successfully",
          },
        });
      })
      .catch((err) => {
        Toast.show({
          type: "error",
          props: {
            message: "Error sending email",
          },
        });
      });
    setTemplate(null);
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <View style={styles.pageContainer}>
        <Text style={styles.titlePage}>
          {person
            ? `Send Email To ${person.firstName} ${person.lastName}`
            : "Send Bulk Email"}
        </Text>
        {!person && (
          <View style={styles.buttonContainer}>
            <Dropdown
              label={"Status"}
              name={"status"}
              control={control}
              errors={errors}
              open={isStatusOpen}
              setOpen={setIsStatusOpen}
              value={status}
              items={statuses}
              setValue={setStatus}
              setItems={setStatuses}
              width={width - 20}
            />
          </View>
        )}
        {!isStatusOpen && emailTemplates && (
          <View style={styles.buttonContainer2}>
            <Dropdown
              label={"Template"}
              name={"template"}
              control={control}
              errors={errors}
              open={isTemplateOpen}
              setOpen={setIsTemplateOpen}
              value={template}
              items={templates}
              setValue={setTemplate}
              setItems={setStatuses}
              width={width - 20}
            />
          </View>
        )}
        {!isStatusOpen && !isTemplateOpen && (
          <View style={styles.buttonContainer3}>
            <Button
              label={"Send Email"}
              onPress={person ? sendEmail : sendBulkEmail}
            />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
    paddingLeft: 10,
    marginTop: 20,
    height: 100,
  },
  buttonContainer2: {
    width: "100%",
    paddingLeft: 10,
    height: 100,
  },
  buttonContainer3: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  pageContainer: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
  },
});
