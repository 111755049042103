import React from "react";
import { StyleSheet, Dimensions } from "react-native";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import StatusPill from "../../components/StatusPill";
import { useRoute } from "@react-navigation/native";
import { useGetOrderCountsByStatus } from "../../hooks/orders/useGetOrderCountsByStatus";
import StageDetailsItem from "./StageDetailsItem";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import { RefreshControl } from "react-native-gesture-handler";
const { height } = Dimensions.get("window");

export default function StageDetails({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const route = useRoute();
  const { stage, count } = route?.params;

  const {
    data: stageData,
    isLoading,
    isFetched,
    refetch,
  } = useGetOrderCountsByStatus(stage);

  return (
    <>
      <Header goBack />
      <View style={styles.container}>
        <StatusPill status={stage} count={count} />
        <View
          style={{
            width: "100%",
            height: height * 0.7,
          }}
        >
          <FlashList
            data={stageData}
            renderItem={({ item }) => (
              <StageDetailsItem
                title={item?.productTitle}
                varients={item?.variants}
              />
            )}
            refreshControl={
              <RefreshControl
                refreshing={!isFetched ? false : isLoading}
                onRefresh={() => {
                  refetch();
                }}
              />
            }
            estimatedItemSize={100}
            isLoading={isLoading}
            loadingComponent={<LoadingState />}
            ListEmptyComponent={
              <LoadingState
                empty
                emptyText="No orders in this stage"
                emptyIcon="package"
                refreshEnabled
                refetch={() => {
                  refetch();
                }}
              />
            }
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: "center",
  },
  listItem: {
    paddingVertical: 10,
    width: 150,
  },
});
