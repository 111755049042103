import React from "react";
import { Dimensions, StyleSheet } from "react-native";
import Header from "../../../components/Header";
import { View } from "../../../components/Themed";
import { RootTabScreenProps } from "../../../types";
import Button from "../../../components/Button";
import { useGetAssignedUsers } from "../../../hooks/users/useGetAssignedUsers";
import Context from "../../../context";
import Dropdown from "../../../components/Dropdown";
import { useForm } from "react-hook-form";
import { useGetUsersByRole } from "../../../hooks/users/useGetUsersByRole";
import { Modalize } from "react-native-modalize";
import Colors from "../../../constants/Colors";
import Modal from "./modal";
import UserListItem from "../../../components/UserListItem";
import FlashList from "../../../components/FlashList";
import LoadingState from "../../../components/LoadingState";
import { RefreshControl } from "react-native-gesture-handler";

const { width } = Dimensions.get("window");

export default function UserList({ navigation }: RootTabScreenProps<"TabOne">) {
  const { user } = React.useContext(Context);
  const { data: adminAndManagers } = useGetUsersByRole(
    1,
    50,
    ["Admin", "Manager"],
    "adminAndManagers"
  );
  const { data: admin, refetch: fetchAdmin } = useGetUsersByRole(
    1,
    50,
    ["Admin"],
    "admin"
  );
  const {
    control,
    formState: { errors },
  } = useForm();
  const [managersOpen, setManagersOpen] = React.useState(false);
  const [manager, setManager] = React.useState(user?.uuid);
  const [managers, setManagers] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);
  const {
    data: users,
    isLoading,
    isFetched,
    refetch,
  } = useGetAssignedUsers(manager);
  const modalizeRef = React.useRef<Modalize>(null);

  React.useEffect(() => {
    adminAndManagers &&
      user &&
      setManagers([
        { label: "All Admin", value: "Admin" },
        { label: "Unassigned", value: "00000000-0000-0000-0000-000000000000" },
        ...adminAndManagers?.items?.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.uuid,
        })),
      ]);
  }, [user, adminAndManagers, admin]);

  return (
    <View style={styles.container}>
      <Header goBack rightActionIcon="user" rightAction={() => {
        navigation.navigate("EditUser", { uuid: user?.uuid });
      }} />
      <View style={styles.innerContainer}>
        {managers && (
          <Dropdown
            name={"assignedToUserUuid"}
            label={"Manager"}
            control={control}
            width={width - 20}
            errors={errors}
            open={managersOpen}
            setOpen={setManagersOpen}
            value={manager}
            items={managers}
            setValue={setManager}
            setItems={setManagers}
            required={false}
          />
        )}
        <View style={styles.inner}>
          <FlashList
            data={manager === "Admin" ? admin?.items : users || []}
            refreshControl={
              <RefreshControl refreshing={!isFetched ? false : isLoading} />
            }
            renderItem={({ item }) => (
              <UserListItem
                name={`${item?.firstName} ${item?.lastName}`}
                edit
                onPress={() => {
                  navigation.navigate("EditUser", { uuid: item?.uuid });
                }}
              />
            )}
            isLoading={isLoading}
            estimatedItemSize={53}
            ListEmptyComponent={
              <LoadingState
                empty
                emptyIcon="users"
                emptyText="No users assigned to this manager"
                refreshEnabled
                refresh={() => {
                  refetch();
                }}
              />
            }
            loadingComponent={<LoadingState />}
          />
        </View>
        <View style={styles.bottomButton}>
          <Button
            label={"Add User"}
            onPress={() => navigation.navigate("AddUser")}
          />
        </View>
      </View>
      <Modal
        modalRef={modalizeRef}
        users={managers?.filter(
          (x: { value: string | undefined }) => x.value !== manager
        )}
        userUuid={currentUser}
        assignedToUserUuid={manager}
        userName={
          manager === "Admin"
            ? `${
                admin?.items?.find((x) => x?.uuid === currentUser)?.firstName
              } ${admin?.items?.find((x) => x?.uuid === currentUser)?.lastName}`
            : `${users?.find((x) => x?.uuid === currentUser)?.firstName} ${
                users?.find((x) => x?.uuid === currentUser)?.lastName
              }`
        }
        firstName={
          manager === "Admin"
            ? admin?.items?.find((x) => x?.uuid === currentUser)?.firstName
            : users?.find((x) => x?.uuid === currentUser)?.firstName
        }
        lastName={
          manager === "Admin"
            ? admin?.items?.find((x) => x?.uuid === currentUser)?.lastName
            : users?.find((x) => x?.uuid === currentUser)?.lastName
        }
        userEmail={
          manager === "Admin"
            ? admin?.items?.find((x) => x?.uuid === currentUser)?.email
            : users?.find((x) => x?.uuid === currentUser)?.email
        }
        userLocation={
          manager === "Admin"
            ? admin?.items?.find((x) => x?.uuid === currentUser)?.location
            : users?.find((x) => x?.uuid === currentUser)?.location
        }
        showDropdown={manager === "Admin" ? true : false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 10,
  },
  innerContainer: {
    flex: 1,
    alignItems: "center",
    width: "100%",
    justifyContent: "space-evenly",
  },
  buttonContainer: {
    marginTop: 15,
    width: "100%",
    alignItems: "center",
  },
  title: {
    fontSize: 13,
    textAlign: "center",
    fontWeight: "600",
  },
  inner: {
    backgroundColor: "#fff",
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    justifyContent: "space-evenly",
    borderRadius: 6,
  },
  managerInner: {
    backgroundColor: "#fff",
    height: "50%",
    width: "100%",
    justifyContent: "space-evenly",
    padding: 10,
    borderRadius: 6,
  },
  flatListContainer: {
    flex: 1,
    padding: 10,
  },
  userContainer: {
    backgroundColor: "transparent",
    marginVertical: 3,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  modal: {
    backgroundColor: Colors.light.background,
  },
  bottomButton: {
    marginBottom: 100,
  },
});
