import {
  Dimensions,
  Keyboard,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import { FlatList, RefreshControl } from "react-native-gesture-handler";
import ListItem from "../../components/ListItem/Index";
import {
  SearchOrdersInput,
  useSearchOrders,
} from "../../hooks/orders/useSearchOrders";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import { formatDate } from "../../services/utils";
import { useGetReadEmails } from "../../hooks/email/useGetReadEmails";
import { useGetGmail } from "../../hooks/email/useGetGmail";
import EmailListItem from "./EmailListItem";
import Input from "../../components/Input";
import { Feather } from "@expo/vector-icons";
import { useForm } from "react-hook-form";
import { Toast } from "react-native-toast-message/lib/src/Toast";
const { width } = Dimensions.get("window");

interface ReadEmailProps {}

const ReadEmail: React.FC<ReadEmailProps> = ({ route }) => {
  const { task, color, title, email } = route.params;
  const [searchTerm, setSearchTerm] = useState("");

  const { data, isLoading, refetch, isFetching, fetchNextPage } = useGetGmail({
    emailToImpersonate: email,
    query: "",
    pageSize: 20,
    searchTerm: searchTerm,
    isSearch: title === "Search",
  });

  const flatData = () => {
    const flatArray: any = [];
    data?.pages?.map((page) => {
      flatArray.push.apply(flatArray, page?.gmailMessageSummaries);
    });
    return flatArray;
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const _search = watch("search");

  const onSubmit = (data: any) => {
    setSearchTerm(data?.search);
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <View style={styles.subContainer}>
        {title === "Search" ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <View style={{ width: width - 100, marginLeft: 20 }}>
              <Input
                search
                name={"search"}
                placeholder={"Search"}
                control={control}
                errors={errors}
                returnKeyType="search"
                onSubmitEditing={() => {
                  if (_search?.length > 0) {
                    handleSubmit(onSubmit)();
                  } else {
                    Keyboard.dismiss();
                  }
                }}
              />
            </View>
            <TouchableOpacity
              onPress={() => {
                if (_search?.length > 0) {
                  handleSubmit(onSubmit)();
                } else {
                  Toast.show({
                    type: "error",
                    props: {
                      message: "Please enter a search term",
                    },
                  });
                }
              }}
              style={{
                backgroundColor: Colors.light.navy,
                padding: 7,
                borderRadius: 4,
                marginTop: 12,
                marginRight: 20,
                marginLeft: 10,
              }}
            >
              <Feather name="search" size={24} color="white" />
            </TouchableOpacity>
          </View>
        ) : (
          <Button
            backgroundColor={color}
            isDisabled
            label={`${title}`}
            onPress={() => {}}
          />
        )}
        <View style={styles.flatContainer}>
          <FlashList
            data={flatData()}
            refreshControl={
              <RefreshControl refreshing={isFetching ? false : isLoading} />
            }
            renderItem={({ item, index }) => (
              <EmailListItem email={item} accountEmail={email} />
            )}
            estimatedItemSize={60}
            ListEmptyComponent={
              <LoadingState
                empty
                emptyIcon="mail"
                emptyText={title !== "Search" ? "No Mail" : "Search Emails"}
                refreshEnabled={title !== "Search"}
                refetch={() => {
                  refetch();
                }}
              />
            }
            loadingComponent={<LoadingState />}
            isLoading={isLoading}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
              fetchNextPage();
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: Colors.light.background,
  },
  body: {
    width: "100%",
    marginTop: 20,
  },
  containerStyle: { justifyContent: "flex-end", flex: 1 },
  subContainer: { flex: 1, width: "100%", alignItems: "center" },
  buttonContainer: {
    marginBottom: 60,
    alignItems: "center",
    height: 340,
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  flatContainer: {
    flex: 1,
    marginVertical: 20,
    width: "100%",
    borderRadius: 4,
    paddingVertical: 0,
    justifyContent: "center",
    backgroundColor: "white",
  },
});

export default ReadEmail;
