import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import React from "react";
import Colors from "../../../constants/Colors";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import instanceAXIOS from "../../../services/api";
import reactQuery from "../../../services/reactQuery";
import Toast from "react-native-toast-message";

interface TaskItemProps {
  id: string;
  task: string;
  complete: boolean;
  assignedDate: string;
  dueDate: string;
  createdByName: string;
  completedByName: string;
}

const TaskItem: React.FC<TaskItemProps> = ({
  id,
  task,
  complete,
  assignedDate,
  dueDate,
  createdByName,
  completedByName,
}) => {
  const navigation = useNavigation();

  const markComplete = async () => {
    await instanceAXIOS
      .post(`/UserTasks/Complete/`, {
        uuid: id,
      })
      .then((res) => {
        reactQuery.invalidateQueries("userTasks");
        Toast.show({
          type: "success",
          props: {
            message: "Task completed",
          },
        });
      });
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate("TaskDetails", {
          id,
          task,
          complete,
          assignedDate,
          completedOn: complete,
          dueDate,
          complete,
          createdByName,
          completedByName,
        });
      }}
    >
      <TouchableOpacity
        disabled={complete}
        onPress={() => {
          markComplete();
        }}
        hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      >
        <Feather
          name={!complete ? "square" : "check-square"}
          size={24}
          color={Colors.light.navy}
        />
      </TouchableOpacity>
      <Text numberOfLines={1} style={styles.taskText}>
        {task}
      </Text>
      <Feather name="chevron-right" size={24} color={Colors.light.navy} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 10,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  taskText: {
    fontSize: 16,
    fontFamily: "spectral-500",
    flex: 1,
    maxWidth: "70%",
  },
});

export default TaskItem;
