const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#000",
    background: "#FFF",
    messageOther: "#F0EBE6",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    brown: "#736B65",
    lightBrown: "#8f847c",
    lightBrownBackground: "#F7F5F2",
    navy: "#4D6786",
    green: "#454F19",
    gold: "#B69A52",
    pink: "#D0668A",
    orange: "#9D5E00",
    purple: "#7938AB",
    red: "#722C0E",
    messageBlue: "#0085FF",
    blue: "#479AB5",
    lightGreen: "#7C864D",
    limeGreen: "#54B652",
    danger: "#EC1717",
    pastelPink: "#D988FF",
    clientGreen: "#999887",
    black: "#000",
    lightGray: "#F2F2F2",
    darkGray: "#75706C",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};
