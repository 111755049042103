import { useContext, useEffect } from "react";
import { StyleSheet } from "react-native";
import React from "react";
import { Text, View } from "../../../components/Themed";
import Header from "../../../components/Header";
import Colors from "../../../constants/Colors";
import { RootTabScreenProps } from "../../../types";
import { useGetAssignedUsers } from "../../../hooks/users/useGetAssignedUsers";
import Context from "../../../context";
import LoadingState from "../../../components/LoadingState";
import UserListItem from "../../../components/UserListItem";
import FlashList from "../../../components/FlashList";

export default function ManagerTaskView({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const { user } = useContext(Context);
  const { data, isLoading, error } = useGetAssignedUsers(user?.uuid);

  return (
    <View style={styles.container}>
      <Header goBack />
      <Text style={styles.titlePage}>My Team</Text>
      <View style={styles.list}>
        <FlashList
          data={data}
          renderItem={({ item }) => (
            <UserListItem
              name={`${item?.firstName} ${item?.lastName}`}
              open
              onPress={() => {
                navigation.navigate("ManagerTasksByUser", {
                  user_uuid: item?.uuid,
                  user_name: `${item?.firstName} ${item?.lastName}`,
                });
              }}
            />
          )}
          estimatedItemSize={53}
          isLoading={isLoading}
          loadingComponent={<LoadingState />}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyText="No team members assigned"
              emptyIcon="users"
            />
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
    width: "96%",
    borderRadius: 10,
    backgroundColor: "white",
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
    marginBottom: 20,
  },
});
