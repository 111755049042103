import React from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Text,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import FlashList from "../FlashList";
import { Modalize } from "react-native-modalize";
import Input from "../Input";
import { useForm } from "react-hook-form";
import instanceAXIOS from "../../services/api";
import { useGetAvailableOrderTags } from "../../hooks/tags/getAvailabelOrderTags";

const { width } = Dimensions.get("window");

interface TagModalProps {
  orderTags: string[];
  modalizeRef: React.RefObject<Modalize>;
  updateOrder: () => void;
  orderUuid: string;
  removeTag: (tag: any) => void;
}

const TagModal: React.FC<TagModalProps> = ({
  orderTags,
  modalizeRef,
  updateOrder,
  orderUuid,
  removeTag,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const { data: availableTags, refetch } = useGetAvailableOrderTags(orderUuid);
  const [newTag, setNewTag] = React.useState(false);

  const onSave = (data) => {
    if (!data.tagname || data.tagName.length <= 0) return;
    instanceAXIOS.post("/Tags", data.tagname).then((res) => {
      refetch();
      addTag(res?.data);
      setNewTag(false);
    });
  };

  const addTag = (tag) => {
    instanceAXIOS.post(`/Orders/Tag/${orderUuid}/${tag.uuid}`).then((res) => {
      updateOrder();
    });
    setValue("tagname", "");
  };

  return (
    <>
      <View style={[styles.tagRow, { paddingTop: 10 }]}>
        <Text style={styles.title}>{newTag ? "Create Tag" : "Tags:"}</Text>
        {!newTag ? (
          <TouchableOpacity onPress={() => setNewTag(!newTag)}>
            <Feather name="plus" size={24} color="black" />
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={styles.body}>
        {newTag ? (
          <View style={styles.createBody}>
            <Input
              placeholder="Tag Name"
              name={"tagname"}
              control={control}
              errors={errors}
              maxLength={40}
              required
            />
            <View style={styles.createFooter}>
              <TouchableOpacity
                onPress={() => {
                  setNewTag(false);
                }}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.createFooterText}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleSubmit(onSave)}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.createFooterText}>Save</Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View style={{ height: 430 }}>
            <FlashList
              data={orderTags}
              renderItem={({ item }) => (
                <TouchableOpacity
                  onPress={() => {
                    removeTag(item);
                  }}
                >
                  <View style={styles.tagBody}>
                    <Feather name="x" size={14} color="black" />
                    <Text style={styles.tagTitle}>{item?.tagName}</Text>
                  </View>
                </TouchableOpacity>
              )}
              estimatedItemSize={50}
              horizontal
            />
            <View style={styles.tagList}>
              <Text>Add:</Text>
              <FlatList
                data={availableTags}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() => {
                      addTag(item);
                    }}
                  >
                    <View
                      style={[
                        styles.tagBody,
                        {
                          // make all tags the same width
                          width: width / 2 - 80,
                        },
                      ]}
                    >
                      <Text style={styles.tagTitle}>{item?.tagName}</Text>
                    </View>
                  </TouchableOpacity>
                )}
                ListEmptyComponent={<Text>No tags</Text>}
                style={{ marginBottom: orderTags ? 85 : 60 }}
              />
            </View>
            <View style={styles.createFooter}>
              <TouchableOpacity
                onPress={() => {
                  modalizeRef.current?.close();
                }}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Feather name="chevron-down" size={24} color="black" />
                <Text style={styles.createFooterText}>Done</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 20,
  },
  tagRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    width: width,
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modal: {
    backgroundColor: "white",
  },
  tagBody: {
    flexDirection: "row",
    alignItems: "center",
    padding: 6,
    backgroundColor: "#F0EBE7",
    margin: 3,
    borderRadius: 5,
    justifyContent: "center",
  },
  tagTitle: {
    fontFamily: "spectral-600",
    fontSize: 10,
    textAlign: "left",
    marginLeft: 4,
  },
  tagList: {
    paddingTop: 20,
  },
  createBody: {
    height: 430,
  },
  createFooter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    paddingTop: 8,
    // height: 60,
  },
  createFooterText: {
    fontSize: 16,
    fontWeight: "600",
  },
});

export default TagModal;
