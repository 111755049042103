import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

export const storeLocalData = async (key: string, value: any) => {
  try {
    if (Platform.OS === "web") {
      // Store in localStorage
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      // Store using SecureStore
      await SecureStore.setItemAsync(key, JSON.stringify(value));
    }
  } catch (e) {
    // Saving error
    console.error(e);
  }
};

export const getLocalData = async (key: string) => {
  try {
    if (Platform.OS === "web") {
      // Retrieve from localStorage
      const value = localStorage.getItem(key);
      if (value !== null) {
        return JSON.parse(value);
      }
    } else {
      // Retrieve using SecureStore
      const value = await SecureStore.getItemAsync(key);
      if (value !== null) {
        return JSON.parse(value);
      }
    }
  } catch (e) {
    // Error reading value
    console.error(e);
  }
};

export const clearLocalData = async () => {
  try {
    if (Platform.OS === "web") {
      // Clear localStorage
      for (const key in STORAGE_KEYS) {
        localStorage.removeItem(STORAGE_KEYS[key]);
      }
    } else {
      // Clear using SecureStore
      for (const key in STORAGE_KEYS) {
        await SecureStore.deleteItemAsync(STORAGE_KEYS[key]);
      }
    }
  } catch (e) {
    // Remove error
    console.error(e);
  }
};

export const removeKey = async (key: string) => {
  try {
    if (Platform.OS === "web") {
      // Remove from localStorage
      localStorage.removeItem(key);
    } else {
      // Remove using SecureStore
      await SecureStore.deleteItemAsync(STORAGE_KEYS[key]);
    }
  } catch (e) {
    // Remove error
    console.error(e);
  }
};

//list of all keys
export const STORAGE_KEYS = {
  KEY_TOKEN: "token",
  KEY_USER: "user",
  HISTORY: "history",
};
