import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetConversationUsers = (orderUuid: string) => {
  const { data, isLoading, refetch } = useQuery(
    ["ConversationUsers", orderUuid],
    () => getConversationUsers(orderUuid)
  );

  return { data, isLoading, refetch };
};

const getConversationUsers = async (orderUuid?: string) => {
  const { data } = await instanceAXIOS.get(
    `/OrderConversation/Users/${orderUuid}`
  );
  return data;
};
