import React, { SetStateAction, Dispatch } from "react";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import { StyleSheet } from "react-native";
import { Text } from "../Themed";
import DropDownPicker from "react-native-dropdown-picker";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";

interface Props {
  required?: boolean;
  name: string;
  label: string;
  control: Control<any>;
  errors: FieldErrors;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  value: string | null;
  items: { label: string; value: string }[];
  setValue: Dispatch<SetStateAction<null>>;
  setItems: Dispatch<SetStateAction<{ label: string; value: string }[]>>;
}

const Dropdown: React.FC<Props> = ({
  required,
  name,
  label,
  control,
  errors,
  open,
  setOpen,
  value,
  items,
  setValue,
  setItems,
  width,
}) => {
  return (
    <>
      <Controller
        control={control}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, onBlur } }) => (
          <>
            <Text style={styles.label}>
              {label}{" "}
              {errors[name] && (
                <Text style={styles.error}>*This is required</Text>
              )}
            </Text>
            <DropDownPicker
              style={{
                width: width ? width : "100%",
                borderWidth: 1.5,
                borderColor: "#736B65",
                borderRadius: 3,
                minHeight: 42,
                marginBottom: 4,
              }}
              ArrowDownIconComponent={() => (
                <Feather
                  name="chevron-down"
                  size={24}
                  color={Colors.light.brown}
                />
              )}
              ArrowUpIconComponent={() => (
                <Feather
                  name="chevron-up"
                  size={24}
                  color={Colors.light.brown}
                />
              )}
              placeholderStyle={{ color: Colors.light.brown, padding: 0 }}
              dropDownContainerStyle={{
                borderWidth: 1.5,
                borderTopWidth: 1,
                borderColor: "#736B65",
                borderRadius: 3,
              }}
              textStyle={{
                color: Colors.light.brown,
                fontFamily: "spectral-600",
                width: width - 40,
                height: 20,
              }}
              listItemLabelStyle={{
                color: Colors.light.brown,
                fontFamily: "spectral-600",
              }}
              selectedItemLabelStyle={{
                color: "black",
                fontFamily: "spectral-600",
              }}
              selectedItemContainerStyle={{
                backgroundColor: Colors.light.background,
              }}
              hideSelectedItemIcon={true}
              open={open}
              value={value}
              placeholder="Select.."
              items={items}
              setOpen={setOpen}
              setValue={(value: any) => {
                setValue(value);
              }}
              setItems={setItems}
            />
          </>
        )}
        name={name}
      />
    </>
  );
};

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    color: "#736B65",
    textAlign: "left",
    fontFamily: "spectral-600",
    marginBottom: 2,
  },
  error: {
    color: "red",
    fontSize: 12,
  },
});

export default Dropdown;
