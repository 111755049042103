import { ActivityIndicator, StyleSheet, TouchableOpacity } from "react-native";
import React from "react";
import { Text, View } from "../Themed";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";

interface LoadingStateProps {
  empty?: boolean;
  emptyText?: string;
  emptyIcon?: string;
  loadingColor?: string;
  refreshEnabled?: boolean;
  refetch?: () => void;
}

const LoadingState: React.FC<LoadingStateProps> = ({
  empty = false,
  emptyText = "No Results",
  emptyIcon = "box",
  loadingColor = Colors.light.navy,
  refreshEnabled = false,
  refetch = () => {},
}) => {
  return (
    <View style={styles.container}>
      {empty ? (
        <>
          <Feather name={emptyIcon} size={24} color={Colors.light.brown} />
          <Text style={styles.loadingText}>{emptyText}</Text>
          {refreshEnabled && (
            <TouchableOpacity onPress={refetch} hitSlop={styles.hit}>
              <Text style={styles.refrechText}>Refresh</Text>
            </TouchableOpacity>
          )}
        </>
      ) : (
        <ActivityIndicator size="large" color={loadingColor} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 40,
  },
  hit: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    alignSelf: "center",
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  refrechText: {
    fontSize: 16,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.navy,
  },
});

export default LoadingState;
