import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClientProvider } from "react-query";
import Toast from "react-native-toast-message";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import queryClient from "./services/reactQuery";
import Toaster from "./components/Toaster";
import React from "react";
import { ContextProvider } from "./context";
import { LogBox } from "react-native";
import { useEffect } from "react";
import { Text, TextInput } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import * as Linking from "expo-linking";

const prefix = Linking.createURL("/");

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const linking = {
    prefixes: [prefix],
  };

  LogBox.ignoreAllLogs();
  const toastConfig = {
    success: (internalState) => (
      <Toaster type="success" message={internalState.props.message} />
    ),
    default: (internalState) => (
      <Toaster type="default" message={internalState.props.message} />
    ),
    error: (internalState) => (
      <Toaster type="error" message={internalState.props.message} />
    ),
    warning: (internalState) => (
      <Toaster type="warning" message={internalState.props.message} />
    ),
  };

  useEffect(() => {
    if (Text.defaultProps == null) Text.defaultProps = {};
    Text.defaultProps.allowFontScaling = false;

    if (TextInput.defaultProps == null) TextInput.defaultProps = {};
    TextInput.defaultProps.allowFontScaling = false;
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <GestureHandlerRootView style={{ flex: 1 }}>
        <QueryClientProvider client={queryClient}>
          <SafeAreaProvider>
            <ContextProvider>
              <Navigation linking={linking} colorScheme={colorScheme} />
              <StatusBar style="dark" />
            </ContextProvider>
          </SafeAreaProvider>
          <Toast config={toastConfig} />
        </QueryClientProvider>
      </GestureHandlerRootView>
    );
  }
}
