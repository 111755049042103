import React, { useContext, useEffect } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
} from "react-native";
import { View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import Button from "../../components/Button";
import { useNavigation } from "@react-navigation/native";
import reactQuery from "../../services/reactQuery";
import ModalHeader from "../../components/ModalHeader";
import * as ImagePicker from "expo-image-picker";
import {
  UploadInput,
  useGetUploadKey,
} from "../../hooks/uploads/useGetUploadKey";
import Context from "../../context";
// import { useUpload } from "../../hooks/uploads/useUpload";
import { Image } from "expo-image";
import VideoPlayer from "expo-video-player";
import { ResizeMode } from "expo-av";
import ActionButton from "../../components/ActionButton";
import { useForm } from "react-hook-form";
import instanceAXIOS from "../../services/api";
import queryClient from "../../services/reactQuery";
import Toast from "react-native-toast-message";
const { width, height } = Dimensions.get("window");
import { Feather } from "@expo/vector-icons";
type createNoteInput = {
  orderProductUploadUuid: string;
  isPrivate: boolean;
  note: string;
};

export default function UploadImageAndComment({
  route,
}: RootTabScreenProps<"TabOne">) {
  const { goBack } = useNavigation();
  const { user } = useContext(Context);
  const { navigation } = useContext(Context);
  const { orderProductUuid, title, imageUploadType } = route.params;
  const getUploadKey = useGetUploadKey();
  // const upload = useUpload();
  const [selectedImage, setSelectedImage] = React.useState(null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [internal, setInternal] = React.useState(false);

  useEffect(() => {
    setValue("orderProductUploadUuid", orderProductUuid);
    setValue("isPrivate", false);
  }, [orderProductUuid]);

  const setIsPrivate = (value: boolean) => {
    setInternal(value);
    setValue("isPrivate", value);
  };

  const onSubmit = (data: createNoteInput) => {
    instanceAXIOS
      .post("/OrderProductUploadNote", {
        orderProductUploadUuid: data.orderProductUploadUuid,
        isPrivate: data.isPrivate,
        note: data.note,
      })
      .then((response) => {
        queryClient.invalidateQueries("orderDetails");
        navigation.goBack();
        Toast.show({
          type: "success",
          props: {
            message: "User created successfully",
          },
        });
      });
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      videoMaxDuration: 240,
      quality: 0.5,
      videoQuality: 3,
    });

    if (result?.assets) {
      const _image = result?.assets[0];
      const _fileName = `upload-${Date.now()}`;

      const inputeImage = {
        isPrivate: _image?.type === "image" ? false : true,
        fileName: _fileName,
        orderProductUuid: orderProductUuid,
        uploadType: imageUploadType,
        isClient: user?.role === "client",
      } as UploadInput;

      setSelectedImage(_image);

      // getUploadKey.mutateAsync(inputeImage).then((_res) => {
      //   upload.mutateAsync({
      //     fileName: _fileName,
      //     uri: _image.uri,
      //     type: _image.type,
      //     fileSize: _image.fileSize,
      //     uploadKey: _res.blobUri,
      //   });
      // });

      // const image = {
      //   id: Math.random().toString(),
      //   uri: result.assets[0].uri,
      // };

      if (!result.canceled) {
        // setImages([...images, image]);
      }
    }
    // setTimeout(() => {
    //   reactQuery.invalidateQueries("orders");
    //   reactQuery.invalidateQueries("orderDetails");
    //   _modalizeRef.current?.close();
    // }, 1000);
  };

  return (
    <View style={styles.container}>
      <ModalHeader goBack headerText={title} />
      <View
        style={{
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 36,
          width: "100%",
        }}
      >
        {selectedImage?.type === "image" || !selectedImage ? (
          <Image
            style={styles.image}
            cachePolicy="memory"
            priority={"high"}
            source={{ uri: selectedImage?.uri }}
            contentFit="cover"
            backgroundColor={Colors.light.navy}
            transition={100}
          />
        ) : (
          <View style={styles.image}>
            <VideoPlayer
              slider={{
                visible: false,
              }}
              fullscreen={{
                visible: false,
              }}
              style={{
                width: width * 0.8,
                height: width * 0.7,
              }}
              timeVisible={false}
              videoProps={{
                shouldPlay: false,
                resizeMode: ResizeMode.CONTAIN,
                rotation: 180,
                source: {
                  uri: selectedImage?.uri,
                },
              }}
            />
          </View>
        )}
        <KeyboardAvoidingView
          behavior={"height"}
          keyboardVerticalOffset={Platform.OS === "ios" ? 0 : 20}
          style={styles.keyboardAvoidingView}
          contentContainerStyle={{ flex: 1 }}
        >
          <Text style={styles.label}>Visibilty</Text>
          <View style={styles.rowContainer}>
            <View style={styles.rowPosition}>
              <View style={{ width: 180, opacity: internal ? 1 : 0.4 }}>
                <ActionButton
                  onPress={() => setIsPrivate(true)}
                  label="Internal Comment"
                  color={Colors.light.green}
                  icon={
                    <Feather
                      name="lock"
                      size={14}
                      color={Colors.light.background}
                    />
                  }
                />
              </View>
              <View style={{ width: 180, opacity: !internal ? 1 : 0.4 }}>
                <ActionButton
                  onPress={() => setIsPrivate(false)}
                  label="Client Comment"
                  color={Colors.light.red}
                  icon={
                    <Feather
                      name="eye"
                      size={14}
                      color={Colors.light.background}
                    />
                  }
                />
              </View>
            </View>
          </View>
        </KeyboardAvoidingView>
        <View>
          <Button
            label="Photo Library"
            onPress={() => {
              pickImage();
            }}
          />
          <Button label="Open Camera" onPress={() => {}} />
          <View style={{ height: 40 }} />
          <Button
            label="Upload"
            backgroundColor={Colors.light.navy}
            onPress={() => {}}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    alignItems: "center",
    flex: 1,
  },
  image: {
    width: width * 0.8,
    height: width * 0.7,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
