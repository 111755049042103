import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { Feather } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import * as Haptics from "expo-haptics";

interface CheckboxProps {
  value: boolean;
  onPress: () => void;
  label: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ value, onPress, label }) => {
  const _updateCheckbox = () => {
    onPress();
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  };

  return (
    <View style={styles.body}>
      <Text style={styles.label}>{label}</Text>
      <TouchableOpacity
        onPress={_updateCheckbox}
        hitSlop={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        }}
      >
        <Feather
          name={!value ? "square" : "check-square"}
          size={26}
          color={Colors.light.brown}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 14,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: Colors.light.brown,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginRight: 10,
    fontFamily: "spectral-600",
    fontSize: 14,
    color: Colors.light.brown,
  },
});

export default Checkbox;
