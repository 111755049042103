import { StyleSheet, View, Text } from "react-native";

import React from "react";
import { RootTabScreenProps } from "../../types";
import { useGetEmailMessage } from "../../hooks/email/useGetGmailMessage";
import Header from "../../components/Header";
import { ScrollView } from "react-native-gesture-handler";
import EmailItem from "./EmailItem";
import { getTimeOrDate } from "../../services/utils";
import LoadingState from "../../components/LoadingState";
import Colors from "../../constants/Colors";

export default function ReadEmailMessage({
  route,
}: RootTabScreenProps<"TabOne">) {
  const { emailId, emailToImpersonate } = route.params;

  const { data, isLoading } = useGetEmailMessage({
    emailToImpersonate: emailToImpersonate,
    id: emailId,
  });

  return (
    <>
      <Header goBack />
      {!isLoading ? (
        <ScrollView style={{}}>
          <>
            <View style={styles.info}>
              <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                {
                  data?.payload?.headers?.find(
                    (header) => header.name === "Subject"
                  )?.value
                }
              </Text>
              <Text>
                {
                  data?.payload?.headers?.find(
                    (header) => header.name === "From"
                  )?.value
                }
              </Text>
              <Text>
                {
                  data?.payload?.headers?.find((header) => header.name === "To")
                    ?.value
                }
              </Text>
              <Text>
                {getTimeOrDate(
                  data?.payload?.headers?.find(
                    (header) => header.name === "Date"
                  )?.value
                )}
              </Text>
            </View>
            {data?.payload?.body?.data ? (
              <EmailItem html={data?.payload?.body?.data} index={1} />
            ) : data?.payload?.parts?.length > 0 ? (
              <>
                {data?.payload?.parts.map((part, index) => {
                  if (part?.parts?.length > 0) {
                    return part.parts.map((part2, index2) => (
                      <EmailItem
                        html={part2?.body?.data}
                        index={index2}
                        key={index2}
                      />
                    ));
                  } else {
                    return (
                      <>
                        {part?.body?.data ? (
                          <EmailItem
                            html={part?.body?.data}
                            index={index}
                            key={index}
                          />
                        ) : (
                          <View
                            style={{
                              paddingHorizontal: 10,
                              paddingVertical: 20,
                              backgroundColor: "#fff",
                              borderBottomColor: Colors.light.brown,
                              borderBottomWidth: 1,
                            }}
                          >
                            <Text>{JSON.stringify(part)}</Text>
                          </View>
                        )}
                      </>
                    );
                  }
                })}
              </>
            ) : (
              <Text style={styles.body}>
                There was an error rendering the email
              </Text>
            )}
          </>
        </ScrollView>
      ) : (
        <LoadingState />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    fontSize: 14,
    marginTop: 10,
    paddingHorizontal: 10,
  },
  info: {
    backgroundColor: "#fff",
    padding: 10,
    borderBottomWidth: 1,
  },
});
