import React, { useState } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  ActivityIndicator,
} from "react-native";
import Colors from "../../../constants/Colors";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Feather } from "@expo/vector-icons";
import Button from "../../../components/Button";
import { Controller, useForm } from "react-hook-form";
import useSendMessage from "../../../hooks/messaging/useSendMessage";
import { capitalizeFirstLetter } from "../../../services/utils";

interface ChatInputBarProps {
  conversationUser: boolean;
  isLoading: boolean;
  onPressMedia: () => void;
  onPressIsMember: () => void;
  onSend: (text: string) => void;
}

const ChatInputBar: React.FC<ChatInputBarProps> = ({
  conversationUser,
  isLoading,
  onPressMedia,
  onPressIsMember,
  onSend,
}) => {
  const insets = useSafeAreaInsets();
  const [inputHeight, setInputHeight] = useState<number>(40);
  const [inputText, setInputText] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const sendMessageMutation = useSendMessage();

  const _onMediaPress = () => {
    Keyboard.dismiss();
    onPressMedia();
  };

  const _joinConversation = () => {
    onPressIsMember();
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("Message", data.input);
    formData.append("OrderConversationUserUuid", conversationUser.uuid);
    formData.append("OrderUuid", conversationUser.orderUuid);
    formData.append("Attachment", null);
    formData.append("FileName", null);
    formData.append("FileExtension", null);
    formData.append("IsImage", false);
    sendMessageMutation.mutate({
      formData: formData,
      message: data.input,
      orderConversationUserUuid: conversationUser.uuid,
      orderUuid: conversationUser.orderUuid,
      attachment: null,
      fileName: null,
      fileExtension: null,
      isImage: false,
      conversationUuid: conversationUser.orderUuid,
      name: `${capitalizeFirstLetter(conversationUser?.firstName)} ${capitalizeFirstLetter(conversationUser?.lastName)}`
    });
    setValue("input", null);
  };

  return (
    <>
      {conversationUser ? (
        <KeyboardAvoidingView
          behavior={"padding"}
          keyboardVerticalOffset={Platform.OS === "ios" ? 0 : 20}
          contentContainerStyle={styles.flex}
        >
          <View style={styles.body}>
            <View style={styles.row}>
              <TouchableOpacity
                onPress={() => {
                  _onMediaPress();
                }}
                hitSlop={styles.hit}
                style={styles.icon}
              >
                <Feather name={"camera"} size={24} color={Colors.light.brown} />
              </TouchableOpacity>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    multiline
                    onChangeText={onChange}
                    value={value}
                    onContentSizeChange={(e) => {
                      const newHeight = e.nativeEvent.contentSize.height + 20;
                      if (newHeight < 200) {
                        setInputHeight(newHeight);
                      }
                    }}
                    style={[
                      styles.input,
                      {
                        height: inputHeight,
                      },
                    ]}
                  />
                )}
                name={"input"}
              />
              <TouchableOpacity
                onPress={() => {
                  handleSubmit(onSubmit)();
                }}
                hitSlop={styles.hit}
                style={styles.icon}
              >
                <Feather name={"send"} size={24} color={Colors.light.brown} />
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      ) : (
        <View style={styles.joinContainer}>
          {isLoading ? (
            <ActivityIndicator size={"large"} color={Colors.light.brown} />
          ) : (
            <Button
              label="Join Conversation"
              onPress={() => _joinConversation()}
            />
          )}
        </View>
      )}
      <View
        style={[
          styles.padding,
          {
            height: insets.bottom,
          },
        ]}
      />
    </>
  );
};

const styles = StyleSheet.create({
  body: {
    backgroundColor: Colors.light.background,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  },
  padding: { backgroundColor: Colors.light.background },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: 16,
  },
  flex: { flex: 1 },
  icon: {
    paddingTop: 8,
  },
  input: {
    backgroundColor: "white",
    flex: 1,
    marginHorizontal: 10,
    borderRadius: 10,
    minHeight: 40,
    maxHeight: 200,
    padding: 10,
    paddingTop: 12,
    borderWidth: 1,
    borderColor: Colors.light.brown,
  },
  hit: {
    top: 20,
    left: 20,
    right: 20,
    bottom: 20,
  },
  joinContainer: {
    backgroundColor: Colors.light.background,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
});

export default ChatInputBar;
