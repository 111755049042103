import React from "react";
import { StyleSheet, TouchableOpacity, RefreshControl } from "react-native";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import { OrderStatus, RootTabScreenProps } from "../../types";
import StatusPill from "../../components/StatusPill";
import { useGetOrderStatusCounts } from "../../hooks/orders/useGetOrderStatusCounts";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";

export default function StageTotals({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const stages: OrderStatus[] = [
    "orderReceived",
    "bouquetReceived",
    "checkedOn",
    "progress",
    "almostReadyToFrame",
    "readyToFrame",
    "frameCompleted",
    "noResponse",
    "approved",
    "disapproved",
    "readyToSeal",
    "preparingToBeShipped",
    "shipped",
  ];

  const {
    data: counts,
    isLoading,
    isFetched,
    refetch,
  } = useGetOrderStatusCounts();

  return (
    <>
      <Header goBack />
      <View style={styles.container}>
        <FlashList
          data={stages}
          animationType="fade"
          estimateItemSize={60}
          loadingComponent={<LoadingState />}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyText="Unable to load stage totals"
              emptyIcon="package"
              refreshEnabled
              refetch={() => {
                refetch();
              }}
            />
          }
          refreshControl={
            <RefreshControl
              refreshing={!isFetched ? false : isLoading}
              onRefresh={() => {
                refetch();
              }}
            />
          }
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.listItem}
              onPress={() =>
                navigation.navigate("StageDetails", {
                  stage: item,
                  count: counts?.find((x) => x.status === item)?.count
                    ? counts?.find((x) => x.status === item)?.count
                    : 0,
                })
              }
            >
              <StatusPill
                status={item}
                count={
                  counts?.find((x) => x.status === item)?.count
                    ? counts?.find((x) => x.status === item)?.count
                    : 0
                }
              />
            </TouchableOpacity>
          )}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  listItem: {
    marginVertical: 10,
    maxWidth: 225,
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4,
  },
});
