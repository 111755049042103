import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetAvailableOrderTags = (orderUuid: string) => {
  return useQuery(["tags"], () => getTags(orderUuid), {
    cacheTime: 240000,
  });
};

const getTags = async (orderUuid: string) => {
  const { data } = await instanceAXIOS.get(
    `/Orders/AvailableTags/${orderUuid}`
  );
  return data;
};
