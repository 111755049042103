import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useOrderProductFilter = (
  assignedToUserUuid: string,
  startDate: string,
  endDate: string
) => {
  const fetchOrders = async (pageParam: { pageParam: number }) => {
    try {
      const offset = new Date().getTimezoneOffset();
      let obj = {
        pageNumber: parseInt(pageParam?.pageParam) || 1,
        pageSize: 20,
        orderByOrderProductUploadDate: "ASC",
        assignedToUserUuid: assignedToUserUuid,
        dateRange: {
          startDate: startDate,
          endDate: endDate,
          offset: -offset,
        },
      };
      const res = await instanceAXIOS.post(
        `/OrderProducts/SearchByFrameUpload`,
        obj
      );
      return res.data;
    } catch (err) {
      Toast.show({
        type: "error",
        props: {
          message: "There was an error fetching orders",
        },
      });
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    refetch,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["orderFilters", assignedToUserUuid, startDate, endDate],
    fetchOrders,
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length < 20) {
          return undefined;
        }
        return pages.length + 1;
      },
      enabled: assignedToUserUuid !== null,
    }
  );

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    isLoading,
  };
};

export interface OrderProductFilter {
  pageNumber: number;
  pageSize: number;
  orderProductStatusFilter: [string];
  orderByOrderProductUploadDate: string;
  assignedToUserUuid: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}
