import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrderCountsForUser = () => {
  return useQuery(["countsForUser"], () => getOrderCountsForUser());
};

const getOrderCountsForUser = async () => {
  const { data } = await instanceAXIOS.get(`/OrderProducts/CountsForUser/`);
  return data;
};
