import React from "react";
import { Text, View } from "react-native";
import Colors from "../../constants/Colors";

interface ToastProps {
  message: string;
  type: "success" | "warning" | "error" | "default";
}

const Toaster: React.FC<ToastProps> = ({ message, type }) => {
  const color = () => {
    switch (type) {
      case "success":
        return Colors.light.green;
      case "warning":
        return Colors.light.gold;
      case "error":
        return Colors.light.danger;
      case "default":
        return Colors.light.green;
    }
  };

  return (
    <View
      style={{
        backgroundColor: color(),
        minWidth: 50,
        width: "auto",
        minHeight: 30,
        marginTop: 10,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 30,
        padding: 10,
      }}
    >
      <Text
        style={{ color: "#fff", textAlign: "center", paddingHorizontal: 8 }}
      >
        {message}
      </Text>
    </View>
  );
};

export default Toaster;
