import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import Colors from "../../../constants/Colors";
import Note from "./Note";
import { NoteObj } from "../../../components/UploadedImage";

interface ViewNotesProps {
  notes: NoteObj[];
}

const ViewNotes: React.FC<ViewNotesProps> = ({ notes }) => {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <View style={styles.body}>
      <TouchableOpacity
        disabled={notes.length === 0}
        hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        onPress={() => setShowNotes(!showNotes)}
      >
        {!showNotes ? (
          <Text style={styles.title}>
            {notes.length > 0
              ? `View Comments (${notes.length})`
              : "No Comments"}
          </Text>
        ) : (
          <Text style={styles.title}>Hide Comments</Text>
        )}
      </TouchableOpacity>
      {showNotes && (
        <>
          {notes.map((note: NoteObj) => (
            <Note note={note} />
          ))}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    padding: 20,
    width: "100%",
  },
  title: {
    fontSize: 14,
    color: Colors.light.brown,
    fontWeight: "bold",
  },
});

export default ViewNotes;
