import { useContext, useEffect, useState } from "react";
import { FlatList, RefreshControl, ScrollView, StyleSheet } from "react-native";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import React from "react";
import Context from "../../context";
import { useGetOrderCountsForUser } from "../../hooks/orders/useGetOrderCountsForUser";
import { useSearchOrders } from "../../hooks/orders/useSearchOrders";
import { useGetStatusLabel } from "../../hooks/useGetStatusInfo";

export default function Tasks({ navigation }: RootTabScreenProps<"TabOne">) {
  const { user } = useContext(Context);
  const [isManager, setIsManager] = useState(false);
  const {
    data: counts,
    isLoading,
    refetch,
    isFetched,
  } = useGetOrderCountsForUser();

  //temp fix
  const [searchOrdersInput, setSearchOrdersInput] = useState<SearchOrdersInput>(
    {
      pageNumber: 1,
      pageSize: 20,
      searchTerm: " ",
      orderProductStatusFilter: ["FrameCompleted", "NoResponse"],
      orderByOrderDate: 0,
      assignedToMe: true,
      assignedToMyTeam: false,
    }
  );

  const {
    data,
    fetchNextPage,
    refetch: refetch2,
    isFetching,
  } = useSearchOrders(searchOrdersInput);

  const flatData = () => {
    const flatArray: any = [];
    data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  useEffect(() => {
    if (user?.role === "manager" || user?.role === "admin") {
      setIsManager(true);
    }
  }, [user]);

  const tasksArray = (status) => {
    switch (status) {
      case "FrameCompleted":
        return ["FrameCompleted", "NoResponse"];
      case "NoResponse":
        return ["FrameCompleted", "NoResponse"];
      case "Approved":
        return ["Approved"];
      case "Disapproved":
        return ["Disapproved"];
      case "ReadyToSeal":
        return ["ReadyToSeal"];
      default:
        return ["FrameCompleted", "NoResponse"];
    }
  };

  const StatusButton = ({ item }) => {
    const label = useGetStatusLabel(item.replace("Count", ""));
    return (
      <Button
        label={`${counts?.[item]} - ${label}`}
        onPress={() => {
          navigation.navigate("OrdersByTask", {
            title: label,
            task: tasksArray(item.replace("Count", "")),
            color: Colors.light.brown,
            count: counts?.[item],
          });
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Header profile />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!isFetched ? false : isLoading}
            onRefresh={() => {
              refetch();
            }}
          />
        }
        style={styles.subContainer}
        contentContainerStyle={styles.containerStyle}
      >
        <Text style={styles.titlePage}>Tasks</Text>
        <View style={styles.buttonContainer}>
          <Button
            label={`All (${flatData()?.length})`}
            onPress={() => {
              navigation.navigate("OrdersByTask", {
                title: "All",
                task: ["FrameCompleted", "NoResponse"],
                color: Colors.light.brown,
                count: flatData()?.length,
              });
            }}
          />
          <View style={{ paddingVertical: 16, width: "100%" }}>
            <FlatList
              data={counts ? Object.keys(counts) : []}
              renderItem={({ item }) => <StatusButton item={item} />}
              keyExtractor={(item) => item}
              scrollEnabled={false}
              contentContainerStyle={styles.flatListContent}
              style={{ width: "100%" }}
            />
          </View>
          {isManager ? (
            <>
              <Button
                backgroundColor={Colors.light.navy}
                label={`Team Member Tasks`}
                onPress={() => {
                  navigation.navigate("ManagerTaskView");
                }}
              />
              <Button
                backgroundColor={Colors.light.navy}
                label={`Tasks from your manager`}
                onPress={() => {
                  navigation.navigate("ManagerTasks");
                }}
              />
            </>
          ) : (
            <Button
              backgroundColor={Colors.light.navy}
              label={`Tasks from your manager`}
              onPress={() => {
                navigation.navigate("ManagerTasks");
              }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
    marginBottom: 20,
  },
  containerStyle: { flex: 1 },
  subContainer: { flex: 1, width: "100%" },
  buttonContainer: {
    marginBottom: 60,
    alignItems: "center",
    height: 340,
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  flatListContent: {
    flexGrow: 0,
    alignItems: "center",
  },
});
