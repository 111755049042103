import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrderStatusCounts = () => {
  return useQuery(["orderStatusCounts"], () => getOrderStatusCounts());
};

const getOrderStatusCounts = async () => {
  const { data } = await instanceAXIOS.get(`/OrderProducts/Counts`);
  return data;
};
