import { ScrollView, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import { FlatList, RefreshControl } from "react-native-gesture-handler";
import ListItem from "../../components/ListItem/Index";
import {
  SearchOrdersInput,
  useSearchOrders,
} from "../../hooks/orders/useSearchOrders";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import { formatDate } from "../../services/utils";

interface OrdersByTaskProps {}

const OrdersByTask: React.FC<OrdersByTaskProps> = ({ route }) => {
  const { task, color, title, count } = route.params;

  const [searchOrdersInput, setSearchOrdersInput] = useState<SearchOrdersInput>(
    {
      pageNumber: 1,
      pageSize: 20,
      searchTerm: " ",
      orderProductStatusFilter: task,
      orderByOrderDate: 0,
      assignedToMe: title === "Ready to Seal" ? false : true,
      assignedToMyTeam: false,
    }
  );

  const { data, isLoading, fetchNextPage, refetch, isFetching } =
    useSearchOrders(searchOrdersInput);

  const flatData = () => {
    const flatArray: any = [];
    data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <View style={styles.subContainer}>
        <Button
          backgroundColor={color}
          isDisabled
          label={`${count} - ${title}`}
          onPress={() => {}}
        />
        <View style={styles.flatContainer}>
          <FlashList
            data={flatData()}
            refreshControl={
              <RefreshControl refreshing={isFetching ? false : isLoading} />
            }
            renderItem={({ item, index }) => (
              <ListItem
                index={index}
                orderNumber={item.shopifyOrderNumber}
                orderStatus={item.status}
                orderDate={formatDate(item.orderDate)}
                orderName={`${item.clientFirstName} ${item.clientLastName}`}
                eventDate={formatDate(item.eventDate)}
                orderNotes={index}
                orderUuid={item?.orderUuid}
                orderProductUuid={item.uuid}
                image={item?.frameValetKey?.blobUri}
                action={task}
                actionTitle={
                  item.status === "approved"
                    ? "Glued"
                    : item.status === "readyToSeal"
                    ? "Sealed"
                    : null
                }
                nextStatus={
                  item.status === "approved"
                    ? "ReadyToSeal"
                    : item.status === "readyToSeal"
                    ? "PreparingToBeShipped"
                    : null
                }
              />
            )}
            keyExtractor={(item) => item.uuid}
            estimatedItemSize={60}
            contentContainerStyle={styles.flatlist}
            ListEmptyComponent={
              <LoadingState
                empty
                emptyIcon="package"
                emptyText="No Orders Found"
                refreshEnabled
                refetch={() => {
                  refetch();
                }}
              />
            }
            loadingComponent={<LoadingState />}
            isLoading={isLoading}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
              fetchNextPage();
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: Colors.light.background,
  },
  flatlist: {
    paddingBottom: 100,
  },
  body: {
    width: "100%",
    marginTop: 20,
  },
  containerStyle: { justifyContent: "flex-end", flex: 1 },
  subContainer: { flex: 1, width: "100%", alignItems: "center" },
  buttonContainer: {
    marginBottom: 60,
    alignItems: "center",
    height: 340,
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  flatContainer: {
    flex: 1,
    marginVertical: 20,
    width: "100%",
    borderRadius: 4,
    paddingVertical: 0,
    justifyContent: "center",
    backgroundColor: "white",
  },
});

export default OrdersByTask;
