import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";

export type ResetUnreadMessageCountInput = {
  orderUuid: string;
};

const resetUnreadMessageCount = async (_data: ResetUnreadMessageCountInput) => {
  const res = await instanceAXIOS.post(
    `/OrderConversation/ResetUnreadMessageCount/${_data.orderUuid}`
  );
  return res.data;
};

export const useResetUnreadMessageCount = () => {
  return useMutation(
    (_data: ResetUnreadMessageCountInput) => resetUnreadMessageCount(_data),
    {
      onSuccess: (data: any) => {
        return data;
      },
      onError: () => {
        console.log("Unable to reset unread message count");
      },
    }
  );
};

export default useResetUnreadMessageCount;
