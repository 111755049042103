import { StyleSheet, View, TouchableOpacity, Dimensions } from "react-native";
import React from "react";
import { Text } from "../Themed";
import { OrderStatus } from "../../types";
import Colors from "../../constants/Colors";
import StatusPill from "../StatusPill";
import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const { width } = Dimensions.get("window");
interface TileListItemProps {
  orderNumber: number;
  orderStatus: OrderStatus;
  orderDate: string;
  orderName: string;
  orderNotes: string;
  eventDate: string;
  image: string;
  imageUuid: string;
  orderProductUuid: string;
}

const TileListItem: React.FC<TileListItemProps> = ({
  orderNumber,
  orderStatus,
  orderDate,
  orderName,
  orderNotes,
  eventDate,
  image,
  imageUuid,
  orderProductUuid,
}) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.body}
      onPress={() => {
        navigation.navigate("OrderDetails", {
          orderNumber: orderNumber,
          orderStatus: orderStatus,
          orderDate: orderDate,
          orderName: orderName,
          eventDate: eventDate,
          orderNotes: orderNotes,
          image: image,
          orderProductUuid: orderProductUuid,
          nonStatusOrderProducts: orderDetails?.nonStatusOrderProducts,
        });
      }}
    >
      {image ? (
        <Image
          style={styles.image}
          cachePolicy="memory"
          source={{ uri: image, cacheKey: imageUuid }}
          placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
          contentFit="cover"
          transition={100}
        />
      ) : (
        <View style={styles.image}>
          <MaterialCommunityIcons
            name="flower-tulip-outline"
            size={32}
            color={Colors.light.brown}
          />
        </View>
      )}
      <StatusPill status={orderStatus} />
      <Text style={styles.orderNumber}>{`#${orderNumber}`}</Text>
      <Text style={styles.name}>{orderName}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  body: {
    height: 280,
    width: "90%",
    marginHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: width / 2 - 30,
    height: width / 2 - 30,
    borderRadius: 18,
    marginBottom: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  name: {
    marginTop: -4,
    fontSize: 14,
    fontFamily: "spectral-600",
  },
  orderNumber: {
    marginTop: 2,
    fontSize: 14,
    fontFamily: "spectral-600",
  },
});

export default TileListItem;
