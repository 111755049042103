import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { ReactNode } from "react";
import Colors from "../../constants/Colors";

interface CircleButtonProps {
  icon: ReactNode;
  onPress: () => void;
}

const CircleButton: React.FC<CircleButtonProps> = ({ icon, onPress }) => {
  return (
    <TouchableOpacity
      hitSlop={{
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      }}
      style={styles.container}
      onPress={onPress}
    >
      {icon}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 28,
    width: 28,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.light.brown,
  },
});

export default CircleButton;
