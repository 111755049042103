import { StyleSheet, TouchableOpacity } from "react-native";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import TileListItem from "../../components/TileListItem";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import React, { useState } from "react";
import {
  SearchOrdersInput,
  useSearchOrders,
} from "../../hooks/orders/useSearchOrders";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import { formatDate } from "../../services/utils";
import { Feather } from "@expo/vector-icons";
import FilterPill from "../../components/FilterPill";



export default function MyFrames({ navigation }: RootTabScreenProps<"TabOne">) {
  const [searchOrdersInput, setSearchOrdersInput] = useState<SearchOrdersInput>(
    {
      pageNumber: 1,
      pageSize: 20,
      searchTerm: "",
      orderProductStatusFilter: null,
      orderByOrderDate: 0,
      assignedToMe: true,
      assignedToMyTeam: true,
      orderByEventDate: false,
    }
  );

  const [activeFilter, setActiveFilter] = useState(false);
  const [thisWeekFilter, setThisWeekFilter] = useState(false);

  const { data, isLoading, fetchNextPage } = useSearchOrders(searchOrdersInput);

  const flatData = () => {
    const flatArray: any = [];
    data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };


  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.pageContainer}>
        <View style={styles.header}>
          <TouchableOpacity
            onPress={() => navigation.navigate("FilterFramesScreen" as never)}
            hitSlop={styles.hit}
          >
            <Feather name="filter" size={24} color={Colors.light.brown} />
          </TouchableOpacity>
        </View>
        <Text style={styles.titlePage}>My Orders</Text>
        <View style={styles.filterPills}>
          <FilterPill
            label="Active"
            onPress={() => {
              setActiveFilter(!activeFilter);
            }}
            active={activeFilter}
          />
          <FilterPill
            label="This Week"
            onPress={() => {
              setThisWeekFilter(!thisWeekFilter);
            }}
            active={thisWeekFilter}
          />
        </View>
        <View style={styles.resultsContainer}>
          <Text style={styles.resultsText}>Preservation: </Text>
          <Text style={styles.resultsText}>Design: </Text>
          <Text style={styles.resultsText}>Fulfillment: </Text>
        </View> 
        <FlashList
          data={flatData()}
          renderItem={({ item, index }) => (
            <TileListItem
              orderNumber={item.shopifyOrderNumber}
              orderStatus={item.status}
              orderDate={formatDate(item.orderDate) || ""}
              orderName={`${item.clientFirstName} ${item.clientLastName}`}
              eventDate={formatDate(item.eventDate) || ""}
              orderNotes={index}
              orderProductUuid={item.uuid}
              image={item?.frameValetKey?.blobUri}
              imageUuid={item?.frameValetKey?.uuid}
            />
          )}
          numColumns={2}
          estimatedItemSize={60}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyIcon="package"
              emptyText="No Orders Found"
            />
          }
          contentContainerStyle={styles.flatlist}
          loadingComponent={<LoadingState />}
          isLoading={isLoading}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            fetchNextPage();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: "100%"
  },
  resultsContainer: {
    paddingLeft: 12,
  },
  resultsText: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
  },
  flatlist: {
    paddingBottom: 200,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  header: {
    paddingHorizontal: 20,
    marginTop: -36,
    backgroundColor: "transparent",
    alignItems: "flex-end",
  },
  hit: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20
  },
  filterPills: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 8,
    paddingHorizontal: 10,
    marginTop: 10,
  },
});
