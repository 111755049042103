import React, { useEffect } from "react";
import {
  StyleSheet,
  Dimensions,
  View,
  Alert,
  Text,
} from "react-native";
import Dropdown from "../../../components/Dropdown";
import { useForm } from "react-hook-form";
import { Modalize } from "react-native-modalize";
import Colors from "../../../constants/Colors";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ActionButton from "../../../components/ActionButton";
import { Feather } from "@expo/vector-icons";
import instanceAXIOS from "../../../services/api";
import Toast from "react-native-toast-message";
import ModalHeader from "../../../components/ModalHeader";
import Input from "../../../components/Input";
import ModalBody from "../../../components/ModalBody";
import { useQueryClient } from "react-query";
import { capitalizeFirstLetter } from "../../../services/utils";
import Button from "../../../components/Button";
const { height, width } = Dimensions.get("window");

interface ModalProps {
  modalRef: React.RefObject<Modalize>;
  users: any;
  userUuid: string;
  userName: string;
  userEmail: string;
  assignedToUserUuid: string;
  firstName: string;
  lastName: string;
  userLocation: string;
  showDropdown: boolean;
}

const Modal: React.FC<ModalProps> = ({
  modalRef,
  users,
  userUuid,
  assignedToUserUuid,
  userName,
  userEmail,
  firstName,
  lastName,
  userLocation,
  showDropdown = false,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm();
  const [managers, setManagers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);
  const [manager, setManager] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const insets = useSafeAreaInsets();
  const queryClient = useQueryClient();
  React.useEffect(() => {
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("email", userEmail);
  }, [firstName, lastName, userEmail, userLocation]);

  React.useEffect(() => {
    setManagers(users);
  }, [users]);

  React.useEffect(() => {
    setValue("assignedToUserUuid", manager);
  }, [manager]);

  const assignUser = async (data: { assignedToUserUuid: string | null }) => {
    if (showDropdown) {
      saveFirstName("", true);
    } else {
      instanceAXIOS
        .post(`/AssignedUser`, {
          userUuid: userUuid,
          assignedToUserUuid: data.assignedToUserUuid,
        })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ["assignedUsers"],
          });
          Toast.show({
            type: "success",
            props: {
              message: "User has been assigned",
            },
          });
          modalRef?.current?.close();
        });
    }
  };

  const unassignUser = () => {
    instanceAXIOS
      .delete(`/AssignedUser/${userUuid}/${assignedToUserUuid}`)
      .then(() => {
        queryClient.invalidateQueries("assignedUsers");
        Toast.show({
          type: "success",
          props: { message: "User has been unassigned" },
        });

        modalRef?.current?.close();
      });
  };

  const saveFirstName = (text: string, close = false) => {
    let firstName = getValues("firstName");
    let lastName = getValues("lastName");
    instanceAXIOS
      .post(`/User/UpdateUser`, {
        uuid: userUuid,
        firstName: firstName,
        lastName: lastName,
        location: location,
      })
      .then(() => {
           queryClient.invalidateQueries({
             queryKey: ["assignedUsers"],
           });
        if (close) {
          queryClient.invalidateQueries({
            queryKey: ["admin"],
            exact: true,
          });
          modalRef?.current?.close();
        } else {
          Toast.show({
            type: "success",
            props: {
              message: "User has been updated",
            },
          });
        }
      });
  };

  const updateLocation = (text: string, close = false) => {
    let firstName = getValues("firstName");
    let lastName = getValues("lastName");
    instanceAXIOS
      .post(`/User/UpdateUser`, {
        uuid: userUuid,
        firstName: firstName,
        lastName: lastName,
        location: text,
      })
      .then(() => {
                queryClient.invalidateQueries(
                  "assignedUsers",
                  assignedToUserUuid
                );

        if (close) {
          queryClient.invalidateQueries({
            queryKey: ["admin"],
            exact: true,
          });
          modalRef?.current?.close();
        } else {
          Toast.show({
            type: "success",
            props: {
              message: "User has been updated",
            },
          });
        }
      });
  };

  const saveEmail = () => {
    let email = getValues("email");
    instanceAXIOS
      .post(`/User/UpdateEmail`, {
        uuid: userUuid,
        email: email,
      })
      .then(() => {
        Toast.show({
          type: "success",
          props: {
            message: "User email has been updated",
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 400) {
          Toast.show({
            type: "error",
            props: {
              message: "User has already registered with this email",
            },
          });
        }
      });
  };

  const deleteUser = () => {
    instanceAXIOS
      .delete(`/User/${userUuid}`)
      .then(() => {
        modalRef?.current?.close();
        Toast.show({
          type: "success",
          props: {
            message: "User has been deleted",
          },
        });
        queryClient.invalidateQueries("assignedUsers", assignedToUserUuid);
      })
      .catch((e) => {
        Toast.show({
          type: "error",
          props: {
            message: "Cannot delete user",
          },
        });
      });
  };

  return (
    <Modalize
      ref={modalRef}
      disableScrollIfPossible
      modalHeight={height - (insets.top + 20)}
      modalStyle={styles.modal}
      withHandle={false}
      withReactModal
      FloatingComponent={
        <View style={styles.buttonContainer}>
          <ActionButton
            icon={<Feather name="save" size={20} color="#fff" />}
            onPress={handleSubmit(assignUser)}
            label={"Save"}
          />
          <ActionButton
            icon={<Feather name="trash-2" size={20} color="#fff" />}
            onPress={() =>
              Alert.alert("Unassign", "Are you sure?", [
                {
                  text: "Cancel",
                  style: "cancel",
                },
                { text: "OK", onPress: () => unassignUser() },
              ])
            }
            label={"Unassign"}
          />
          <ActionButton
            icon={<Feather name="trash-2" size={20} color="#fff" />}
            onPress={() =>
              Alert.alert("Delete User", "Are you sure?", [
                {
                  text: "Cancel",
                  style: "cancel",
                },
                { text: "OK", onPress: () => deleteUser() },
              ])
            }
            label={"Delete"}
          />
        </View>
      }
    >
      <ModalHeader
        headerText={userName}
        goBack
        goBackCustom={() => {
          modalRef?.current?.close();
        }}
      />

      <ModalBody
        body={
          <View style={{ paddingHorizontal: 30 }}>
            <Text style={styles.email}>{userEmail}</Text>
            {userLocation && (
              <Text style={styles.email}>{`Location: ${capitalizeFirstLetter(
                userLocation
              )}`}</Text>
            )}
            <View
              style={[
                styles.dropdownContainer,
                { height: height - (insets.top + 120) },
              ]}
            >
              <View>
                <Input
                  name={"firstName"}
                  placeholder="First Name"
                  control={control}
                  errors={errors}
                  onBlur={saveFirstName}
                />
                <Input
                  name={"lastName"}
                  placeholder="Last Name"
                  control={control}
                  errors={errors}
                  onBlur={saveFirstName}
                />
                <Input
                  name={"email"}
                  placeholder="Email"
                  control={control}
                  errors={errors}
                  onBlur={saveEmail}
                />
                {userLocation && (
                <View
                  style={{ justifyContent: "center", flexDirection: "row" }}
                >
                  <Button
                    label={`Change location to: ${
                      userLocation === "utah" ? "Georgia" : "Utah"
                    }`}
                    onPress={() =>
                      updateLocation(
                        userLocation === "utah" ? "georgia" : "utah",
                        true
                      )
                    }
                  />
                </View>
              )}
                <View style={{ height: 10 }} />
                {managers && !showDropdown && !openLocation && (
                  <Dropdown
                    name={"assignedToUserUuid"}
                    required={!showDropdown}
                    label={"New Manager"}
                    control={control}
                    errors={errors}
                    open={open}
                    setOpen={setOpen}
                    value={manager}
                    items={managers.filter(
                      (x) =>
                        x.value !== "Admin" &&
                        x.value !== "00000000-0000-0000-0000-000000000000"
                    )}
                    setValue={setManager}
                    setItems={setManagers}
                    width={width - 40}
                  />
                )}
              </View>
            </View>
          </View>
        }
      ></ModalBody>
    </Modalize>
  );
};

const styles = StyleSheet.create({
  modal: {
    backgroundColor: Colors.light.background,
  },
  dropdownContainer: {
    padding: 20,
    paddingBottom: 50,
    alignContent: "center",
    justifyContent: "space-between",
    width: width,
  },
  buttonContainer: {
    flexDirection: "row",
    position: "absolute",
    paddingHorizontal: 20,
    bottom: 30,
    left: 0,
    right: 0,
  },
  email: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    textAlign: "center",
    marginBottom: 20,
  },
});

export default Modal;
