import axios from "axios";
import { getLocalData } from "./storage";
import * as RootNavigation from "../navigation/RootNavigation";
import { API_URL } from "@env";
import { STORAGE_KEYS } from "./storage";
import Toast from "react-native-toast-message";

export const instanceAXIOS = axios.create({
  baseURL: "https://pressedfloralapi.azurewebsites.net/",
  //DEV
  // baseURL: "https://dev-pressedfloral-api.behavioristapp.com/",
  headers: { "Content-Type": "application/json;" },
});

instanceAXIOS.interceptors.request.use(
  async (config) => {
    if (
      !config.url?.includes("Authentication") ||
      config.url?.includes("Authentication/SendNewTwoFactorRegistrationCode")
    ) {
      let jwt = null;
      await getLocalData(STORAGE_KEYS.KEY_TOKEN).then((token) => {
        jwt = token;
      });
      if (jwt !== null && jwt !== undefined) {
        config.headers["Authorization"] = `Bearer ${jwt}`;
      } else {
        throw new axios.Cancel("No JWT Token");
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instanceAXIOS.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      Toast.show({
        type: "error",
        props: {
          message: "Session Expired, Please Login Again",
        },
      });
      RootNavigation.navigate("Logout");
    }
    if (error.message.includes("Network Error")) {
      Toast.show({
        type: "error",
        props: {
          message: "Network Error",
        },
      });
    }
    return Promise.reject(error);
  }
);

export default instanceAXIOS;
