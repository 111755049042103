import { ActivityIndicator, StyleSheet, View } from "react-native";
import React from "react";
import Colors from "../../constants/Colors";

interface SplashscreenProps {}

const Splashscreen: React.FC<SplashscreenProps> = ({}) => {
  return (
    <View style={styles.body}>
      <ActivityIndicator size="large" color={Colors.light.navy} />
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Splashscreen;
