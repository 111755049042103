import { StyleSheet, TouchableOpacity } from "react-native";
import React from "react";
import { Text, View } from "../Themed";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";

interface SortToggleProps {
  label: string;
  up: boolean;
  onPress: () => void;
}

const SortToggle: React.FC<SortToggleProps> = ({ label, onPress, up }) => {
  return (
    <View style={{ marginLeft: 14 }}>
      <Text style={styles.label}>{label}</Text>
      <TouchableOpacity
        style={styles.container}
        onPress={() => {
          onPress();
        }}
      >
        <Feather
          name={up ? "arrow-up" : "arrow-down"}
          size={18}
          color={Colors.light.brown}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderWidth: 1.5,
    borderColor: Colors.light.brown,
    height: 42,
    borderRadius: 3,
    width: 52,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    color: Colors.light.brown,
    marginBottom: 2,
    fontSize: 16,
    fontFamily: "spectral-600",
  },
  text: {
    color: Colors.light.brown,
    fontSize: 14,
  },
});

export default SortToggle;
