import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,

          "space-mono": require("../assets/fonts/SpaceMono-Regular.ttf"),
          "spectral-100": require("../assets/fonts/Spectral-ExtraLight.ttf"),
          "spectral-200": require("../assets/fonts/Spectral-Light.ttf"),
          "spectral-300": require("../assets/fonts/Spectral-Regular.ttf"),
          "spectral-400": require("../assets/fonts/Spectral-Medium.ttf"),
          "spectral-500": require("../assets/fonts/Spectral-Regular.ttf"),
          "spectral-600": require("../assets/fonts/Spectral-SemiBold.ttf"),
          "spectral-700": require("../assets/fonts/Spectral-Bold.ttf"),
          "spectral-800": require("../assets/fonts/Spectral-ExtraBold.ttf"),
          "spectral-500italic": require("../assets/fonts/Spectral-Italic.ttf"),
          "spectral-600italic": require("../assets/fonts/Spectral-SemiBoldItalic.ttf"),
          "spectral-700italic": require("../assets/fonts/Spectral-BoldItalic.ttf"),
          "spectral-800italic": require("../assets/fonts/Spectral-ExtraBoldItalic.ttf"),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
