import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrderSummary = () => {
  return useQuery(["orderSummary"], () => getOrderSummary(), {
    cacheTime: 240000,
  });
};

const getOrderSummary = async () => {
  const { data } = await instanceAXIOS.get(`/OrderProducts/Summary`);
  return data;
};
