import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetUserTasks = (getTasksInput: GetTasksInput) => {
  const getTasksByUser = async (pageParam: { pageParam: number }) => {
    try {
      const res = await instanceAXIOS.post(`/GetUserTasks/`, {
        pageNumber: parseInt(pageParam?.pageParam) || 1,
        pageSize: getTasksInput.pageSize,
        completed: getTasksInput.completed,
      });
      return res.data;
    } catch (err) {
      Toast.show({
        type: "error",
        props: {
          message: "There was an error fetching tasks",
        },
      });
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(["userTasks", getTasksInput], getTasksByUser, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || lastPage.length < getTasksInput.pageSize) {
        return undefined;
      }
      return pages.length + 1;
    },
  });

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};

export interface GetTasksInput {
  pageNumber: number;
  pageSize: number;
  completed: boolean;
}
