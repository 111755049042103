import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetConversation = (getConversationInput: GetConversationInput) => {
  const getConversation = async (pageParam: { pageParam: number }) => {
    try {
      const res = await instanceAXIOS.post(
        `/OrderConversation/Messages/`,
        {
            pageNumber: pageParam.pageParam || 1,
            pageSize: 20,
            orderUuid: getConversationInput.orderUuid,
            offset: getConversationInput.offset,
        }
      );
      return res.data;
    } catch (err) {
      Toast.show({
        type: "error",
        props: {
          message: "There was an error fetching messages",
        },
      });
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(["conversation", getConversationInput?.orderUuid], getConversation, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || lastPage.length < 20) {
        return undefined;
      }
      return pages.length + 1;
    },
  });

  return {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};

export interface GetConversationInput {
  orderUuid: string;
  offset: number; //uers time zone offset
}
