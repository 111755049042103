import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";
import queryClient from "../../services/reactQuery";

const updateShippingAddress = async (_data: any) => {
  const res = await instanceAXIOS.patch(`/Orders/Address`, _data);
  return res.data;
};

export const useUpdateShippingAddress = () => {
  return useMutation((_data: any) => updateShippingAddress(_data), {
    onSuccess: (data: any) => {
      Toast.show({
        type: "success",
        props: {
          message: "Shipping address updated successfully",
        },
      });
      queryClient.invalidateQueries("orderDetails");
      queryClient.invalidateQueries("orderSummary");
      return data;
    },
    onError: (error) => {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to update shipping address",
        },
      });
    },
  });
};
