import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import Colors from "../../../constants/Colors";

interface ConversationBreadCrumbsProps {
  name: string;
  orderNubmer: string;
  onPress: () => void;
}

const ConversationBreadCrumbs: React.FC<ConversationBreadCrumbsProps> = ({
  name,
  orderNumber,
  onPress,
}) => {
  const _goToOrderProduct = () => {
    onPress();
  };

  return (
    <View style={styles.body}>
      <TouchableOpacity
        hitSlop={styles.hit}
        onPress={() => _goToOrderProduct()}
      >
        <Text style={styles.text}>{`Customer: ${name}`}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        hitSlop={styles.hit}
        onPress={() => _goToOrderProduct()}
      >
        <Text style={styles.text}>{`Order: #${orderNumber}`}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    backgroundColor: Colors.light.background,
    paddingVertical: 10,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 20,
    zIndex: 1000,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.05,
    shadowRadius: 6,

  },
  text: {
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    fontSize: 14,
  },
  hit: {
    top: 20,
    left: 20,
    bottom: 20,
    right: 20,
  },
});

export default ConversationBreadCrumbs;
