import { StyleSheet, Text } from "react-native";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import React from "react";
import Button from "../../components/Button";
import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import instanceAXIOS from "../../services/api";
import reactQuery from "../../services/reactQuery";
import Toast from "react-native-toast-message";

export default function TaskDetails({ route }: RootTabScreenProps<"TabOne">) {
  const { goBack } = useNavigation();
  const {
    task,
    assignedDate,
    dueDate,
    complete,
    completedOn,
    completedByName,
    createdByName,
    id,
  } = route.params;

  const formatDateTime = (date: string) => {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    const minuteStr = minute < 10 ? `0${minute}` : minute;
    return `${month}/${day}/${year} ${hour12}:${minuteStr} ${ampm}`;
  };

  const markComplete = async () => {
    await instanceAXIOS
      .post(`/UserTasks/Complete/`, {
        uuid: id,
      })
      .then((res) => {
        reactQuery.invalidateQueries("userTasks");
        Toast.show({
          type: "success",
          props: {
            message: "Task completed",
          },
        });
        goBack();
      });
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <ScrollView>
        <Text style={styles.titlePage}>Task Details</Text>
        <Text style={styles.label}>{`Task: ${
          complete ? "completed" : ""
        } `}</Text>
        <Text style={styles.dueText}>Due Date: {formatDateTime(dueDate)}</Text>
        <View style={styles.taskContainer}>
          <Text style={styles.taskText}>{task}</Text>
        </View>
        <Text style={styles.noteText}>
          Assigned Date: {formatDateTime(assignedDate)}
        </Text>
        <Text style={styles.noteText}>
          Assigned By: {createdByName ? createdByName : "N/A"}
        </Text>
        {complete ? (
          <>
            <View style={styles.space} />
            <Text style={styles.noteText}>
              Completed Date: {formatDateTime(completedOn)}
            </Text>
            <Text style={styles.noteText}>
              Completed By: {completedByName ? completedByName : "N/A"}
            </Text>
          </>
        ) : null}

        <View style={styles.buttonContainer}>
          {!complete ? (
            <Button
              backgroundColor={Colors.light.gold}
              label={"Mark Complete"}
              onPress={() => {
                markComplete();
              }}
            />
          ) : (
            <Button
              isDisabled
              backgroundColor={Colors.light.green}
              label={"Completed"}
              onPress={() => {
                goBack();
              }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: -48,
  },
  space: {
    height: 20,
  },
  buttonContainer: {
    marginTop: 40,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
    marginBottom: 20,
  },
  label: {
    fontSize: 18,
    marginLeft: "5%",
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  taskContainer: {
    backgroundColor: "white",
    width: "90%",
    alignSelf: "center",
    borderRadius: 10,
    marginBottom: 10,
  },
  taskText: {
    fontSize: 18,
    fontFamily: "spectral-400",
    color: Colors.light.brown,
    alignSelf: "center",
    padding: 10,
  },
  noteText: {
    marginLeft: "5%",
    fontSize: 16,
    fontFamily: "spectral-400",
    color: Colors.light.brown,
  },
  dueText: {
    fontSize: 16,
    fontFamily: "spectral-400",
    color: Colors.light.brown,
    marginLeft: "5%",
    marginBottom: 10,
  },
});
