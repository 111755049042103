import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";
import queryClient from "../../services/reactQuery";

const updateOrderStatus = async (_data: {
  orderProductUuid: string;
  status: string;
}) => {
  const res = await instanceAXIOS.post(`/OrderProducts/Status`, {
    orderProductUuid: _data.orderProductUuid,
    status: _data.status,
  });
  return res.data;
};

export const useUpdateOrderStatus = () => {
  return useMutation(
    (_data: { orderProductUuid: string; status: string }) =>
      updateOrderStatus(_data),
    {
      onSuccess: (data: any) => {
        Toast.show({
          type: "success",
          props: {
            message: "Order status updated successfully",
          },
        });
        queryClient.invalidateQueries("orderDetails", {
          refetchInactive: true,
        });
        queryClient.invalidateQueries("countsForUser");
        queryClient.invalidateQueries("orders");
        queryClient.invalidateQueries("orderCountsByStatus");
        queryClient.invalidateQueries("orderStatusCounts");
        return data;
      },
      onError: (error) => {
        Toast.show({
          type: "error",
          props: {
            message: "Unable to update order status",
          },
        });
      },
    }
  );
};
