import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Dimensions, RefreshControl, StyleSheet } from "react-native";
import Dropdown from "../../components/Dropdown";
import FlashList from "../../components/FlashList";
import Header from "../../components/Header";
import Input from "../../components/Input";
import ListItem from "../../components/ListItem/Index";
import LoadingState from "../../components/LoadingState";
import SortToggle from "../../components/SortToggle";
import { Text, View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import {
  SearchOrdersInput,
  useSearchOrders,
} from "../../hooks/orders/useSearchOrders";
import { RootTabScreenProps } from "../../types";
import Controls from "./Controls";
import { formatDate, formatEventDate } from "../../services/utils";

const statusData = [
  { label: "All", value: null },
  { label: "Order Received", value: "OrderReceived" },
  { label: "Bouquet Received", value: "BouquetReceived" },
  { label: "Checked On", value: "CheckedOn" },
  { label: "Progress", value: "Progress" },
  { label: "Almost Ready to Frame", value: "AlmostReadyToFrame" },
  { label: "Ready to Frame", value: "ReadyToFrame" },
  { label: "Frame Completed", value: "FrameCompleted" },
  { label: "Preparing to be Shipped", value: "PreparingToBeShipped" },
  { label: "Shipped", value: "Shipped" },
  { label: "No Response", value: "NoResponse" },
  { label: "Approved", value: "Approved" },
  { label: "Disapproved", value: "Disapproved" },
  { label: "Ready To Seal", value: "ReadyToSeal" },
];

export default function Orders({ navigation }: RootTabScreenProps<"TabOne">) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [statusDropDownIsOpen, setStatusDropDownIsOpen] = useState(false);
  const [orderByDropdownIsOpen, setOrderByDropdownIsOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [sort, setSort] = useState("desc");
  const [statuses, setStatuses] = useState(statusData);
  const [orderBy, setOrderBy] = useState(false);
  const [orderByOptions, setOrderByOptions] = useState([
    {
      label: "Order Number",
      value: false,
    },
    {
      label: "Event Date",
      value: true,
    },
  ]);
  const [searchOrdersInput, setSearchOrdersInput] = useState<SearchOrdersInput>(
    {
      pageNumber: 1,
      pageSize: 20,
      searchTerm: "",
      orderProductStatusFilter: null,
      orderByOrderDate: 1,
      assignedToMe: false,
      assignedToMyTeam: false,
      orderByEventDate: false,
    }
  );

  const {
    data,
    isLoading,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useSearchOrders(searchOrdersInput);

  const flatData = () => {
    const flatArray: any = [];
    data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  const search = watch("Search");
  const statusFilter = watch("Status");

  useEffect(() => {
    let _filter = null;
    if (statusFilter) {
      _filter = [statusFilter];
    }
    setSearchOrdersInput({
      ...searchOrdersInput,
      searchTerm: search,
      orderProductStatusFilter: _filter,
      orderByEventDate: orderBy,
    });
  }, [search, statusFilter, orderBy]);

  useEffect(() => {
    setValue("Status", status);
  }, [status]);

  useEffect(() => {
    setValue("orderByEventDate", orderBy);
  }, [orderBy]);

  return (
    <View style={styles.container}>
      <Header profile />
      <Controls
        control={control}
        errors={errors}
        sort={sort}
        setSort={(_stort) => {
          setSort(_stort);
          setSearchOrdersInput({
            ...searchOrdersInput,
            orderByOrderDate: sort === "asc" ? 1 : 0,
          });
        }}
        statusDropDownIsOpen={statusDropDownIsOpen}
        setStatusDropDownIsOpen={setStatusDropDownIsOpen}
        orderByDropdownIsOpen={orderByDropdownIsOpen}
        setOrderByDropdownIsOpen={setOrderByDropdownIsOpen}
        statuses={statuses}
        status={status}
        setStatus={setStatus}
        setStatuses={setStatuses}
        totalItems={data?.pages[0]?.totalItems}
        orderBy={orderBy}
        orderByOptions={orderByOptions}
        setOrderByOptions={setOrderByOptions}
        setOrderBy={setOrderBy}
      />
      <View style={styles.pageContainer}>
        <FlashList
          data={flatData()}
          refreshControl={
            <RefreshControl
              refreshing={isFetchingNextPage ? false : isLoading}
              onRefresh={() => {
                refetch();
              }}
            />
          }
          renderItem={({ item, index }) => (
            <ListItem
              index={index}
              orderNumber={item.shopifyOrderNumber}
              orderStatus={item.status}
              orderDate={formatDate(item.orderDate)}
              orderName={`${item.clientFirstName} ${item.clientLastName}`}
              orderUuid={item?.orderUuid}
              eventDate={formatEventDate(item?.eventDate)}
              orderNotes={index}
              orderProductUuid={item.uuid}
              image={item?.frameValetKey?.blobUri}
              imageUuid={item?.frameValetKey?.orderProductUploadUuid}
            />
          )}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            fetchNextPage();
          }}
          contentContainerStyle={styles.flatlist}
          estimatedItemSize={60}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyIcon="package"
              emptyText="No Orders Found"
            />
          }
          loadingComponent={<LoadingState />}
          isLoading={isLoading}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  controlsContainer: { paddingHorizontal: 16, marginBottom: 12, zIndex: 1000 },
  pageContainer: { flex: 1, width: "100%" },
  dropDownContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  resultsContainer: {
    width: 80,
  },
  flatlist: {
    width: "100%",
    paddingBottom: 100,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "spectral-600",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  resultsText: {
    marginTop: 4,
    fontSize: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  label: {
    color: Colors.light.brown,
    marginBottom: 4,
    fontSize: 16,
    fontFamily: "spectral-600",
  },
  item: {
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 10,
    minHeight: 50,
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: Colors.light.background,
    borderWidth: 2,
    borderColor: Colors.light.brown,
  },
  itemName: {
    color: Colors.light.brown,
    fontSize: 18,
    fontFamily: "spectral-600",
  },
});
