import React, { FC } from "react";
import { Text, View, StyleSheet } from "react-native";
import Colors from "../../constants/Colors";
import { getInitials } from "../../services/utils";
import { Image } from "expo-image";

interface AvatarProps {
  image?: string;
  name?: string;
  large?: boolean;
  shadow?: boolean;
}

const Avatar: FC<AvatarProps> = ({
  image,
  name,
  large = false,
  shadow = false,
}) => {
  return (
    <View
      style={[
        large ? styles.bodyLarge : styles.body,
        shadow ? styles.shadow : null,
      ]}
    >
      {image ? (
        <Image
          source={{ uri: image, cacheKey: image }}
          style={large ? styles.imageLarge : styles.image}
        />
      ) : (
        <View
          style={[
            (large ? styles.bodyLarge : styles.body,
            shadow ? styles.shadow : null),
          ]}
        >
          <View style={large ? styles.imageLarge : styles.image}>
            <Text style={large ? styles.initalsLarge : styles.initals}>
              {getInitials(name || "")}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    width: 34,
    height: 34,
    borderRadius: 20,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  bodyLarge: {
    width: 160,
    height: 160,
    borderRadius: 80,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  shadow: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  image: {
    width: 30,
    height: 30,
    borderRadius: 20,
    backgroundColor: Colors.light.brown,
    justifyContent: "center",
    alignItems: "center",
  },
  imageLarge: {
    width: 150,
    height: 150,
    borderRadius: 80,
    backgroundColor: Colors.light.brown,
    justifyContent: "center",
    alignItems: "center",
  },
  initals: {
    color: "white",
    fontSize: 14,
    fontWeight: "600",
  },
  initalsLarge: {
    color: "white",
    fontSize: 60,
    letterSpacing: 2,
    fontWeight: "500",
  },
});

export default Avatar;
