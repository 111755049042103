import React, { useContext, useEffect } from "react";
import { StyleSheet, View, Text, Alert, Platform } from "react-native";
import { RefreshControl } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Button from "../../components/Button";
import FlashList from "../../components/FlashList";
import Header from "../../components/Header";
import LoadingState from "../../components/LoadingState";
import Colors from "../../constants/Colors";
import Context from "../../context";
import { useGetOrderSummary } from "../../hooks/orders/useGetOrderSummary";
import ProductItem from "../ClientOrderProducts/ProductItem";
import window from "../../constants/Layout";
import { useDeleteClient } from "../../hooks/users/useDeleteClient";
import { useFocusEffect } from "@react-navigation/native";

export default function Client() {
  const insets = useSafeAreaInsets();
  const { user, logout, setTabVisible } = useContext(Context);

  const { data, isLoading, isFetched, refetch } = useGetOrderSummary();

  const customProducts = () => {
    const frameCompleted = data?.filter(
      (order) => order.status === "frameCompleted"
    );
    const otherOrders = data?.filter(
      (order) => order.status !== "frameCompleted"
    );
    return frameCompleted?.concat(otherOrders);
  };

  useFocusEffect(
    React.useCallback(() => {
      setTabVisible(true);
    }, [])
  );


  const deleteUser = useDeleteClient();

  const _delete = () => {
    Alert.alert(
      "Delete Account",
      "Are you sure you want to delete your account? This action cannot be undone.",
      [
        {
          text: "Cancel",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "Delete",
          onPress: () => {
            _deleteAccount();
          },
          style: "destructive", // Custom style to make the text red
        },
      ]
    );
  };

  const _deleteAccount = async () => {
    await deleteUser.mutate({
      clientUuid: user!?.uuid,
    });
    Alert.alert("Account Deleted", "Your account has been deleted", [
      {
        text: "Ok",
        onPress: () => {
          logout();
        },
        style: "cancel",
      },
    ]);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Header />
        <Text style={styles.hello}>{`Hello, ${user?.firstName}`}</Text>
        <Text style={styles.title}>{`Your Custom Products`}</Text>
        <View style={styles.flex}>
          <FlashList
            data={customProducts()}
            estimatedItemSize={100}
            refreshControl={
              <RefreshControl
                refreshing={isFetched ? isLoading : false}
                onRefresh={() => {
                  refetch();
                }}
              />
            }
            isLoading={isLoading}
            ListEmptyComponent={
              <LoadingState
                empty
                emptyIcon="package"
                emptyText="No products found"
              />
            }
            loadingComponent={<LoadingState />}
            renderItem={({ item }) => (
              <View
                style={{
                  height: item?.status === "frameCompleted" ? 180 : 120,
                  flexDirection: "row",
                }}
              >
                <ProductItem
                  order={item}
                  name={item.shopifyOrderName}
                  status={item.status}
                />
              </View>
            )}
          />
        </View>
      </View>
      <View
        style={[styles.buttonContainer, { marginBottom: insets.bottom + 60 }]}
      >
        <Button onPress={logout} label={"Logout"} />
        {Platform.OS !== "web" ? (
          <Button
            onPress={_delete}
            label={"Delete Account"}
            backgroundColor={Colors.light.background}
            textColor={Colors.light.lightBrown}
          />
        ) : (
          <View style={{ height: 50 }} />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  hello: {
    fontSize: 30,
    textAlign: "center",
    fontFamily: "spectral-600",
    margin: 10,
    color: "#999887",
  },
  title: {
    marginTop: 40,
    marginBottom: 10,
    fontSize: 18,
    marginLeft: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  flex: { flex: 1 },
  container: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  subContainer: {
    flex: 1,
    width: "100%",
    maxWidth: Platform.OS !== "web" ? window.window.width : 800,
  },
  listTitle: {
    fontSize: 18,
    marginLeft: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: Platform.OS !== "web" ? window.window.width : "100%",
    alignSelf: "center",
    maxWidth: Platform.OS !== "web" ? window.window.width : 600,
  },
});
