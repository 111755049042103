import React, { FC } from "react";
import { StyleSheet, View, Text } from "react-native";
import Colors from "../../../constants/Colors";
import StatusPill from "../../../components/StatusPill";
import { TouchableOpacity } from "react-native-gesture-handler";

interface OrderProductQuickDetailsProps {
  product: string;
  varient: string;
  varient2: string;
  varient3?: string;
  quantity: number;
  status: string;
  onPress?: () => void;
  short?: boolean;
}

const OrderProductQuickDetails: FC<OrderProductQuickDetailsProps> = ({
  product,
  varient,
  varient2,
  quantity,
  varient3,
  status,
  onPress,
  short = false,
}) => {
  return short ? (
    <TouchableOpacity
      disabled={!onPress}
      activeOpacity={0.6}
      style={styles.shortBody}
    >
      <View style={styles.container}>
        <Text style={styles.productText}>{`Product: ${product}`}</Text>
        <Text style={styles.quantityText}>{`Quantity: ${quantity}`}</Text>
      </View>
    </TouchableOpacity>
  ) : (
    <TouchableOpacity
      disabled={!onPress}
      activeOpacity={0.6}
      style={[short ? styles.shortBody : styles.body]}
      onPress={onPress ? onPress : null}
    >
      <View style={styles.row}>
        <View style={styles.container}>
          <View>
            <Text style={styles.productText}>{`Product: ${product}`}</Text>
            <Text style={styles.text}>{varient}</Text>
            <Text style={styles.text}>{varient2}</Text>
            <Text style={styles.text}>{varient3}</Text>
            <Text style={styles.quantityText}>{`Quantity: ${quantity}`}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};
export default OrderProductQuickDetails;

const styles = StyleSheet.create({
  shortBody: {
    marginVertical: 14,
    padding: 8,
    marginHorizontal: 20,
    // height: 60,
    borderRadius: 12,
    backgroundColor: Colors.light.lightGray,
    borderWidth: 1,
    borderColor: Colors.light.darkGray,
  },
  body: {
    marginVertical: 4,
    paddingHorizontal: 18,
    padding: 14,
    marginHorizontal: 20,
    height: 140,
    borderRadius: 12,
    backgroundColor: Colors.light.lightGray,
    borderWidth: 1,
    borderColor: Colors.light.darkGray,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  container: {
    justifyContent: "space-between",
  },
  contentContainer: {
    backgroundColor: "white",
    padding: 4,
    paddingHorizontal: 12,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
  },
  quantityText: {
    fontSize: 16,
    fontFamily: "spectral-600",
  },
  productText: {
    fontSize: 16,
    fontFamily: "spectral-600",
  },
  text: {
    fontSize: 14,
    fontFamily: "spectral-500",
  },
});
