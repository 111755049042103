import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import React from "react";
import Colors from "../../../constants/Colors";
import { useNavigation } from "@react-navigation/native";
import AvatarBubble from "./AvatarBubble";

const { width } = Dimensions.get("window");

interface ConversationListItemProps {
  name: string;
  orderNumber: string;
  updated: string;
  unread: boolean;
  orderUuid: string;
  lastMessage: string;
  isCustomer?: boolean;
  users?: any;
  index?: number;
  isSearch?: boolean;
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({
  name,
  orderNumber,
  updated,
  unread,
  orderUuid,
  lastMessage,
  isCustomer = false,
  users,
  index,
  isSearch = false,
}) => {
  const { navigate } = useNavigation();

  const _GoToConversations = () => {
    navigate("Conversation", {
      orderNumber: orderNumber,
      name: name,
      orderUuid: orderUuid,
      unread: unread,
    });
  };

  return (
    <View style={styles.bodyContainer}>
      {index === 0 ? <View style={styles.divider} /> : null}

      <TouchableOpacity
        style={styles.body}
        onPress={() => {
          _GoToConversations();
        }}
      >
        <View style={styles.row4}>
          {unread ? (
            <View
              style={[
                styles.dot,
                {
                  backgroundColor: unread
                    ? Colors.light.messageBlue
                    : "transparent",
                },
              ]}
            />
          ) : null}
          <AvatarBubble
            isCustomer={isCustomer}
            users={
              isSearch
                ? [
                    {
                      firstName: name,
                      lastName: "",
                    },
                  ]
                : users
            }
          />
          <View style={styles.flex}>
            <View style={styles.row}>
              <View style={styles.innerRow}>
                <Text style={styles.textName}>{`${name}`}</Text>
              </View>
              <Text style={styles.text}>{`Order: #${orderNumber}`}</Text>
            </View>
            <View style={styles.row2}>
              <Text numberOfLines={1} style={styles.lastMessageText}>
                {lastMessage === "null" || lastMessage.length === 0
                  ? isSearch
                    ? ""
                    : "New Image Received"
                  : lastMessage}
              </Text>
              <Text style={styles.text2}>{updated}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <View style={styles.divider} />
    </View>
  );
};

const styles = StyleSheet.create({
  bodyContainer: {
    flex: 1,
    backgroundColor: "white",
  },
  flex: {
    flex: 1,
  },
  body: {
    height: 72,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  divider: {
    height: 1,
    backgroundColor: Colors.light.lightBrown,
    opacity: 0.2,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
  },
  innerRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dot: {
    height: 12,
    width: 12,
    borderRadius: 9,
    marginRight: 6,
  },
  row2: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 10,
  },
  row4: {
    flexDirection: "row",
  },
  text: {
    fontFamily: "spectral-600",
  },
  textName: {
    marginTop: -12,
    fontFamily: "spectral-600",
  },
  text2: {
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    fontSize: 12,
  },
  lastMessageText: {
    fontFamily: "spectral-400",
    color: Colors.light.brown,
    maxWidth: width - 210,
  },
});

export default ConversationListItem;
