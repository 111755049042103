import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";
import queryClient from "../../services/reactQuery";

const assignUser = async (_data: {
  orderPrductUuid: string;
  userUuid: string;
}) => {
  const res = await instanceAXIOS.patch(
    `/OrderProducts/${_data?.orderPrductUuid}/${_data.userUuid}`,
    {}
  );
  return res.data;
};

export const useAssignOrder = () => {
  return useMutation(
    (_data: { orderPrductUuid: string; userUuid: string }) => assignUser(_data),
    {
      onSuccess: (data: any) => {
        Toast.show({
          type: "success",
          props: {
            message: "Order assigned successfully",
          },
        });
        queryClient.invalidateQueries("orderDetails");
        queryClient.invalidateQueries("orderSummary");
        return data;
      },
      onError: (error) => {
        Toast.show({
          type: "error",
          props: {
            message: "Unable assign order",
          },
        });
      },
    }
  );
};
