import React, { useState } from "react";
import { Dimensions, ScrollView, StyleSheet } from "react-native";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
const { height } = Dimensions.get("window");

export default function Email({ navigation }: RootTabScreenProps<"TabOne">) {
  const [isManager, setIsManager] = useState(false);

  return (
    <View style={styles.container}>
      <Header profile />

      <ScrollView
        style={styles.subContainer}
        contentContainerStyle={styles.containerStyle}
      >
        <View style={styles.buttonContainer}>
          <Text style={styles.titlePage}>Emails</Text>
          <Button
            label={`Search`}
            onPress={() => {
              navigation.navigate("ReadEmail", {
                task: "Search",
                color: Colors.light.navy,
                title: "Search",
                email: [
                  "designers@pressedfloral.com",
                  "florist@pressedfloral.com",
                  "shipping@pressedfloral.com",
                  "info@pressedfloral.com",
                ],
              });
            }}
          />
          <Button
            label={`Designers`}
            onPress={() => {
              navigation.navigate("ReadEmail", {
                task: "Design",
                color: Colors.light.navy,
                title: "Design",
                email: ["designers@pressedfloral.com"],
              });
            }}
          />
          <Button
            label={`Florist`}
            onPress={() => {
              navigation.navigate("ReadEmail", {
                task: "Florist",
                color: Colors.light.navy,
                title: "Florist",
                email: ["florist@pressedfloral.com"],
              });
            }}
          />
          <Button
            label={"Fulfillment"}
            onPress={() => {
              navigation.navigate("ReadEmail", {
                task: "Shipping",
                color: Colors.light.navy,
                title: "Shipping",
                email: ["shipping@pressedfloral.com"],
              });
            }}
          />
          <Button
            label={"Info"}
            onPress={() => {
              navigation.navigate("ReadEmail", {
                task: "Info",
                color: Colors.light.navy,
                title: "Info",
                email: ["info@pressedfloral.com"],
              });
            }}
          />
          <Button
            backgroundColor={Colors.light.navy}
            label={`Bulk Email`}
            onPress={() => {
              navigation.navigate("BulkEmail");
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
  },
  containerStyle: { justifyContent: "flex-end", flex: 1, paddingBottom: 100 },
  subContainer: { flex: 1, width: "100%" },
  buttonContainer: {
    marginBottom: 60,
    alignItems: "center",
    height: height * 0.8 - 140,
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
