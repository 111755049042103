import {
  StyleSheet,
  Dimensions,
  Alert,
  TouchableOpacity,
  Text,
  View,
  ActivityIndicator,
  Platform,
  Image as WebImage,
  PermissionsAndroid,
} from "react-native";

import React, { useEffect, useState } from "react";
import Colors from "../../constants/Colors";
import CircleButton from "../CircleButton";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";
import ViewNotes from "../../screens/OrderDetails/ViewNotes";
import { useDeleteUpload } from "../../hooks/uploads/useDeleteUpload";
import reactQuery from "../../services/reactQuery";
import { formatDateTime } from "../../services/utils";
import * as FileSystem from "expo-file-system";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import ExpoImage from "../ExpoImage";
import Toast from "react-native-toast-message";

const { width } = Dimensions.get("window");

export type NoteObj = {
  createdByUserFirstName: string;
  createdByUserLastName: string;
  createdByUserUuid: string;
  dateCreated: string;
  dateUpdated: string;
  isPrivate: boolean;
  note: string;
  uuid: string;
};

interface UploadedImageProps {
  index: number;
  image: string;
  orderProductUuid: string;
  notes: any[];
  order: any;
  hideComments?: boolean;
  hideUpload?: boolean;
  isImage: boolean;
  fileExtension: string;
  onRefresh?: () => void;
}

const UploadedImage: React.FC<UploadedImageProps> = ({
  index,
  order,
  image,
  orderProductUuid,
  notes,
  hideComments = false,
  hideUpload = false,
  isImage,
  fileExtension,
  onRefresh = null,
}) => {
  const navigation = useNavigation();
  const [visible, setVisible] = useState(false);
  const deleteUpload = useDeleteUpload();
  const [isVideo, setIsVideo] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const offset = useSharedValue(0);

  useEffect(() => {
    if (Platform.OS !== "web") {
      _checkIfDownloaded();
    }
  }, []);

  const _checkIfDownloaded = async () => {
    let tmp = await FileSystem.getInfoAsync(
      FileSystem.documentDirectory + order?.uuid + `-video.${fileExtension}`
    );
    if (tmp.exists) {
      setIsVideo(true);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!isImage) {
      getVideo();
    }
  }, [isImage]);

  const getVideo = async () => {
    let tmp = await FileSystem.getInfoAsync(
      FileSystem.documentDirectory + order?.uuid + `-video.${fileExtension}`
    );
    if (!tmp.exists) {
      _download();
    } else {
      offset.value = withTiming(1, {
        duration: 160,
        easing: Easing.inOut(Easing.ease),
      });
      setIsLoaded(true);
    }
  };

  const _download = async () => {
    const dirInfo2 = await FileSystem.getInfoAsync(
      FileSystem.documentDirectory
    );
    //make dirInfo2.size a kb or mb or gb value
    let _size = "";
    if (dirInfo2.size > 1000000000) {
      _size = (dirInfo2.size / 1000000000).toFixed(2) + " GB";
    } else if (dirInfo2.size > 1000000) {
      _size = (dirInfo2.size / 1000000).toFixed(2) + " MB";
    } else if (dirInfo2.size > 1000) {
      _size = (dirInfo2.size / 1000).toFixed(2) + " KB";
    } else {
      _size = dirInfo2.size + " B";
    }

    const videoUrl = image;

    const destinationUri =
      FileSystem.documentDirectory + order?.uuid + `-video.${fileExtension}`;

    const downloadObject = FileSystem.createDownloadResumable(
      videoUrl,
      destinationUri,
      {},
      (downloadProgress) => {
        const progress =
          downloadProgress.totalBytesWritten /
          downloadProgress.totalBytesExpectedToWrite;
        offset.value = progress;
      }
    );

    const result = await downloadObject.downloadAsync();

    if (result?.status === 200) {
      setIsLoaded(true);
    }
  };

  const _delete = () => {
    Alert.alert(
      "Delete Image",
      "Are you sure you want to delete this image? All comments on this image will be deleted as well.",
      [
        {
          text: "Cancel",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => {
            deleteUpload
              .mutateAsync({
                orderProductUploadUuid: orderProductUuid,
              })
              .then(() => {
                reactQuery.invalidateQueries("orderDetails");
                reactQuery.invalidateQueries("orderSummary");
              });
          },
        },
      ]
    );
  };


  const _savePhoto = async () => {
  

    try {
      // await CameraRoll.save(image, { type: "photo" });
      Toast.show({
        type: "success",
        props: {
          message: "Image saved to camera roll",
        },
      });
    } catch (e) {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to save",
        },
      });
    }
  };

  const animatedLoaderStyle = useAnimatedStyle(() => {
    return {
      width: `${offset.value * 100}%`,
    };
  });

  return (
    <View style={styles.body}>
      {!isImage && !isLoaded ? (
        <View
          style={{
            position: "absolute",
            top: 200,
            left: 0,
            right: 0,
            zIndex: 1,
            width: width,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "rgba(0,0,0,0.2)",
              borderRadius: 3,
              height: 6,
              width: 200,
            }}
          >
            <Animated.View
              style={[
                animatedLoaderStyle,
                {
                  backgroundColor: "white",
                  borderRadius: 3,
                  height: 6,
                },
              ]}
            />
          </View>
        </View>
      ) : null}
      <View style={styles.container}>
        <TouchableOpacity
          activeOpacity={0.9}
          style={styles.shadowContainer}
          onPress={() => {
            if ((isImage && imageIsLoaded) || (!isImage && isLoaded)) {
              navigation.navigate("ImagePreview", {
                image: image,
                isVideo: isImage ? false : true,
                order: order,
                fileExtension: fileExtension,
              });
            }
            if (Platform.OS === "web") {
              window.open(image, "_blank");
            }
          }}
        >
          {isImage ? (
            <>
              {Platform.OS !== "web" ? (
                <ExpoImage
                  source={image}
                  _height={width * 0.8}
                  _width={width * 0.8}
                  cacheKey={order?.uuid}
                  cachePolicy="memory"
                  priority={index === 0 ? "high" : "low"}
                  placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
                  contentFit="cover"
                  transition={100}
                  onRefresh={() => {
                    if (onRefresh) {
                      onRefresh();
                    }
                  }}
                  onLoadEnd={() => {
                    setImageIsLoaded(true);
                  }}
                  onError={(e) => {
                    setIsVideo(true);
                  }}
                />
              ) : (
                <WebImage
                  style={{
                    resizeMode: "cover",
                    backgroundColor: "grey",
                    borderRadius: 8,
                    width: 300,
                    height: 300,
                  }}
                  source={{ uri: image }}
                />
              )}
            </>
          ) : (
            <View
              style={[
                styles.image,
                {
                  backgroundColor: "grey",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              {isLoaded ? (
                <Feather name="video" size={38} color="white" />
              ) : (
                <ActivityIndicator color={Colors.light.background} />
              )}
            </View>
          )}
        </TouchableOpacity>
        {hideUpload ? null : (
          <View style={styles.rowContainer}>
            <View>
              <Text style={styles.text}>{`Uploaded ${formatDateTime(
                order?.dateUploaded
              )}`}</Text>
              <Text
                style={styles.text}
              >{`By: ${order?.uploadedByUserFirstName} ${order?.uploadedByUserLastName}`}</Text>
            </View>
            {hideComments ? null : (
              <View style={styles.buttonContainer}>
                <CircleButton
                  icon={
                    <Feather
                      name="download"
                      size={14}
                      color={Colors.light.background}
                    />
                  }
                  onPress={() => {
                    _savePhoto();
                  }}
                />
                <View style={styles.buttonSpacer} />

                <CircleButton
                  icon={
                    <Feather
                      name="edit"
                      size={14}
                      color={Colors.light.background}
                    />
                  }
                  onPress={() => {
                    navigation.navigate("CreateNote", {
                      orderProductUuid: orderProductUuid,
                    });
                  }}
                />

                <View style={styles.buttonSpacer} />
                <CircleButton
                  icon={
                    <Feather
                      name="trash"
                      size={14}
                      color={Colors.light.background}
                    />
                  }
                  onPress={() => {
                    _delete();
                  }}
                />
              </View>
            )}
          </View>
        )}
        {hideComments ? (
          <View style={{ height: 14 }} />
        ) : (
          <ViewNotes notes={notes.reverse()} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    width: Platform.OS !== "web" ? width : 360,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonSpacer: {
    width: 10,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: Platform.OS !== "web" ? width * 0.8 : 340,
    marginTop: 10,
  },
  shadowContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.18,
    shadowRadius: 8,
    elevation: 5,
    margin: 10,
    borderRadius: 10,
  },
  image: {
    width: width * 0.8,
    height: width * 0.8,
    borderRadius: 8,
  },
  webImage: {
    borderRadius: 8,
    resizeMode: "cover",
    width: "100%",
    height: "100%",
  },
  container: {
    paddingTop: 4,
    width: Platform.OS !== "web" ? "90%" : 340,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    marginBottom: 10,
  },
  text: {
    color: Colors.light.brown,
    fontSize: 12,
    fontWeight: "500",
  },
  line: {
    width: width * 0.8,
    height: 1,
    backgroundColor: Colors.light.brown,
    opacity: 0.6,
    marginTop: 8,
    marginBottom: 2,
  },
  row: {
    marginTop: 10,
    marginBottom: 10,
    width: width * 0.84,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default UploadedImage;
