import { StyleSheet, View, Text } from "react-native";
import React, { useContext } from "react";
import Colors from "../../../constants/Colors";
import { NoteObj } from "../../../components/UploadedImage";
import { capitalizeFirstLetter, formatDateTime } from "../../../services/utils";
import Context from "../../../context";

interface NoteProps {
  note: NoteObj;
}

const Note: React.FC<NoteProps> = ({ note }) => {
  const { user } = useContext(Context);

  return (
    <View>
      <View style={styles.line} />
      <Text style={styles.noteText}>{note.note}</Text>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={styles.authorText}>{`- ${
          capitalizeFirstLetter(note.createdByUserFirstName)
        } ${note.createdByUserLastName}  ${formatDateTime(
          note.dateCreated
        )}`}</Text>
        {user?.role !== "client" ? (
          <>
            {note.isPrivate ? (
              <View style={styles.privateContainer}>
                <Text style={styles.privateText}>Internal</Text>
              </View>
            ) : (
              <View style={styles.publicContainer}>
                <Text style={styles.privateText}>Client</Text>
              </View>
            )}
          </>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  noteText: {
    fontSize: 14,
    color: Colors.light.brown,
    fontFamily: "spectral-400",
  },
  line: {
    height: 1,
    backgroundColor: Colors.light.brown,
    width: "100%",
    opacity: 0.2,
    marginTop: 20,
    marginBottom: 10,
  },
  authorText: {
    fontSize: 12,
    color: Colors.light.brown,
    fontFamily: "spectral-400",
    opacity: 0.6,
  },
  privateText: {
    color: "white",
    fontSize: 12,
    fontFamily: "spectral-600",
  },
  privateContainer: {
    backgroundColor: Colors.light.green,
    paddingHorizontal: 6,
    borderRadius: 4,
    marginLeft: 10,
  },
  publicText: {
    color: "white",
    fontSize: 12,
    fontFamily: "spectral-600",
  },
  publicContainer: {
    backgroundColor: Colors.light.red,
    paddingHorizontal: 6,
    borderRadius: 4,
    marginLeft: 10,
  },
});

export default Note;
