import { useRoute } from "@react-navigation/native";
import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  FlatList,
} from "react-native";
import Colors from "../../constants/Colors";
import Header from "../../components/Header";
import { useGetOrderPhotoArchive } from "../../hooks/orders/useOrderPhotoArchive";
import { useNavigation } from "@react-navigation/native";
import { Image } from "expo-image";

const PhotoArchive = () => {
  const route = useRoute();
  const { navigate } = useNavigation();
  const { orderUuid } = route.params;
  const { data: photos, isFetching } = useGetOrderPhotoArchive(orderUuid);

  const handlePhotoPress = (item) => {
    navigate("ImagePreview", {
      image: item.blobUri,
      isVideo: false,
      order: orderUuid,
      fileExtension: item.fileExtension,
    });
  };

  const _renderPhotoItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => handlePhotoPress(item)}
      style={styles.photoContainer}
    >
      <Image
        source={{ uri: item.blobUri, cacheKey: item.blobUri }}
        style={styles.photo}
      />
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <Header title="Photo Archive" goBack />
      <FlatList
        data={photos || []}
        renderItem={_renderPhotoItem}
        numColumns={2}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.gallery}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.light.background,
  },
  gallery: {
    paddingLeft: 6,
    paddingTop: 5,
    paddingBottom: 100,
  },
  photoContainer: {
    flex: 1,
    marginTop: 3,
    borderRadius: 10,
    overflow: "hidden",
    height: Dimensions.get("window").width / 2 - 10,
    width: Dimensions.get("window").width / 2 - 10,
  },
  photo: {
    width: Dimensions.get("window").width / 2 - 10,
    height: Dimensions.get("window").width / 2 - 10,
    borderRadius: 10,
  },
});

export default PhotoArchive;
