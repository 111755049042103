import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetAssignedUsers = (personUuid?: string) => {
  return useQuery(
    ["assignedUsers", personUuid],
    () => personUuid !== "Admin" && getAssignedUsers(personUuid)
  );
};

const getAssignedUsers = async (personUuid?: string) => {
  const { data } = await instanceAXIOS.get(`/User/AssignedUsers/${personUuid}`);
  return data;
};
