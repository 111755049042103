import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import React from "react";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";

interface BottomBarIconProps {
  name: string;
  icon: String;
  color: string;
  active: boolean;
  onPress: () => void;
  unreadCount?: number;
}

const BottomBarIcon: React.FC<BottomBarIconProps> = ({
  name,
  icon,
  color,
  active,
  onPress,
  unreadCount,
}) => {
  return (
    <TouchableOpacity onPress={() => onPress()} style={styles.body}>
      {unreadCount > 0 ? (
        <View style={styles.dotContainer}>
          <View style={styles.dot}>
            <View style={styles.dotInner} />
          </View>
        </View>
      ) : null}
      <Feather
        name={icon}
        size={24}
        style={styles.icon}
        color={active ? color : Colors.light.brown}
      />
      <Text
        style={[styles.title, { color: active ? color : Colors.light.text }]}
      >
        {name}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    marginBottom: -10,
  },
  icon: { marginBottom: 0 },
  title: {
    fontSize: 10,
    marginTop: 2,
  },
  hit: {
    top: 10,
    left: 10,
    right: 10,
    bottom: 30,
  },
  dotContainer: {
    position: "absolute",
    top: 16,
    zIndex: 100,
  },
  dotInner: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Colors.light.messageBlue,
  },
  dot: {
    marginLeft: 18,
    backgroundColor: Colors.light.background,
    borderRadius: 100,
    width: 14,
    height: 14,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default BottomBarIcon;
