import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Colors from "../../../constants/Colors";
import { Modalize } from "react-native-modalize";
import ActionButton from "../../../components/ActionButton";
import * as ImagePicker from "expo-image-picker";
import { UploadInput } from "../../../hooks/uploads/useGetUploadKey";
import { Image } from "expo-image";
import { Feather } from "@expo/vector-icons";
import useSendMessage from "../../../hooks/messaging/useSendMessage";
import * as FileSystem from "expo-file-system";
import { capitalizeFirstLetter } from "../../../services/utils";
import Dropzone from "react-dropzone";

interface ConversationMediaModalProps {
  open: boolean;
  conversationUser: any;
  onClose: () => void;
}

const ConversationMediaModal: React.FC<ConversationMediaModalProps> = ({
  open,
  onClose,
  conversationUser,
}) => {
  const modalizeRef = useRef<Modalize>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const sendMessageMutation = useSendMessage();
  const dropzoneRef = useRef<any>(null);

  useEffect(() => {
    if (open) {
      modalizeRef?.current?.open();
    }
  }, [open]);

  const _pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      videoMaxDuration: 120,
      base64: true,
      quality: 0.4,
      videoQuality: 3,
    });

    const _image = result?.assets[0];
    setSelectedImage(_image);
  };

  const _takeImage = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      videoMaxDuration: 120,
      quality: 0.4,
      videoQuality: 3,
    });

    const _image = result?.assets[0];
    setSelectedImage(_image);
  };

  const _sendImage = () => {
    onSubmit();
  };

  const onSubmit = async () => {
    const form_data = new FormData();
    const name = Math.floor(Math.random() * 100000000000000) + 1;
    const type = selectedImage?.uri.split("/")[1].split(";")[0];
    const uri = selectedImage?.uri.split(",")[1];
    form_data.append("Attachment", {
      uri: uri,
      name: name,
      type: type,
    });
    form_data.append("FileName", name);
    form_data.append("FileExtension", type);
    form_data.append("IsImage", "true");
    form_data.append("OrderConversationUserUuid", conversationUser?.uuid);
    form_data.append("Message", null);
    sendMessageMutation.mutate({
      formData: form_data,
      message: null,
      orderConversationUserUuid: conversationUser.uuid,
      orderUuid: conversationUser.orderUuid,
      attachment: selectedImage?.uri,
      fileName: null,
      fileExtension: null,
      isImage: false,
      conversationUuid: conversationUser.orderUuid,
      name: `${capitalizeFirstLetter(
        conversationUser?.firstName
      )} ${capitalizeFirstLetter(conversationUser?.lastName)}}`,
    });
    setSelectedImage(null);

    modalizeRef?.current?.close();
  };

  return (
    <Modalize
      ref={modalizeRef}
      modalHeight={selectedImage ? 300 : 200}
      onClose={() => onClose()}
      modalStyle={styles.modal}
    >
      {!selectedImage ? (
        <View style={styles.body}>
          <ActionButton
            label="Pick Photo"
            onPress={() => dropzoneRef?.current?.open()}
          />
        </View>
      ) : (
        <View style={styles.imageContainer}>
          <TouchableOpacity
            onPress={() => {
              setSelectedImage(null);
            }}
            hitSlop={styles.hit}
            style={styles.icon}
          >
            <Feather name={"x-circle"} size={26} color={Colors.light.brown} />
          </TouchableOpacity>
          <View style={styles.imageShadow}>
            <Image
              style={styles.image}
              cachePolicy="memory"
              source={{ uri: selectedImage?.uri }}
              placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
              contentFit="cover"
              transition={100}
            />
          </View>
          <TouchableOpacity
            onPress={() => {
              _sendImage();
            }}
            hitSlop={styles.hit}
            style={styles.icon}
          >
            <Feather name={"send"} size={26} color={Colors.light.brown} />
          </TouchableOpacity>
          <Dropzone
            ref={dropzoneRef}
            accept="image/*"
            maxFiles={1}
            onError={(err) => {
              window.alert(err);
            }}
            onDropRejected={(files) => {
              if (files.length > 1) {
                window.alert("Please select one image at a time");
              }
            }}
            onDrop={async (files) => {
              const file = files[0];
              const _fileName = `upload-${Date.now()}`;

              // Check if file size is greater than 200MB
              const MAX_FILE_SIZE_IN_BYTES = 200000000;
              if (file.size > MAX_FILE_SIZE_IN_BYTES) {
                window.alert("Unable to upload Image, Max file size is 200MB");
                return;
              }

              const fileExtensionMatch = file.type.match(/image\/(\w+)/);
              let fileExtension = fileExtensionMatch
                ? fileExtensionMatch[1]
                : "null";

              if (
                fileExtension !== "jpeg" &&
                fileExtension !== "jpg" &&
                fileExtension !== "png"
              ) {
                window.alert(
                  "Unable to upload Image, Image must be a jpeg, jpg, or png"
                );
                return;
              }
            }}
            children={() => <View />}
          />
        </View>
      )}
    </Modalize>
  );
};

export default ConversationMediaModal;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    paddingHorizontal: 20,
    width: "100%",
    paddingTop: 40,
  },
  modal: {
    backgroundColor: Colors.light.background,
  },
  height: { height: 10 },
  image: {
    height: 200,
    width: 200,
    borderRadius: 10,
  },
  imageContainer: {
    flexDirection: "row",
    paddingTop: 40,
    paddingHorizontal: 24,
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
  },
  imageShadow: {
    shadowRadius: 6,
    shadowOffset: {
      height: 2,
      width: 0,
    },
    shadowOpacity: 0.2,
  },
  hit: {
    top: 20,
    left: 20,
    right: 20,
    bottom: 20,
  },
});
