import React, { FC, useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import { useNavigation, useRoute } from "@react-navigation/native";
import Toast from "react-native-toast-message";
import Colors from "../../constants/Colors";
import ModalBody from "../../components/ModalBody";
import instanceAXIOS from "../../services/api";
import {
  View,
  Text,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  Alert,
  Dimensions,
} from "react-native";
import ModalHeader from "../../components/ModalHeader";
import Header from "../../components/Header";
import { Feather } from "@expo/vector-icons";
import CustomAlert from "../../components/CustomAlert";

const width = Dimensions.get("window").width;

const ResetPassword: FC<Props> = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigation();
  const route = useRoute();
  const [isLength, setIsLength] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const { newUser } = route.params;
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const _passwordString = watch("password", "");
  const _code = watch("twoFactorCode", "");

  useEffect(() => {
    _validatePassword(_passwordString);
  }, [_passwordString]);

  const onSubmit = (data) => {
    if (data.Password === data.ConfirmPassword) {
      instanceAXIOS
        .post("/Authentication/ResetPassword", {
          twoFactorCode: data.twoFactorCode,
          password: data.password,
        })
        .then((res) => {
          Toast.show({
            type: "success",
            props: {
              message: "Password reset successfully",
            },
          });
          setTimeout(() => navigate.navigate("Login"), 500);
        })
        .catch((e) => {
          Toast.show({
            type: "error",
            props: {
              message: "Invalid Code",
            },
          });
        });
    } else {
      Toast.show({
        type: "error",
        props: {
          message: "Passwords do not match",
        },
      });
    }
  };

  const _validatePassword = (_password: string) => {
    if (_password.length >= 8) {
      setIsLength(true);
    } else {
      setIsLength(false);
    }

    if (_password.match(/[A-Z]/)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }

    if (_password.match(/[a-z]/)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }

    if (_password.match(/[0-9]/)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }

    if (_password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      setIsSpecial(true);
    } else {
      setIsSpecial(false);
    }

    if (
      _password.length >= 8 &&
      _password.match(/[A-Z]/) &&
      _password.match(/[a-z]/) &&
      _password.match(/[0-9]/) &&
      _password.match(/[!@#$%^&*(),.?":{}|<>]/)
    ) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  };

  const onNewUserSubmit = (data) => {
    if (!isMatch) {
      if (Platform.OS === "web") {
        window.alert(
          "Password does not meet the requirements. Please try again."
        );
      } else {
        Toast.show({
          type: "error",
          props: {
            message:
              "Password does not meet the requirements. Please try again.",
          },
        });
      }
      return;
    }

    if (data.password !== data.confirmPassword) {
      if (Platform.OS === "web") {
        window.alert("Password does not match. Please try again.");
      } else {
        Toast.show({
          type: "error",
          props: {
            message: "Password does not match. Please try again.",
          },
        });
      }
      return;
    }

    instanceAXIOS
      .post("/Authentication/Register", {
        twoFactorCode: data.twoFactorCode,
        password: data.password,
      })
      .then((res) => {
        if (Platform.OS === "web") {
          window.alert("Registration successful. Please login.");
        } else {
          Toast.show({
            type: "success",
            props: {
              message: "Registration successful. Please login.",
            },
          });
        }
        setTimeout(() => {
          navigate.navigate("Login");
        }, 1000);
      })
      .catch((e) => {
        if (Platform.OS === "web") {
          setTimeout(() => {
            setVisible(true);
          }, 100);
        } else {
          Alert.alert(
            "Invalid Code",
            `You entered code ${data.twoFactorCode}.\n \nIf you have entered your code incorrectly, please try again. \n \n If that is the correct code, please check your registration status.`,
            [
              {
                text: "Try Again",
                onPress: () => {
                  setValue("twoFactorCode", "");
                },
              },
              {
                text: "Check Registration Status",
                onPress: () => {
                  navigate.navigate("CheckRegistrationStatus");
                },
                style: "cancel",
              },
            ]
          );
        }
      });
  };

  return (
    <>
      <View style={styles.body}>
        <Header goBack title={newUser ? "Register User" : "Forgot Password"} />
        <KeyboardAvoidingView style={styles.flex} behavior={"height"}>
          <View style={styles.container}>
            <Input
              control={control}
              errors={errors}
              name="twoFactorCode"
              required
              placeholder="Code"
              autoCapitalize="none"
            />
            <Input
              control={control}
              errors={errors}
              name="password"
              required
              placeholder="Password"
              autoCapitalize="none"
              secureEntry
            />
            <View
              style={{
                marginTop: 0,
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <View style={{ paddingHorizontal: 6 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Feather
                    name={isLength ? "check" : "x"}
                    size={14}
                    color={isLength ? Colors.light.green : Colors.light.danger}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 5,
                      fontFamily: "spectral-500",
                    }}
                  >
                    Length 8-32
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Feather
                    name={isUpperCase ? "check" : "x"}
                    size={14}
                    color={
                      isUpperCase ? Colors.light.green : Colors.light.danger
                    }
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 5,
                      fontFamily: "spectral-500",
                    }}
                  >
                    1 Uppercase
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Feather
                    name={isLowerCase ? "check" : "x"}
                    size={14}
                    color={
                      isLowerCase ? Colors.light.green : Colors.light.danger
                    }
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 5,
                      fontFamily: "spectral-500",
                    }}
                  >
                    1 Lowercase
                  </Text>
                </View>
              </View>
              <View style={{ paddingHorizontal: 6 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Feather
                    name={isNumber ? "check" : "x"}
                    size={14}
                    color={isNumber ? Colors.light.green : Colors.light.danger}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 5,
                      fontFamily: "spectral-500",
                    }}
                  >
                    1 Number
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Feather
                    name={isSpecial ? "check" : "x"}
                    size={14}
                    color={isSpecial ? Colors.light.green : Colors.light.danger}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 5,
                      fontFamily: "spectral-500",
                    }}
                  >
                    1 Symbol
                  </Text>
                </View>
              </View>
            </View>
            <Input
              control={control}
              errors={errors}
              name="confirmPassword"
              required
              placeholder="Confirm Password"
              autoCapitalize="none"
              secureEntry
            />
            {newUser ? (
              <Button
                label={"Set Password"}
                onPress={handleSubmit(onNewUserSubmit)}
                backgroundColor={Colors.light.lightGreen}
              />
            ) : (
              <Button
                label={"Reset Password"}
                onPress={handleSubmit(onSubmit)}
                backgroundColor={Colors.light.lightGreen}
              />
            )}
          </View>
        </KeyboardAvoidingView>
      </View>
      <CustomAlert
        visible={visible}
        message={`You entered code ${_code}.\n \nIf you have entered your code incorrectly, please try again. \n \n If that is the correct code, please check your registration status.`}
        title={"Invalid Code"}
        optionOne="Try Again"
        optionTwo="Check Registration Status"
        pressOne={() => {
          setVisible(false);
        }}
        pressTwo={() => {
          setVisible(false);
          navigate.navigate("CheckRegistrationStatus");
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    backgroundColor: Colors.light.background,
  },
  container: {
    marginHorizontal: 40,
    marginTop: 60,
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: Platform.OS !== "web" ? width : 400,
    alignSelf: "center",
    width: "90%",
  },
  flex: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
});

export default ResetPassword;
