import React from "react";
import Toaster from "../components/Toaster";

const toastConfig = {
  success: (internalState) => (
    <Toaster type="success" message={internalState.props.message} />
  ),
  default: (internalState) => (
    <Toaster type="default" message={internalState.props.message} />
  ),
  error: (internalState) => (
    <Toaster type="error" message={internalState.props.message} />
  ),
  warning: (internalState) => (
    <Toaster type="warning" message={internalState.props.message} />
  ),
};
export default toastConfig;
