import React from "react";
import {
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  View,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import Input from "../../components/Input";
import window from "../../constants/Layout";
import { useForm } from "react-hook-form";
import Context, { InputLoginData } from "../../context";
import Button from "../../components/Button";
import { useNavigation } from "@react-navigation/native";
import Colors from "../../constants/Colors";
import { Image } from "expo-image";

export default function Login() {
  const { login } = React.useContext(Context);
  const navigation = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: InputLoginData) => login(data);
  return (
    <ScrollView
      automaticallyAdjustKeyboardInsets
      testID="ID_LOGIN_VIEW"
      style={{
        flex: 1,
        height: window.window.height,
        backgroundColor: "#fbfaf8",
      }}
    >
      <KeyboardAvoidingView style={styles.container} behavior={
        "height"
      }>
      <Image
        style={{
          height: Platform.OS !== "web" ? window.window.width : 300,
          width: Platform.OS !== "web" ? window.window.width : 300,
        }}
        source={require("../../assets/images/splash.jpg")}
        contentFit="cover"
        transition={100}
      />
      <TouchableWithoutFeedback
        onPress={Platform.OS !== "web" && Keyboard.dismiss}
      >
        <View style={styles.inner}>
          <Input
            testID="ID_LOGIN_INPUT"
            control={control}
            errors={errors}
            name="Email"
            required={true}
            placeholder="Email"
            autoCapitalize="none"
            keyboardType="email-address"
            textContentType="oneTimeCode"
          />
          <Input
            testID="ID_PASSWORD_INPUT"
            control={control}
            errors={errors}
            name="Password"
            required={true}
            placeholder="Password"
            autoCapitalize="none"
            secureEntry
            textContentType="oneTimeCode"
          />
          <View style={styles.buttonSection}>
            <Button
              testID="ID_LOGIN_BUTTON"
              label="Login"
              onPress={handleSubmit(onSubmit)}
              backgroundColor={Colors.light.lightGreen}
            />
            <Button
              label="Forgot Password?"
              onPress={() => navigation.navigate("ForgotPassword")}
              backgroundColor="transparent"
              textColor="#000"
            />
            <Button
              label="Register"
              onPress={() =>
                navigation.navigate("ResetPassword", { newUser: true })
              }
              backgroundColor="transparent"
              textColor="#000"
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fbfaf8",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: window.window.width * 0.1,
    maxWidth: Platform.OS !== "web" ? window.window.width : 800,
    alignSelf: "center",
    width: "100%",
  },
  inner: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSection: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
});
