import React from "react";
import { View, FlatList, Text, StyleSheet } from "react-native";
import { capitalizeFirstLetter, formatDate } from "../../../services/utils";
import StatusPill from "../../../components/StatusPill";
import { OrderStatus } from "../../../types";

export interface StatusHistory {
  userUuid: string;
  userFirstName: string;
  userLastName: string;
  status: OrderStatus;
  dateStatusChanged: string;
}
//remove when API is ready
const _statusHistory: StatusHistory[] = [
  {
    userUuid: "1",
    userFirstName: "John",
    userLastName: "Doe",
    status: "orderReceived",
    dateStatusChanged: "2021-09-01T00:00:00",
  },
  {
    userUuid: "2",
    userFirstName: "Jane",
    userLastName: "Doe",
    status: "progress",
    dateStatusChanged: "2021-09-02T00:00:00",
  },
  {
    userUuid: "3",
    userFirstName: "Alice",
    userLastName: "Doe",
    status: "readyToFrame",
    dateStatusChanged: "2021-09-03T00:00:00",
  },
];

interface StatusHistoryProps {
  statusHistory: StatusHistory[];
}

const StatusHistory: React.FC<StatusHistoryProps> = ({ statusHistory }) => {
  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>Status History</Text>
      </View>
      <FlatList
        data={_statusHistory}
        renderItem={({ item }) => (
          <View style={styles.statusItem}>
            <View>
              <Text style={styles.date}>
                {formatDate(item?.dateStatusChanged)}
              </Text>
              <Text style={styles.statusItemText}>
                {item?.userFirstName && item?.userLastName
                  ? `${capitalizeFirstLetter(
                      item.userFirstName
                    )} ${capitalizeFirstLetter(item.userLastName)}`
                  : "N/A"}
              </Text>
            </View>
            <StatusPill status={item.status} small />
          </View>
        )}
        ItemSeparatorComponent={() => (
          <View style={{ height: 1, backgroundColor: "#DDDBD5" }} />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    paddingHorizontal: 16,
  },
  titleWrapper: {
    borderBottomColor: "#DDDBD5",
    borderBottomWidth: 1,
    marginBottom: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    fontFamily: "spectral-600",
  },
  statusItem: {
    paddingVertical: 10,
    backgroundColor: "transparent",
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  date: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "spectral-700",
  },
  statusItemText: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: "#75706C",
  },
});

export default StatusHistory;
