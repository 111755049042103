import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetEvents = (monthYear: { month: number; year: number }) => {
  return useQuery(["events", monthYear.month, monthYear.year], () =>
    getEvents(monthYear)
  );
};

const getEvents = async (monthYear: { month: number; year: number }) => {
  const offset = new Date().getTimezoneOffset();
  const { data } = await instanceAXIOS.get(
    `/Calendar/OrderProductUploads/${monthYear.month}/${
      monthYear.year
    }/${-offset}`
  );
  return data;
};
