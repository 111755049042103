import {
  Alert,
  Dimensions,
  Keyboard,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import Header from "../../../components/Header";
import { View, Text } from "../../../components/Themed";
import Colors from "../../../constants/Colors";
import { RootTabScreenProps } from "../../../types";
import React, { useEffect, useState } from "react";
import ModalHeader from "../../../components/ModalHeader";
import { useNavigation } from "@react-navigation/native";
import DatePicker from "react-native-date-picker";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import Toast from "react-native-toast-message";
import instanceAXIOS from "../../../services/api";
import reactQuery from "../../../services/reactQuery";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { formatDateTime } from "../../../services/utils";

const { height } = Dimensions.get("window");

export default function ManagerCreateTask({
  route,
}: RootTabScreenProps<"TabOne">) {
  const { user_uuid, user_name } = route.params;
  const navigation = useNavigation();
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const nextDayAt5pm = () => {
    const today = new Date();
    const tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate(),
      17,
      0,
      0,
      0
    );
  };

  useEffect(() => {
    setValue("user_uuid", user_uuid);
    setDate(nextDayAt5pm());
    setValue("dateDue", nextDayAt5pm().toISOString());
  }, [user_uuid]);

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    if (currentDate > new Date()) {
      setDate(currentDate);
      setValue("dateDue", currentDate.toISOString());
    }
  };

  const onSubmit = (data) => {
    instanceAXIOS
      .post("/UserTasks", {
        task: data.task,
        assignedToUserUuid: data.user_uuid,
        dateDue: data.dateDue,
      })
      .then((res) => {
        reactQuery.invalidateQueries("userTasks");
        navigation.goBack();
        Toast.show({
          type: "success",
          props: {
            message: "Task created Successfully",
          },
        });
      })
      .catch((err) => {
        navigation.goBack();
        Toast.show({
          type: "error",
          props: {
            message: "Unable to create task",
          },
        });
      });
  };

  const onGoBack = () => {
    Alert.alert(
      "Are you sure?",
      "You will lose all the data you have entered.",
      [
        {
          text: "Cancel",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => navigation.goBack(),
        },
      ]
    );
  };

  return (
    <View style={styles.container}>
      <ModalHeader
        goBack
        headerText={"Create Task"}
        goBackCustom={() => {
          onGoBack();
        }}
      />
      <Text style={styles.titlePage}>{`Task for: ${user_name}`}</Text>
      <View style={styles.contentContainer}>
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text style={styles.label}>Due Date:</Text>
          <TouchableOpacity
            style={{
              zIndex: 400,
            }}
            onPress={() => {
              alert("clicked");
              setOpen(true);
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontFamily: "spectral-400",
                color: Colors.light.brown,
              }}
              onPress={() => setOpen(true)}
            >
              {formatDateTime(date)}
            </Text>
          </TouchableOpacity>
          <DatePicker
            modal
            open={open}
            date={date}
            onConfirm={(date) => {
              setOpen(false);
              setDate(date);
              onChange(null, date);
            }}
            onCancel={() => {
              setOpen(false);
            }}
          />
        </View>
        <Input
          name={"task"}
          placeholder={"Task"}
          control={control}
          errors={errors}
          onSubmitEditing={() => Keyboard.dismiss()}
          height={40}
          required
        />
        {/* </TouchableWithoutFeedback> */}
        <View style={styles.buttonContainer}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Create Task"}
            onPress={handleSubmit(onSubmit)}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    marginTop: 60,
  },
  container: {
    flex: 1,
  },
  label: {
    fontSize: 18,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    marginRight: 10,
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
    marginBottom: 20,
  },
  contentContainer: {
    paddingHorizontal: 20,
  },
});
