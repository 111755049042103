import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";

export type removeUserFromConversationInput = {
  userConversationUuid: string;
};

const removeUserFromConversation = async (_data: removeUserFromConversationInput) => {
  const res = await instanceAXIOS.delete(
    `/OrderConversation/RemoveUser/${_data.userConversationUuid}`
  );
  return res.data;
};

export const useRemoveUserFromConversation = () => {
  return useMutation(
    (_data: removeUserFromConversationInput) => removeUserFromConversation(_data),
    {
      onSuccess: (data: any) => {
        return data;
      },
      onError: () => {
        Toast.show({
          type: "error",
          props: {
            message: "Unable to remove user from conversation",
          },
        });
      },
    }
  );
};

export default useRemoveUserFromConversation;
