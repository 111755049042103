import React, { useContext } from "react";
import { StyleSheet, TouchableOpacity, Alert } from "react-native";
import Header from "../../components/Header";
import { View, Text } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import Context from "../../context";
import Colors from "../../constants/Colors";
import Button from "../../components/Button";
import DatePicker from "react-native-date-picker";
import { formatDate } from "../../services/utils";
import instanceAXIOS from "../../services/api";
import { useNavigation } from "@react-navigation/native";

export default function ExportOptions({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const { goBack } = useNavigation();
  const { user } = useContext(Context);
  const [openStart, setOpenStart] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    //the first of the month last month
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );

  const [openEnd, setOpenEnd] = React.useState(false);
  const [endDate, setEndDate] = React.useState(
    //the first day of the current month
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );


  const _generateExport = () => {
    //convert dates to date time string
    const _startDate = startDate.toISOString();
    const _endDate = endDate.toISOString();
    instanceAXIOS.post(`/Export/OrderProductSummary/${_startDate}/${_endDate}`).then((res) => {
      Alert.alert(
        `Generating Export`,
        ` Report sent to: ${user?.email}`,
        [
          {
            text: "Ok",
            onPress: () => {
              goBack()
            },
            style: "cancel",
          },
        ]
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <View style={styles.container} testID="ID_EXPORT_OPTIONS_VIEW">
      <Header goBack />
      <View style={styles.contentContainer}>
        <View style={styles.dateContainer}>
          <View style={styles.dateRow}>
            <Text style={styles.dateText}>Start Date:</Text>
            <TouchableOpacity
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              onPress={() => {
                setOpenStart(true);
              }}
            >
              <Text style={styles.dateTextSecond}>{formatDate(startDate)}</Text>
            </TouchableOpacity>
            <DatePicker
              modal
              mode="date"
              open={openStart}
              date={startDate}
              onConfirm={(date) => {
                setStartDate(date);
                setOpenStart(false);
              }}
              onCancel={() => {
                setOpenStart(false);
              }}
            />
          </View>
          <View style={styles.dateRow}>
            <Text style={styles.dateText}>End Date:</Text>
            <TouchableOpacity
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              onPress={() => {
                setOpenEnd(true);
              }}
            >
              <Text style={styles.dateTextSecond}>{formatDate(endDate)}</Text>
            </TouchableOpacity>
            <DatePicker
              modal
              mode="date"
              open={openEnd}
              date={endDate}
              onConfirm={(date) => {
                setEndDate(date);
                setOpenEnd(false);
              }}
              onCancel={() => {
                setOpenEnd(false);
              }}
            />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <Button label="Generate Export" onPress={() => _generateExport()} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  contentContainer: {
    paddingTop: 10,
    flex: 1,
    justifyContent: "space-between",
    width: "100%",
  },
  dateContainer: {
    width: "100%",
    paddingHorizontal: 18,
  },
  buttonContainer: {
    paddingVertical: 30,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  dateRow: {
    flexDirection: "row",
    paddingVertical: 10,
  },
  dateText: {
    fontFamily: "spectral-600",
    fontSize: 16,
    color: Colors.light.brown,
  },
  dateTextSecond: {
    fontFamily: "spectral-600",
    fontSize: 16,
    paddingLeft: 10,
    color: Colors.light.brown,
  },
});
