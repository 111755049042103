import {
  StyleSheet,
  View,
  RefreshControl,
  Dimensions,
  Keyboard,
  Platform,
} from "react-native";
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import ConversationBreadCrumbs from "./ConversationBreadCrumbs";
import FloatingButton from "./FloatingButton";
import ChatInputBar from "./ChatInputBar";
import ConversationMediaModal from "./ConversationMediaModal";
import ChatBubble from "./ChatBubble";
import AvatarModal from "./AvatarModal";
import { useGetConversation } from "../../hooks/messaging/useGetConversation";
import addUserToConversation from "../../hooks/messaging/useAddUserToConversation";
import Context from "../../context";
import useSendMessage from "../../hooks/messaging/useSendMessage";
import Toast from "react-native-toast-message";
import toastConfig from "../../util/ToastConfig";
import { useGetConversationUsers } from "../../hooks/messaging/useGetConversationUsers";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "react-query";
import { capitalizeFirstLetter } from "../../services/utils";
import { useResetUnreadMessageCount } from "../../hooks/messaging/useResetUnreadMessageCount";
import OrderProductsModal from "./OrderProductsModal";
import Dropzone from "react-dropzone";

const { height } = Dimensions.get("window");

interface ConversationProps {}

const Conversation: React.FC<ConversationProps> = ({ route }) => {
  const { name, orderNumber, orderUuid, unread, fromNotification } =
    route?.params;
  const { user, connection, setTabVisible, getUnreadCount } =
    useContext(Context);
  const navigation = useNavigation();
  const dropzoneRef = useRef<any>(null);
  const [mediaModal, setMediaModal] = useState<boolean>(false);
  const [avatarModal, setAvatarModal] = useState<boolean>(false);
  const [avatarSelected, setAvatarSelected] = useState<string>(null);
  const [conversationUser, setConversationUser] = useState<any>(null);
  const [orderPoductsModal, setOrderProductsModal] = useState<boolean>(false);
  const {
    data: messages,
    isLoading,
    refetch,
    fetchNextPage,
  } = useGetConversation({
    orderUuid: orderUuid,
    offset: new Date().getTimezoneOffset(),
  });
  const addUserToConversationMutation = addUserToConversation();
  const {
    data: conversationUsers,
    isLoading: usersLoading,
    refetch: refecthConversationUsers,
  } = useGetConversationUsers(orderUuid);
  const queryClient = useQueryClient();
  const { mutate: resetUnreadMessageCount } = useResetUnreadMessageCount();
  const sendMessageMutation = useSendMessage();

  React.useEffect(() => {
    if (unread) {
      resetUnreadMessageCount({ orderUuid });
      getUnreadCount();
      if (unread) {
        const conversations = queryClient.getQueryData("conversations");
        if (conversations?.pages > 0) {
          const conversationIndex = conversations?.pages[0].items.findIndex(
            (conversation) => conversation.orderUuid === orderUuid
          );
          if (conversationIndex !== -1) {
            conversations.pages[0].items[
              conversationIndex
            ].unreadMessageCount = 0;
            queryClient.setQueryData("conversations", conversations);
          }
        } else {
          queryClient.invalidateQueries("conversations");
        }
      }
    }
  }, [unread]);

  React.useEffect(() => {
    setTabVisible(false);
    return () => {
      setTabVisible(true);
    };
  }, []);

  React.useEffect(() => {
    refetch();
    setTimeout(() => {
      connection.on("ReceiveMessage", (data) => {
        if (data?.orderUuid === orderUuid) {
          refetch();
          resetUnreadMessageCount({ orderUuid });
          getUnreadCount();
        }
      });
      connection.on("AddUserToOrderConversation", (data) => {
        if (data?.orderUuid === orderUuid) {
          Toast.show({
            type: "success",
            props: {
              message: `New person added to conversation: ${data?.firstName} ${data?.lastName}`,
            },
          });
          queryClient.invalidateQueries(["ConversationUsers", orderUuid]);
          refecthConversationUsers();
        }
      });
      connection.on("RemoveUserFromOrderConversation", (data) => {
        if (data?.orderUuid === orderUuid) {
          Toast.show({
            type: "success",
            props: {
              message: `Person removed to conversation`,
            },
          });
          queryClient.invalidateQueries(["ConversationUsers", orderUuid]);
          refecthConversationUsers();
        }
      });
    }, 1000);
    return () => {
      connection.off("AddUserToOrderConversation");
      connection.off("RemoveUserFromOrderConversation");
    };
  }, []);

  const _joinConversation = () => {
    if (user) {
      addUserToConversationMutation.mutate(
        {
          orderUuid: orderUuid,
          userUuid: user.uuid,
        },
        {
          onSuccess: (data) => {
            setConversationUser(data);
            queryClient.invalidateQueries(["ConversationUsers", orderUuid]);
          },
        }
      );
    }
  };

  //check if user is a member of the conversation
  useEffect(() => {
    if (conversationUsers?.length > 0) {
      conversationUsers?.map((_user: any) => {
        if (_user?.userUuid === user?.uuid) {
          setConversationUser(_user);
        }
      });
    } else {
      setConversationUser(null);
    }
  }, [conversationUsers]);

  const _addToConversation = () => {
    //todo
  };

  const _updateCustomerOrderProductStatus = () => {
    setOrderProductsModal(true);
  };

  const flatData = () => {
    const flatArray: any = [];
    messages?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  return (
    <View style={styles.flexBody}>
      <Header
        goBack
        goBackAction={() => {
          getUnreadCount();

          if (fromNotification) {
            if (user?.role === "client") {
              navigation.reset({
                index: 0,
                routes: [{ name: "ClientBottomTabNavigator" }],
              });
            } else {
              navigation.reset({
                index: 0,
                routes: [{ name: "Root" }],
              });
            }
          } else {
            navigation.goBack();
          }
        }}
      />
      {user?.role !== "client" ? (
        <ConversationBreadCrumbs
          onPress={() => {
            Keyboard.dismiss();
            navigation.navigate("OrderScreen", {
              orderNumber: orderNumber,
              orderProductUuid: orderUuid,
            });
          }}
          name={name}
          orderNumber={orderNumber}
        />
      ) : null}
      <FlashList
        data={flatData()}
        inverted
        layout="default"
        onScrollBeginDrag={() => {
          Keyboard.dismiss();
        }}
        estimatedItemSize={100}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              refetch();
            }}
          />
        }
        isLoading={isLoading}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          fetchNextPage();
        }}
        ListEmptyComponent={
          <LoadingState
            empty
            emptyIcon="message-square"
            emptyText="No Messages"
            refreshEnabled
            refetch={() => {
              refetch();
            }}
          />
        }
        contentContainerStyle={{
          backgroundColor: "#fff",
          paddingTop: 20,
          paddingBottom: 100,
        }}
        loadingComponent={<LoadingState />}
        renderItem={({ item, index }) => (
          <ChatBubble
            name={`${capitalizeFirstLetter(
              item?.firstName
            )} ${capitalizeFirstLetter(item?.lastName)}`}
            date={item?.dateCreated}
            message={item?.message}
            isSender={
              item?.orderConversationUserUuid === conversationUser?.uuid
            }
            image={item?.attachment?.blobUri}
            avatar={item?.userAvatar}
            onAvatarPress={(avatar) => {
              setAvatarModal(true);
              setAvatarSelected({
                name: `${item?.firstName} ${item?.lastName}`,
                image: item?.userAvatar,
              });
            }}
            includeAvatar={
              //inverted now so check if last item
              index === flatData().length - 1 ||
              flatData()[index + 1]?.orderConversationUserUuid !==
                item?.orderConversationUserUuid
            }
          />
        )}
      />
      {conversationUser && user?.role !== "client" ? (
        <>
          <FloatingButton
            bottom={100}
            icon={"users"}
            size={21}
            onPress={() => {
              Keyboard.dismiss();
              navigation.navigate("ConversationDetails", {
                users: conversationUsers,
                conversationUser: conversationUser,
                orderUuid: orderUuid,
              });
            }}
          />
          <FloatingButton
            bottom={40}
            icon={"package"}
            size={21}
            onPress={() => {
              Keyboard.dismiss();
              _updateCustomerOrderProductStatus();
            }}
          />
        </>
      ) : null}
      <ChatInputBar
        conversationUser={conversationUser}
        onPressMedia={() => {
          if (Platform.OS === "web") {
            dropzoneRef.current?.open();
          } else {
            setMediaModal(true);
          }
        }}
        onPressIsMember={() => {
          _joinConversation();
        }}
        onSend={(t: string) => {
          // sendMessage(t);
        }}
        isLoading={usersLoading}
      />
      {Platform.OS === "web" ? (
        <Dropzone
          ref={dropzoneRef}
          accept="image/*"
          //include base
          maxFiles={1}
          onError={(err) => {
            window.alert(err);
          }}
          onDropRejected={(files) => {
            if (files.length > 1) {
              window.alert("Please select one image at a time");
            }
          }}
          onDrop={async (files) => {
            const file = files[0];
            const _fileName = `upload-${Date.now()}`;
            //get set a new var _base64 to be the base64 string
            const base64 = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

            const MAX_FILE_SIZE_IN_BYTES = 200000000;
            if (file.size > MAX_FILE_SIZE_IN_BYTES) {
              window.alert("Unable to upload Image, Max file size is 200MB");
              return;
            }

            const fileExtensionMatch = file.type.match(/image\/(\w+)/);
            let fileExtension = fileExtensionMatch
              ? fileExtensionMatch[1]
              : "null";

            if (
              fileExtension !== "jpeg" &&
              fileExtension !== "jpg" &&
              fileExtension !== "png"
            ) {
              window.alert(
                "Unable to upload Image, Image must be a jpeg, jpg, or png"
              );
              return;
            }

            const form_data = new FormData();

            form_data.append("Attachment", file);
            form_data.append("FileName", _fileName);
            form_data.append("FileExtension", fileExtension);
            form_data.append("IsImage", "true");
            form_data.append(
              "OrderConversationUserUuid",
              conversationUser?.uuid
            );

            sendMessageMutation.mutate({
              formData: form_data,
              message: "",
              orderConversationUserUuid: conversationUser.uuid,
              orderUuid: conversationUser.orderUuid,
              attachment: base64,
              fileName: null,
              fileExtension: null,
              isImage: false,
              conversationUuid: conversationUser.orderUuid,
              name: `${capitalizeFirstLetter(
                conversationUser?.firstName
              )} ${capitalizeFirstLetter(conversationUser?.lastName)}`,
            });
          }}
          children={() => <View />}
        />
      ) : null}

      <ConversationMediaModal
        open={mediaModal}
        onClose={() => setMediaModal(false)}
        conversationUser={conversationUser}
      />
      <AvatarModal
        open={avatarModal}
        onClose={() => {
          setAvatarModal(false);
          setAvatarSelected(null);
        }}
        image={avatarSelected?.image}
        name={avatarSelected?.name}
      />
      {OrderProductsModal && (
        <OrderProductsModal
          open={orderPoductsModal}
          onClose={() => {
            setOrderProductsModal(false);
          }}
          orderUuid={orderUuid}
        />
      )}
      <Toast config={toastConfig} />
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1, height: height },
  flexBody: {
    flex: 1,
    backgroundColor: "#fff",
  },
});

export default Conversation;
