import { View, Text, StyleSheet, Alert, Dimensions } from "react-native";
import React from "react";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import { TouchableOpacity } from "react-native-gesture-handler";
import useRemoveUserFromConversation from "../../hooks/messaging/useRemoveUserFromConversation";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "react-query";
import { capitalizeFirstLetter } from "../../services/utils";
import Button from "../../components/Button";
import { useGetConversationUsers } from "../../hooks/messaging/useGetConversationUsers";

const { width } = Dimensions.get("window");

interface ConversationDetailsProps {}

const ConversationDetails: React.FC<ConversationDetailsProps> = ({ route }) => {
  const { conversationUser, orderUuid } = route?.params;
  const removeUserFromConversationMutation = useRemoveUserFromConversation();
  const { data: users } = useGetConversationUsers(orderUuid);
  const { goBack } = useNavigation();
  const queryClient = useQueryClient();
  const { navigate } = useNavigation();

  const _leaveConversation = () => {
    Alert.alert(
      "Leave Conversation",
      "Are you sure you want to leave this conversation?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Leave",
          onPress: () => {
            _removeUserFromConversation();
          },
        },
      ]
    );
  };
  const _removeUserFromConversation = () => {
    removeUserFromConversationMutation.mutate(
      {
        userConversationUuid: conversationUser?.uuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["ConversationUsers", orderUuid]);
          goBack();
        },
      }
    );
  };

  return (
    <View style={styles.body}>
      <View style={styles.width}>
        <Header isModal goBack title={"Conversation Members"} />
        <View>
          {users.map((user: any, index: number) => {
            return (
              <>
                {index === 0 ? <View style={styles.divider} /> : null}
                <View style={styles.item}>
                  <Text style={styles.text}>{`${capitalizeFirstLetter(
                    user?.firstName
                  )} ${capitalizeFirstLetter(user?.lastName)}`}</Text>
                  {conversationUser?.userUuid === user?.userUuid ? (
                    <TouchableOpacity
                      hitSlop={styles.hit}
                      onPress={() => _leaveConversation()}
                    >
                      <Text style={styles.textLeave}>Leave</Text>
                    </TouchableOpacity>
                  ) : null}
                </View>
                <View style={styles.divider} />
              </>
            );
          })}
        </View>
      </View>
      <Button
        backgroundColor={Colors.light.navy}
        label={"Add Team Member"}
        onPress={() => {
          navigate("ConversationAddUser", {
            orderUuid: orderUuid,
            users: users,
          });
        }}
      />
    </View>
  );
};

export default ConversationDetails;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: "space-between",
    alignItems: "center",
    width: width,
    paddingBottom: 40,
  },
  width: {
    width: width,
  },
  item: {
    padding: 16,
    paddingHorizontal: 20,
    height: 60,
    width: width,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divider: {
    height: 1,
    backgroundColor: Colors.light.brown,
    opacity: 0.3,
    width: "90%",
    alignSelf: "center",
  },
  text: {
    fontSize: 17,
    fontFamily: "spectral-700",
    color: Colors.light.brown,
  },
  hit: {
    top: 20,
    left: 20,
    right: 20,
    bottom: 20,
  },
  textLeave: {
    fontSize: 17,
    fontFamily: "spectral-700",
    color: Colors.light.red,
  },
});
