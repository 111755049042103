import { StyleSheet, View, Text, Dimensions } from "react-native";
import React, { useEffect } from "react";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from "react-native-reanimated";
import Colors from "../../../constants/Colors";
const { width } = Dimensions.get("window");
import Context from "../../../context";

interface StepProps {
  data: any;
}

const Step: React.FC<StepProps> = ({ data }) => {
  const offset = useSharedValue(0);
  const offset2 = useSharedValue(0);

  useEffect(() => {
    offset.value = withDelay(
      2400,
      withTiming(1, {
        duration: 800,
        easing: Easing.linear,
      })
    );
    offset2.value = withDelay(
      3000,
      withTiming(1, {
        duration: 400,
        easing: Easing.linear,
      })
    );
  }, [data]);

  const animatedProgressStyles = useAnimatedStyle(() => {
    return {
      opacity: interpolate(offset.value, [0, 1], [0, 1], Extrapolate.CLAMP),
    };
  });
  const animatedTextStyles = useAnimatedStyle(() => {
    return {
      opacity: interpolate(offset2.value, [0, 1], [0, 1], Extrapolate.CLAMP),
    };
  });

  return (
    <>
      <Animated.View style={[styles.container, animatedProgressStyles]}>
        <View style={styles.circleBackground} />
        <View style={styles.circleRing} />
        <Text style={styles.text}>{data?.id}</Text>
      </Animated.View>
      <Animated.Text style={[animatedTextStyles, styles.statusText]}>
        {data?.status}
      </Animated.Text>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 60,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  statusText: {
    color: Colors.light.brown,
    fontFamily: "spectral-700",
    fontSize: 24,
    textAlign: "center",
    marginTop: 10,
  },
  circleBackground: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: Colors.light.brown,
    position: "absolute",
  },
  circleRing: {
    width: 96,
    height: 96,
    borderRadius: 50,
    backgroundColor: Colors.light.clientGreen,
    position: "absolute",
  },
  text: {
    fontSize: 50,
    height: 100,
    lineHeight: 100,
    width: 100,
    zIndex: 100,
    textAlign: "center",
    fontFamily: "spectral-700",
    color: Colors.light.background,
  },
});

export default Step;
