import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";
import reactQuery from "../../services/reactQuery";

const approveDisapprove = async (_data: {
  orderProductUuid: string;
  status: string;
  comment: string;
}) => {
  if (_data.status === "disapproved" && !_data.comment) {
    Toast.show({
      type: "error",
      props: {
        message: "Please provide a comment",
      },
    });
    return;
  }
  const res = await instanceAXIOS.post(
    `/OrderProducts/ClientApproveOrDisapprove`,
    _data
  );
  return res.data;
};

export const useClientApproveDisapprove = () => {
  return useMutation(
    (_data: { orderProductUuid: string; status: string; comment: string }) =>
      approveDisapprove(_data),
    {
      onSuccess: (data: any) => {
        Toast.show({
          type: "success",
          props: {
            message: "Order status updated successfully",
          },
        });
        reactQuery.invalidateQueries("orderSummary");
        reactQuery.invalidateQueries("orderDetails");
        reactQuery.invalidateQueries("orderStatusCounts");
        reactQuery.invalidateQueries("orderCountsByStatus");
        return data;
      },
      onError: (error) => {
        Toast.show({
          type: "error",
          props: {
            message: "Unable set order status",
          },
        });
      },
    }
  );
};
