import React, { useEffect, useRef } from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import Colors from "../../../constants/Colors";
import { Modalize } from "react-native-modalize";
import Avatar from "../../../components/Avatar";

const { width } = Dimensions.get("window");

interface AvatarModalProps {
  open: boolean;
  onClose: () => void;
  image: string;
  name: string;
}

const AvatarModal: React.FC<AvatarModalProps> = ({
  open,
  onClose,
  image,
  name,
}) => {
  const modalizeRef = useRef<Modalize>(null);

  useEffect(() => {
    if (open) {
      modalizeRef?.current?.open();
    }
  }, [open]);

  return (
    <Modalize
      ref={modalizeRef}
      modalHeight={320}
      onClose={() => onClose()}
      modalStyle={styles.modal}
    >
      <View style={styles.container}>
        <Avatar large image={image} name={name} />
        <Text style={styles.name}>{name}</Text>
      </View>
    </Modalize>
  );
};

export default AvatarModal;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    paddingHorizontal: 20,
    width: "100%",
    paddingTop: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    backgroundColor: Colors.light.background,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 40,
  },
  name: {
    fontSize: 26,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    marginTop: 20,
    textAlign: "center",
  },
  container: { 
    width: width, 
    justifyContent: "center", 
    alignItems: "center" 
  },
});
