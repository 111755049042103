import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetConversations = () => {
  const getConversations = async (pageParam: { pageParam: number }) => {
    try {
      const page = pageParam.pageParam || 1;
      const res = await instanceAXIOS.get(`/OrderConversation/${page}/20`, {});
      return res.data;
    } catch (err) {
      Toast.show({
        type: "error",
        props: {
          message: "There was an error fetching conversations",
        },
      });
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(["conversations"], getConversations, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || lastPage.items.length < 20) {
        return undefined;
      }
      return pages.length + 1;
    },
  });

  return {
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};
