import moment from "moment-timezone";

export const formatDate = (_date: string) => {
  if (_date === "0001-01-01T00:00:00") return null;
  let date = convertToUserTimeZone(_date);
  const newDate = new Date(date);
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  if (isNaN(month) || isNaN(day) || isNaN(year)) return "";
  //just last 2 digits of year
  const yearShort = year.toString().substr(-2);
  return `${month}/${day}/${yearShort}`;
};

//format date does not use time zones. The user enters a literal date, but the system just puts it in timezone 0.
//we just need the date, so we can just use the date part of the string
export const formatEventDate = (_date: string) => {
  if (_date === "0001-01-01T00:00:00") return null;
  const [, year, month, day] = /(\d{4})-(\d{2})-(\d{2})T/.exec(_date) || [];
  if (!year || !month || !day) return "";
  const yearShort = year.substr(-2);
  return `${month}/${day}/${yearShort}`;
};

export const formatDateTime = (_date: string) => {
  if (_date === "0001-01-01T00:00:00") return null;
  let date = convertToUserTimeZone(_date);
  const newDate = new Date(date);
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  const hour = newDate.getHours();
  const minute = newDate.getMinutes();
  const ampm = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;
  if (isNaN(month) || isNaN(day) || isNaN(year) || isNaN(hour) || isNaN(minute))
    return "";
  const paddedMinute = minute.toString().padStart(2, "0");
  return `${month}/${day}/${year} ${hour12}:${paddedMinute} ${ampm}`;
};

export const convertToUserTimeZone = (utcTimeString: string) => {
  const userTimeZone = moment.tz.guess(); // get user's timezone
  const userTime = moment.utc(utcTimeString).tz(userTimeZone); // convert to user's timezone
  return userTime; // format time string
};

export const getTimeOrDate = (dateString: string) => {
  const date = new Date(dateString);
  const today = new Date();
  const isSameDay =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() &&
    date.getTimezoneOffset() === today.getTimezoneOffset();

  if (isSameDay) {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
};

export const getTimeOrDateMessaging = (dateString: string) => {
  const date = new Date(dateString);
  const today = new Date();
  const isSameDay =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() &&
    date.getTimezoneOffset() === today.getTimezoneOffset();

  if (isSameDay) {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day} ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })  }`;
  }
};

export const getInitials = (name: string) => {
  const names = name.split(" ");
  let initials = "";
  names.forEach((name) => {
    initials += name.charAt(0);
  });
  return initials.toUpperCase();
};

export const capitalizeFirstLetter = (name: string) => {
  if (!name) return "";
  return name?.charAt(0)?.toUpperCase() + name?.slice(1);
};

