import { StyleSheet, View, Text } from "react-native";
import React from "react";
import Colors from "../../../constants/Colors";

interface StageDetailsItemProps {
  title: string;
  varients: any;
}

const StageDetailsItem: React.FC<StageDetailsItemProps> = ({
  title,
  varients,
}) => {
  return (
    <View style={styles.body}>
      <Text style={styles.title}>{title}</Text>
      {varients?.map((varient) => (
        <View key={varient?.variantTitle} style={{ flexDirection: "row" }}>
          <Text style={styles.varientTitle}>{varient?.variantTitle}</Text>
          <Text style={styles.optionTitle}>{` - ${varient?.count}`}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    width: "100%",
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    marginVertical: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 4,
  },
  varientTitle: {
    fontSize: 14,
    marginLeft: 20,
    paddingVertical: 5,
    fontWeight: "500",
    color: Colors.light.brown,
  },
  optionTitle: {
    fontSize: 14,
    marginLeft: 2,
    paddingVertical: 5,
    fontWeight: "500",
    color: Colors.light.brown,
  },
});

export default StageDetailsItem;
