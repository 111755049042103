import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useRef } from "react";
import Button from "../Button";
import UploadedImage from "../UploadedImage";
import { useSharedValue } from "react-native-reanimated";
import Colors from "../../constants/Colors";
import {
  UploadInput,
  useGetUploadKey,
} from "../../hooks/uploads/useGetUploadKey";
import { Feather } from "@expo/vector-icons";
import Dropzone from "react-dropzone";
import { BlockBlobClient } from "@azure/storage-blob";
import { useQueryClient } from "react-query";

interface UploadFrameProps {
  name: string;
  orderProductUuid: string;
  imageUploadType: "Frame" | "Bouquet" | "Florist";
  uploads: Upload[];
  hideUpload?: boolean;
  hideComments?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}

export type Upload = {
  uuid: string;
  blobUri: string;
  key: string;
};

const UploadFrame: React.FC<UploadFrameProps> = ({
  name,
  orderProductUuid,
  imageUploadType,
  uploads,
  hideComments = false,
  hideUpload = false,
  disabled = false,
}) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const dropzoneRef = useRef<any>(null);
  const xScroll = useSharedValue(0);
  const getUploadKey = useGetUploadKey();
  const queryClient = useQueryClient();

  const getSortedUploads = () => {
    //sort uploads by createdAt date showing newest last
    const sortedUploads = uploads?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA > dateB ? 1 : -1;
    });
    return sortedUploads;
  };

  return (
    <View style={styles.container}>
      {uploads?.length > 0 && <Text style={styles.text}>{name}</Text>}

      <>
        <View
          style={{
            zIndex: 1,
          }}
        >
          {uploads?.length > 2 ? (
            <>
              <TouchableOpacity
                onPress={() => {
                  scrollViewRef.current?.scrollToEnd({ animated: true });
                }}
                style={{
                  zIndex: 100,
                  height: "100%",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                <View
                  style={{
                    padding: 10,
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Feather name="chevron-right" size={30} color="white" />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  scrollViewRef.current?.scrollTo({
                    x: 0,
                    y: 0,
                    animated: true,
                  });
                }}
                style={{
                  zIndex: 100,
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              >
                <View
                  style={{
                    padding: 10,
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Feather name="chevron-left" size={30} color="white" />
                </View>
              </TouchableOpacity>
            </>
          ) : null}
          <ScrollView
            ref={scrollViewRef}
            id="scrollViewRef"
            horizontal
            showsHorizontalScrollIndicator
            persistentScrollbar={true}
            style={{
              maxWidth: 800,
              zIndex: 1,
            }}
            contentContainerStyle={{
              justifyContent: "flex-start",
            }}
          >
            {getSortedUploads()?.map((item, index) => (
              <UploadedImage
                key={index}
                index={index}
                order={item}
                orderProductUuid={item.uuid}
                image={item?.blobUri ?? ""}
                notes={item?.notes ?? []}
                hideComments={hideComments}
                hideUpload={hideUpload}
                isImage={item?.isImage ?? false}
                fileExtension={item?.fileExtension}
              />
            ))}
          </ScrollView>
        </View>
      </>
      <Dropzone
        ref={dropzoneRef}
        accept="image/*"
        maxFiles={1}
        onError={(err) => {
          window.alert(err);
        }}
        onDropRejected={(files) => {
          if (files.length > 1) {
            window.alert("Please select one image at a time");
          }
        }}
        onDrop={async (files) => {
          const file = files[0];
          const _fileName = `upload-${Date.now()}`;

          // Check if file size is greater than 200MB
          const MAX_FILE_SIZE_IN_BYTES = 200000000;
          if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            window.alert("Unable to upload Image, Max file size is 200MB");
            return;
          }

          const fileExtensionMatch = file.type.match(/image\/(\w+)/);
          let fileExtension = fileExtensionMatch
            ? fileExtensionMatch[1]
            : "null";

          if (
            fileExtension !== "jpeg" &&
            fileExtension !== "jpg" &&
            fileExtension !== "png"
          ) {
            window.alert(
              "Unable to upload Image, Image must be a jpeg, jpg, or png"
            );
            return;
          }

          const inputImage = {
            isPrivate: true,
            fileName: _fileName,
            orderProductUuid: orderProductUuid,
            uploadType: imageUploadType,
            isClient: true,
            isImage: true,
            fileExtension: fileExtension,
          } as UploadInput;

          getUploadKey.mutateAsync(inputImage).then(async (_res) => {
            // Create a BlockBlobClient using the blobUrl from Azure Blob Storage
            const blockBlobClient = new BlockBlobClient(_res.blobUri);

            // Read the file data as an ArrayBuffer
            const fileData = await file.arrayBuffer();

            // Upload the file to Azure Blob Storage
            await blockBlobClient
              .uploadData(fileData, {
                blobHTTPHeaders: {
                  blobContentType: file.type,
                },
              })
              .then(() => {
                queryClient.invalidateQueries("orderDetails");
                queryClient.invalidateQueries("orderSummary");
              });
          });
        }}
        children={() => <View />}
      />

      {hideUpload ? null : (
        <Button
          label={name?.toUpperCase()}
          backgroundColor={disabled ? "#999999" : Colors.light.brown}
          icon={
            <Feather
              name={"camera"}
              color={"white"}
              size={18}
              style={styles.icon}
            />
          }
          onPress={() => {
            dropzoneRef.current?.open();
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 6,
  },
  icon: {
    marginRight: 8,
    marginBottom: 2,
  },
  flatList: {
    width: "100%",
  },
  text: {
    width: "100%",
    marginLeft: 80,
    marginBottom: 8,
    textAlign: "left",
    color: Colors.light.brown,
    fontSize: 16,
    fontFamily: "spectral-600",
  },
  modal: {
    backgroundColor: Colors.light.background,
  },
  body: {
    paddingTop: 20,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.background,
  },
});

export default UploadFrame;
