import { StyleSheet, Image, View, TouchableOpacity } from "react-native";
import React from "react";
import { Text } from "../Themed";
import { OrderStatus } from "../../types";
import Colors from "../../constants/Colors";
import StatusPill from "../StatusPill";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";

interface UserListItemProps {
  name: string;
  edit?: boolean;
  assigned?: boolean;
  open?: boolean;
  onPress?: () => void;
}

const UserListItem: React.FC<UserListItemProps> = ({
  name,
  edit = false,
  open = false,
  assigned = false,
  onPress,
}) => {
  const navigation = useNavigation();

  return (
    <>
      <TouchableOpacity
        disabled={!onPress}
        onPress={() => {
          onPress ? onPress() : null;
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 20,
            alignItems: "center",
            height: 52,
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontFamily: "spectral-600",
              color: Colors.light.brown,
            }}
          >
            {name}
          </Text>
          <View>
            <>
              {edit ? (
                <Feather name="edit-2" size={18} color={Colors.light.brown} />
              ) : open ? (
                <Feather
                  name="chevron-right"
                  size={24}
                  color={Colors.light.brown}
                />
              ) : null}
              {assigned ? (
                <Feather name="check" size={18} color={Colors.light.brown} />
              ) : null}
            </>
          </View>
        </View>
      </TouchableOpacity>
      <View
        style={{
          height: 1,
          backgroundColor: Colors.light.background,
          width: "100%",
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({});

export default UserListItem;
