import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrder = (orderUuid: string) => {
  return useQuery(["orderSummary", orderUuid], () => getOrder(orderUuid), {
    cacheTime: 240000,
  });
};

const getOrder = async (orderUuid: string) => {
  const { data } = await instanceAXIOS.get(`/Orders/${orderUuid}`);
  return data;
};
