import React, { FC } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import Colors from "../../../constants/Colors";
import { Feather } from "@expo/vector-icons";

interface DetailItemProps {
  title: string;
  value: string;
  underline?: boolean;
  edit?: boolean;
  onPress?: () => void;
}

const DetailItem: FC<DetailItemProps> = ({
  title,
  value,
  underline = false,
  edit = false,
  onPress,
}) => {
  return (
    <View style={styles.itemRow}>
      <Text style={styles.itemTitle}>{title}</Text>
      {edit ? (
        <TouchableOpacity
          hitSlop={styles.hit}
          onPress={() => {
            onPress ? onPress() : null;
          }}
          style={styles.iconRow}
        >
          <Feather name="edit-2" size={18} color={Colors.light.brown} />
        </TouchableOpacity>
      ) : (
          <Text adjustsFontSizeToFit numberOfLines={1} style={[styles.itemValue, {
            textDecorationLine: underline ? "underline" : "none",
        }]}>
          {value}
        </Text>
      )}
    </View>
  );
};
export default DetailItem;

const styles = StyleSheet.create({
  itemRow: {
    flexDirection: "row",
    borderBottomColor: "#ddd",
    paddingVertical: 2,
    padding: 20,
    alignItems: "center",
  },
  itemTitle: {
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  itemValue: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 6,
    color: Colors.light.brown,
    marginRight: 80,
  },
  iconRow: {
    marginLeft: 10,
  },
  hit: {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
  },
});
