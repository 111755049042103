import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";

export type DeleteClientInput = {
  clientUuid: string;
};

const deleteClient = async (_data: DeleteClientInput) => {
  const res = await instanceAXIOS.delete(
    `/Users/DeleteClient/${_data.clientUuid}`
  );
  return res.data;
};

export const useDeleteClient = () => {
  return useMutation((_data: DeleteClientInput) => deleteClient(_data), {
    onSuccess: (data: any) => {
      return data;
    },
    onError: () => {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to delete account right now",
        },
      });
    },
  });
};

export default deleteClient;
