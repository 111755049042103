import { StyleSheet, View, RefreshControl } from "react-native";
import React from "react";
import Colors from "../../constants/Colors";
import Header from "../../components/Header";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import ConversationListItem from "./ConversationListItem";
import {
  useRoute,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import { useGetConversations } from "../../hooks/messaging/useGetConversations";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import { useSearchOrders } from "../../hooks/orders/useSearchOrders";
import Context from "../../context";
import {
  capitalizeFirstLetter,
  getTimeOrDateMessaging,
} from "../../services/utils";
interface ConversationsProps {}

const Conversations: React.FC<ConversationsProps> = ({}) => {
  const { connection, user, getUnreadCount } = React.useContext(Context);

  const route = useRoute();
  const { navigate } = useNavigation();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const searchTerm = watch("Search");

  const {
    data: orders,
    isLoading: isLoadingOrders,
    fetchNextPage: fetchNextPageOrders,
    refetch: refetchOrders,
  } = useSearchOrders({
    pageNumber: 1,
    pageSize: 20,
    searchTerm: searchTerm,
    orderProductStatusFilter: null,
    orderByOrderDate: 1,
    assignedToMe: false,
    assignedToMyTeam: false,
    orderByEventDate: false,
  });

  const {
    data: conversations,
    isLoading,
    refetch,
    fetchNextPage,
  } = useGetConversations();

  const userNames = (users, userUuid) => {
    const filteredNames = users
      .filter((user) => user?.userUuid !== userUuid)
      .map((user) => user?.firstName);
    return filteredNames.join(", ");
  };

  useFocusEffect(
    React.useCallback(() => {
      //on pop navigation we need to update list
      refetch();
      getUnreadCount();
      connection.on("ReceiveMessage", (data) => {
        refetch();
      });
    }, [])
  );

  const flatData = () => {
    const flatArray: any = [];
    conversations?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  const flatOrders = () => {
    const flatArray: any = [];
    orders?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    const combinedOrders: any = [];
    flatArray?.map((order) => {
      const index = combinedOrders.findIndex(
        (item) => item?.shopifyOrderNumber === order?.shopifyOrderNumber
      );
      if (index > -1) {
        combinedOrders[index] = {
          ...combinedOrders[index],
          unreadMessageCount:
            combinedOrders[index]?.unreadMessageCount +
            order?.unreadMessageCount,
        };
      } else {
        combinedOrders.push(order);
      }
    });
    return combinedOrders;
  };

  return (
    <View style={styles.background}>
      <Header />
      {user?.role !== "client" && (
        <View style={styles.searchContainer}>
          <Input
            search
            name={"Search"}
            placeholder={"Search"}
            control={control}
            errors={errors}
          />
        </View>
      )}
      <FlashList
        data={searchTerm?.length > 0 ? flatOrders() : flatData()}
        estimatedItemSize={100}
        onEndReached={() => {
          searchTerm?.length > 0 ? fetchNextPageOrders() : fetchNextPage();
        }}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              searchTerm?.length > 0 ? refetchOrders() : refetch();
            }}
          />
        }
        isLoading={isLoading}
        contentContainerStyle={styles.flatlist}
        ListEmptyComponent={
          <LoadingState
            empty
            emptyIcon="message-square"
            emptyText="No conversations"
            refreshEnabled={searchTerm?.length === 0 || !searchTerm}
            refetch={() => {
              searchTerm?.length > 0 ? null : refetch();
            }}
          />
        }
        loadingComponent={<LoadingState />}
        renderItem={({ item, index }) =>
          searchTerm?.length > 0 ? (
            <ConversationListItem
              name={`${capitalizeFirstLetter(
                item?.clientFirstName
              )} ${capitalizeFirstLetter(item?.clientLastName)}`}
              orderNumber={item?.shopifyOrderNumber}
              orderUuid={item?.orderUuid}
              updated={"Click to see conversation"}
              unread={false}
              lastMessage={""}
              index={index}
              isSearch
            />
          ) : (
            <ConversationListItem
              name={
                user?.role === "client"
                  ? `Pressed Floral: ${userNames(item?.users, user?.uuid)}`
                  : `${item?.clientFirstName} ${item?.clientLastName}`
              }
              users={item?.users}
              orderNumber={item?.orderNumber}
              orderUuid={item?.orderUuid}
              updated={getTimeOrDateMessaging(item?.lastMessage?.dateCreated)}
              unread={item?.unreadMessageCount > 0}
              unreadItemCount={item?.unreadMessageCount}
              isCustomer={user?.role === "client"}
              lastMessage={item?.lastMessage?.message}
              index={index}
            />
          )
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
    justifyContent: "center",
    alignItems: "center",
  },
  searchContainer: {
    paddingHorizontal: 16,
    marginBottom: 12,
    marginTop: 12,
  },
  background: { backgroundColor: Colors.light.background, flex: 1 },
  flatlist: {
    paddingBottom: 100,
  },
});

export default Conversations;
