import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";

export type DeleteUploadInput = {
  orderProductUploadUuid: string;
};

const deleteUpload = async (_data: DeleteUploadInput) => {
  const res = await instanceAXIOS.delete(
    `/Uploads/${_data.orderProductUploadUuid}/true`
  );
  return res.data;
};

export const useDeleteUpload = () => {
  return useMutation((_data: DeleteUploadInput) => deleteUpload(_data), {
    onSuccess: (data: any) => {
      return data;
    },
    onError: () => {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to delete upload",
        },
      });
    },
  });
};

export default deleteUpload;
