import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  Pressable,
} from "react-native";
import Colors from "../../../constants/Colors";
import { Image } from "expo-image";
import Avatar from "../../../components/Avatar";
import {
  formatDateTime,
  getTimeOrDate,
  getTimeOrDateMessaging,
} from "../../../services/utils";
import { navigate } from "../../../navigation/RootNavigation";
import * as Clipboard from "expo-clipboard";
import Toast from "react-native-toast-message";
import Hyperlink from "react-native-hyperlink";

const { width } = Dimensions.get("window");

const AVATAR = "https://i.pravatar.cc/100?id=skater";

interface ChatBubbleProps {
  message?: string;
  image?: string;
  avatar?: string;
  name: string;
  date: string;
  isSender: boolean;
  includeAvatar?: boolean;
  onAvatarPress?: () => void;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  message = null,
  image = null,
  avatar = null,
  name,
  includeAvatar = false,
  date,
  isSender,
  onAvatarPress,
}) => {
  return (
    <>
      {includeAvatar ? (
        <TouchableOpacity
          onPress={() => onAvatarPress()}
          style={[
            styles.avatarContainer,
            {
              left: isSender ? null : 4,
              right: isSender ? 4 : null,
            },
          ]}
        >
          <Avatar image={avatar} name={name} />
        </TouchableOpacity>
      ) : null}
      {message !== "null" && message ? (
        <>
          <Pressable
            onLongPress={() => {
              Clipboard.setString(message);
              Toast.show({
                type: "success",
                props: {
                  message: "Copied to clipboard",
                },
              });
            }}
            style={[
              (styles.messageContainer,
              isSender ? styles.align : styles.alignOther),
              {
                marginTop: includeAvatar ? 20 : 4,
              },
            ]}
          >
            {includeAvatar ? <Text style={styles.name}>{name}</Text> : null}

            <View style={[isSender ? styles.message : styles.messageOther]}>
              <Hyperlink
                linkStyle={{
                  color: isSender ? "white" : Colors.light.messageBlue,
                  textDecorationLine: "underline",
                }}
                linkDefault={true}
              >
                <Text
                  style={
                    isSender ? styles.messageText : styles.messageTextOther
                  }
                >
                  {message}
                </Text>
              </Hyperlink>
              <Text style={isSender ? styles.date : styles.dateOther}>
                {getTimeOrDateMessaging(date)}
              </Text>
            </View>
          </Pressable>
        </>
      ) : null}
      {image ? (
        <View
          style={[
            (styles.messageContainer,
            isSender ? styles.align : styles.alignOther),
            {
              marginTop: includeAvatar ? 20 : 4,
            },
          ]}
        >
          {includeAvatar ? <Text style={styles.name}>{name}</Text> : null}
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              navigate("ImagePreview", { image: image, keepTabAway: true });
            }}
          >
            <Image
              style={styles.image}
              cachePolicy="memory"
              source={{ uri: image }}
              placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
              contentFit="cover"
              transition={100}
            />
          </TouchableOpacity>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  messageContainer: {
    flexDirection: "row",
    width: "100%",
  },
  image: {
    marginHorizontal: 20,
    height: width * 0.7,
    width: width * 0.7,
    borderRadius: 10,
  },
  avatarContainer: {
    position: "absolute",
    zIndex: 1,
    top: 12,
  },
  align: {
    alignItems: "flex-end",
  },
  alignOther: {
    alignItems: "flex-start",
  },
  message: {
    backgroundColor: Colors.light.messageBlue,
    padding: 10,
    marginHorizontal: 20,
    borderRadius: 10,
    maxWidth: "60%",
  },
  messageOther: {
    backgroundColor: Colors.light.messageOther,
    padding: 10,
    marginHorizontal: 20,
    borderRadius: 10,
    maxWidth: "60%",
  },
  messageText: {
    color: "white",
    fontWeight: "500",
  },
  messageTextOther: {
    color: "black",
    fontWeight: "500",
  },
  date: {
    color: "white",
    fontSize: 10,
    paddingTop: 4,
    alignSelf: "flex-end",
    marginBottom: -2,
  },
  dateOther: {
    fontSize: 10,
    paddingTop: 4,
    alignSelf: "flex-end",
    marginBottom: -2,
  },
  name: {
    marginHorizontal: 42,
    marginBottom: 4,
    fontSize: 12,
    color: Colors.light.brown,
    fontWeight: "500",
  },
});

export default ChatBubble;
