import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useSearchOrders = (searchOrdersInput: SearchOrdersInput) => {
  const fetchOrders = async (pageParam: { pageParam: number }) => {
    if (searchOrdersInput?.searchTerm?.length === 0) {
      searchOrdersInput.searchTerm = " ";
    }
    try {
      const res = await instanceAXIOS.post(`/OrderProducts/Search`, {
        pageNumber: parseInt(pageParam?.pageParam) || 1,
        pageSize: searchOrdersInput.pageSize,
        searchTerm: searchOrdersInput.searchTerm || " ",
        orderProductStatusFilter: searchOrdersInput.orderProductStatusFilter,
        orderByOrderDate: searchOrdersInput.orderByOrderDate,
        assignedToMe: searchOrdersInput.assignedToMe,
        assignedToMyTeam: searchOrdersInput.assignedToMyTeam,
        orderByEventDate: searchOrdersInput.orderByEventDate,
      });
      return res.data;
    } catch (err) {
    
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    refetch,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(["orders", searchOrdersInput], fetchOrders, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || lastPage.length < searchOrdersInput.pageSize) {
        return undefined;
      }
      return pages.length + 1;
    },
    cacheTime: 0,
  });

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    isLoading,
  };
};

export interface SearchOrdersInput {
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
  orderProductStatusFilter: string[] | null;
  orderByOrderDate: number;
  assignedToMe: boolean;
  assignedToMyTeam: boolean;
  orderByEventDate: boolean;
}
