import { StyleSheet, View, Text, Dimensions } from "react-native";
import React, { FC } from "react";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import SortToggle from "../../../components/SortToggle";
import Colors from "../../../constants/Colors";
import { Control } from "react-hook-form";
import { or } from "react-native-reanimated";

const { width } = Dimensions.get("window");

interface Status {
  label: string;
  value: string;
}

interface ControlsProps {
  control: Control;
  errors: any;
  sort: string;
  setSort: (sort: string) => void;
  statusDropDownIsOpen: boolean;
  setStatusDropDownIsOpen: (status: boolean) => void;
  statuses: Status[];
  status: string;
  setStatus: (status: string) => void;
  setStatuses: (statuses: Status[]) => void;
  totalItems: number;
  orderBy?: any;
  setOrderBy?: (orderBy: any) => void;
  orderByDropdownIsOpen?: boolean;
  setOrderByDropdownIsOpen?: (orderBy: boolean) => void;
  orderByOptions?: any;
  setOrderByOptions?: (orderByOptions: any) => void;
}

const Controls: FC<ControlsProps> = ({
  control,
  errors,
  sort,
  statusDropDownIsOpen,
  setStatusDropDownIsOpen,
  statuses,
  status,
  setStatus,
  setStatuses,
  totalItems,
  setSort,
  orderByDropdownIsOpen = false,
  setOrderByDropdownIsOpen = () => {},
  orderBy = null,
  setOrderBy = () => {},
  orderByOptions = null,
  setOrderByOptions = () => {},
}) => {
  const formatNumber = (number: number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <View style={styles.controlsContainer}>
      <Input
        search
        name={"Search"}
        placeholder={"Search"}
        control={control}
        errors={errors}
      />
      {orderByOptions ? (
        <View style={{ zIndex: 1000, marginTop: -10 }}>
          <Dropdown
            label={"Order By"}
            name={"OrderBy"}
            control={control}
            errors={errors}
            open={orderByDropdownIsOpen}
            setOpen={setOrderByDropdownIsOpen}
            value={orderBy}
            items={orderByOptions}
            setValue={setOrderBy}
            setItems={setOrderByOptions}
            width={width - 30}
          />
        </View>
      ) : null}
      <View style={styles.dropDownContainer}>
        <View style={styles.resultsContainer}>
          <Text style={styles.label}>Results</Text>
          <Text style={styles.resultsText}>{formatNumber(totalItems)}</Text>
        </View>
        <View style={{ zIndex: 1000 }}>
          <Dropdown
            label={"Status"}
            name={"Status"}
            control={control}
            errors={errors}
            open={statusDropDownIsOpen}
            setOpen={setStatusDropDownIsOpen}
            value={status}
            items={statuses}
            setValue={setStatus}
            setItems={setStatuses}
            width={width - 180}
          />
        </View>
        <SortToggle
          label={"Sort"}
          onPress={() => {
            setSort(sort === "asc" ? "desc" : "asc");
          }}
          up={sort === "asc" ? true : false}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  controlsContainer: {
    width: "100%",
    paddingHorizontal: 16,
    marginBottom: 12,
    zIndex: 1000,
   
  },
  dropDownContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  resultsContainer: {
    width: 80,
  },
  label: {
    color: Colors.light.brown,
    marginBottom: 4,
    fontSize: 16,
    fontFamily: "spectral-600",
  },
  resultsText: {
    marginTop: 4,
    fontSize: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
});

export default Controls;
