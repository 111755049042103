import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export type EmailMessageInput = {
  emailToImpersonate: string;
  id: string;
};

export const useGetEmailMessage = (emailMessageInput: EmailMessageInput) => {
  return useQuery(
    ["emailMessage", emailMessageInput],
    () => getEmailMessage(emailMessageInput),
    {
      cacheTime: 240000,
    }
  );
};

const getEmailMessage = async (emailMessageInput: EmailMessageInput) => {
  const { data } = await instanceAXIOS.post(
    `/Email/GMail/Message/`,
    emailMessageInput
  );
  return data;
};
