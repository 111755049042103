import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";

export type AddUserToConversationInput = {
  orderUuid: string;
  userUuid: string;
};

const addUserToConversation = async (_data: AddUserToConversationInput) => {
  const res = await instanceAXIOS.post(
    `/OrderConversation/AddUser/${_data?.orderUuid}/${_data?.userUuid}`
  );
  return res.data;
};

export const useAddUserToConversation = () => {
  return useMutation(
    (_data: AddUserToConversationInput) => addUserToConversation(_data),
    {
      onSuccess: (data: any) => {
        return data;
      },
      onError: () => {
        Toast.show({
          type: "error",
          props: {
            message: "Unable to add user to conversation",
          },
        });
      },
    }
  );
};

export default useAddUserToConversation;
