import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetEmailTemplates = (count: number) => {
  return useQuery(["emailTemplates"], () => getEmailTemplates(count));
};

const getEmailTemplates = async (count: number) => {
  const { data } = await instanceAXIOS.get(`/Email/Templates/${count}`);
  return data;
};
