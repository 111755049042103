import { StyleSheet, TouchableOpacity } from "react-native";
import React, { FC } from "react";
import { Text } from "../Themed";
import Colors from "../../constants/Colors";

interface ButtonProps {
  label: string;
  icon?: any;
  backgroundColor?: string;
  onPress: () => void;
  textColor?: string;
  isDisabled?: boolean;
  testID?: string;
}

const Button: FC<ButtonProps> = ({
  label,
  icon = null,
  onPress,
  backgroundColor = Colors.light.brown,
  textColor = "#fff",
  isDisabled = false,
  testID = "ID_BUTTON",
}) => {
  return (
    <TouchableOpacity
      testID={testID}
      disabled={isDisabled}
      onPress={() => onPress()}
      style={[
        styles.body,
        {
          backgroundColor: backgroundColor,
        },
      ]}
    >
      {icon}
      <Text
        allowFontScaling
        adjustsFontSizeToFit
        numberOfLines={1}
        style={[
          styles.text,

          {
            width: icon ? "80%" : "100%",
            marginLeft: icon ? 4 : 0,
            textAlign: icon ? "center" : "center",
            color: textColor,
          },
        ]}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  body: {
    width: "80%",
    height: 42,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    marginVertical: 5,
    cursor: "pointer",
  },
  text: {
    color: "#fff",
    fontFamily: "spectral-600",
    fontSize: 16,
  },
});

export default Button;
