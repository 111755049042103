import { View, Text, StyleSheet, Alert } from "react-native";
import React, { useEffect } from "react";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Context from "../../context";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import useRemoveUserFromConversation from "../../hooks/messaging/useRemoveUserFromConversation";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "react-query";
import { capitalizeFirstLetter } from "../../services/utils";
import { useGetUsersByRole } from "../../hooks/users/useGetUsersByRole";
import useAddUserToConversation from "../../hooks/messaging/useAddUserToConversation";

interface ConversationAddUserProps {}

const ConversationAddUser: React.FC<ConversationAddUserProps> = ({ route }) => {
  const { users, conversationUser, orderUuid } = route?.params;
  const addUserToConversationMutation = useAddUserToConversation();
  const { goBack } = useNavigation();
  const queryClient = useQueryClient();
  const { data: staffUsers } = useGetUsersByRole(
    1,
    50,
    ["Admin", "Manager", "Staff"],
    "adminAndManagers"
  );

  const flatData = () => {
    const flatArray: any = [];
    staffUsers?.items?.map((user) => {
      if (!users?.some((u) => u?.userUuid === user?.uuid)) {
        flatArray.push(user);
      }
    });
    return flatArray;
  };

  const _addUser = (user: any) => {
    addUserToConversationMutation.mutate(
      {
        orderUuid: orderUuid,
        userUuid: user?.uuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["ConversationUsers", orderUuid]);
          goBack();
        },
      }
    );
  };

  return (
    <View style={styles.body}>
      <Header isModal goBack title={"Add Member"} />
      <ScrollView contentContainerStyle={{ paddingBottom: 100 }}>
        {flatData()?.map((user: any, index) => {
          return (
            <>
              {index === 0 ? <View style={styles.divider} /> : null}
              <View style={styles.item}>
                <Text style={styles.text}>{`${capitalizeFirstLetter(
                  user?.firstName
                )} ${capitalizeFirstLetter(user?.lastName)}`}</Text>
                {conversationUser?.userUuid === user?.userUuid ? (
                  <TouchableOpacity
                    hitSlop={styles.hit}
                    onPress={() => _addUser(user)}
                  >
                    <Text style={styles.textLeave}>Add</Text>
                  </TouchableOpacity>
                ) : null}
              </View>
              <View style={styles.divider} />
            </>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default ConversationAddUser;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
  },
  item: {
    padding: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divider: {
    height: 1,
    backgroundColor: Colors.light.brown,
    opacity: 0.3,
    width: "90%",
    alignSelf: "center",
  },
  text: {
    fontSize: 17,
    fontFamily: "spectral-700",
    color: Colors.light.brown,
  },
  hit: {
    top: 20,
    left: 20,
    right: 20,
    bottom: 20,
  },
  textLeave: {
    fontSize: 17,
    fontFamily: "spectral-700",
    color: Colors.light.green,
  },
});
