import {
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
} from "react-native";
import React from "react";
import Colors from "../../../constants/Colors";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

const { width } = Dimensions.get("window");

interface TabBarProps {
  onUpdateTab: (tab: string) => void;
  tabs: string[];
}

const TabBar: React.FC<TabBarProps> = ({ onUpdateTab, tabs }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0]);
  const offset = useSharedValue(0);

  const handleTabPress = (tab: string) => {
    setActiveTab(tab);
    onUpdateTab(tab);
    offset.value = withTiming((tabs.indexOf(tab) * (width - 40)) / tabs.length);
  };

  const indicatorAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offset.value }],
    };
  });

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: width - 40,
        }}
      >
        {tabs.map((tab, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => handleTabPress(tab)}
            style={[
              styles.tab,
              {
                width: (width - 40) / tabs.length,
              },
            ]}
          >
            <Text
              style={[
                styles.tabText,
                {
                  fontFamily:
                    activeTab === tab ? "spectral-700" : "spectral-500",

                  color:
                    activeTab === tab ? Colors.light.navy : Colors.light.brown,
                },
              ]}
            >
              {tab}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <View style={styles.bar}>
        <Animated.View
          style={[
            { width: (width - 40) / tabs.length },
            styles.barIndicator,
            indicatorAnimatedStyle,
          ]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tabText: {
    fontSize: 16,
  },
  barIndicator: {
    height: 4,
    borderRadius: 4,
    backgroundColor: Colors.light.navy,
  },
  tab: {
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
  },
  bar: {
    alignSelf: "center",
    width: width - 40,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.light.tabIconDefault,
  },
});

export default TabBar;
