import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import Colors from "../../../constants/Colors";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Feather } from "@expo/vector-icons";

interface FloatingButtonProps {
  onPress: () => void;
  icon: string;
  bottom?: number;
  size?: number;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  onPress,
  icon,
  bottom = 0,
  size = 20,
}) => {
  const insets = useSafeAreaInsets();
  return (
    <TouchableOpacity
      onPress={() => {
        onPress();
      }}
    >
      <View
        style={[
          styles.body,
          {
            bottom: insets.bottom + bottom,
          },
        ]}
      >
        <Feather
          style={styles.icon}
          name={icon}
          size={size}
          color={Colors.light.background}
        />
      </View>
    </TouchableOpacity>
  );
};

export default FloatingButton;

const styles = StyleSheet.create({
  body: {
    position: "absolute",
    right: 10,
    height: 46,
    width: 46,
    borderRadius: 24,
    backgroundColor: Colors.light.brown,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 6,
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  icon: {
    marginLeft: 2,
  },
});
