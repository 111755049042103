import React, { FC } from "react";
import { StyleSheet, View, Text } from "react-native";
import Colors from "../../../constants/Colors";

interface MoneyItemProps {
  title: string;
  value: string;
  brown?: boolean;
}

const MoneyItem: FC<MoneyItemProps> = ({ title, value, brown = false }) => {
  return (
    <View style={styles.itemRow}>
      <Text style={[styles.itemTitle, {
        color: brown ? Colors.light.brown : 'black',
      }]}>{title}</Text>
      <Text adjustsFontSizeToFit numberOfLines={1} style={styles.itemValue}>
        {value}
      </Text>
    </View>
  );
};
export default MoneyItem;

const styles = StyleSheet.create({
  itemRow: {
    flexDirection: "row",
    borderBottomColor: "#ddd",
    paddingVertical: 2,
    padding: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemTitle: {
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  itemValue: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 6,
    color: Colors.light.brown,
  },
});
