import { StyleSheet, View, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import { Image } from "expo-image";
import { Text } from "../Themed";
import { OrderStatus } from "../../types";
import Colors from "../../constants/Colors";
import StatusPill from "../StatusPill";
import { useNavigation } from "@react-navigation/native";
import Checkbox from "../Checkbox";
import { useUpdateOrderStatus } from "../../hooks/orders/useUpdateOrderStatus";
import { Feather } from "@expo/vector-icons";

interface ListItemProps {
  orderNumber: number | null;
  orderStatus: OrderStatus;
  orderDate: string;
  orderName: string;
  orderUuid: string;
  orderNotes: string;
  eventDate: string;
  image: string;
  imageUuid: string;
  orderProductUuid: string;
  calendar?: boolean;
  actionTitle?: string;
  nextStatus?: OrderStatus;
  isImage?: boolean;
  index?: number;
}

const ListItem: React.FC<ListItemProps> = ({
  orderNumber,
  orderStatus,
  orderDate,
  orderName,
  orderNotes,
  orderUuid,
  eventDate,
  image,
  imageUuid,
  orderProductUuid,
  calendar,
  actionTitle,
  nextStatus,
  isImage = true,
  index,
}) => {
  const navigation = useNavigation();
  const [checked, setChecked] = useState(false);
  const updateOrderStatus = useUpdateOrderStatus();

  const _updateStatus = () => {
    updateOrderStatus.mutate({
      orderProductUuid: orderProductUuid,
      status: nextStatus,
    });
  };

  return (
    <View style={styles.rowBody}>
      {index === 0 ? <View style={styles.dividerTop} /> : null}

      <TouchableOpacity
        style={styles.body}
        onPress={() => {
          if (!orderUuid) {
            navigation.navigate("OrderDetails", {
              orderNumber: orderNumber,
              orderStatus: orderStatus,
              orderDate: orderDate,
              orderName: orderName,
              eventDate: eventDate,
              orderNotes: orderNotes,
              image: image,
              orderProductUuid: orderProductUuid,
              nonStatusOrderProducts: orderDetails?.nonStatusOrderProducts,
            });
          } else {
            navigation.navigate("OrderScreen", {
              orderNumber: orderNumber,
              orderStatus: orderStatus,
              orderDate: orderDate,
              orderName: orderName,
              eventDate: eventDate,
              orderNotes: orderNotes,
              image: image,
              imageUuid: imageUuid,
              orderProductUuid: orderUuid,
            });
          }
        }}
      >
        <View style={styles.container}>
          <View>
            <View style={styles.row}>
              {orderNumber ? (
                <Text style={styles.orderNumber}>{`#${orderNumber}`}</Text>
              ) : null}
              <Text style={styles.subText}>{`${
                calendar ? "" : "Event:"
              } ${eventDate}`}</Text>
            </View>
            <Text style={styles.nameText}>{orderName}</Text>
          </View>
          {image ? (
            <>
              {isImage ? (
                <Image
                  style={styles.image}
                  cachePolicy="memory"
                  priority="high"
                  source={{ uri: image, cacheKey: imageUuid }}
                  placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
                  contentFit="cover"
                  transition={100}
                />
              ) : (
                <View style={styles.image}>
                  <Feather
                    name="video"
                    size={20}
                    color={Colors.light.background}
                  />
                </View>
              )}
            </>
          ) : null}
        </View>
        <View>
          {actionTitle ? null : <StatusPill status={orderStatus} />}
          {orderStatus?.toLowerCase() === "disapproved" && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("Conversation", {
                  orderUuid: orderUuid,
                  orderNumber: orderNumber,
                  name: orderName,
                  unread: 0,
                });
              }}
              style={{ marginTop: 3 }}
            >
              <StatusPill status="viewComment" />
            </TouchableOpacity>
          )}
        </View>
      </TouchableOpacity>
      <View style={styles.actionContainer}>
        {!actionTitle ? null : (
          <Checkbox
            value={checked}
            label={actionTitle}
            onPress={() => {
              setChecked(!checked);
              _updateStatus();
            }}
          />
        )}
      </View>
      <View style={styles.divider} />
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "#fff",
    flex: 1,
  },
  container: {
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
    marginBottom: 8,
  },
  image: {
    width: 44,
    height: 44,
    borderRadius: 3,
    marginLeft: 12,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.light.clientGreen,
  },
  orderNumber: {
    fontWeight: "500",

    fontSize: 14,
  },
  subText: {
    marginLeft: 8,
  },
  nameText: {
    fontSize: 14,
    marginTop: -2,
  },
  rowBody: {
    flexDirection: "row",
    borderColor: Colors.light.background,
    borderBottomWidth: 2,
  },
  actionContainer: {
    justifyContent: "center",
  },
  divider: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    height: 1,
    width: "100%",
    backgroundColor: Colors.light.brown,
    opacity: 0.1,
  },
  dividerTop: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    height: 1,
    width: "100%",
    backgroundColor: Colors.light.brown,
    opacity: 0.1,
  },
});

export default ListItem;
