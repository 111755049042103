import {
  Dimensions,
  StyleSheet,
  View,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import React, { FC, useContext, useEffect, useRef } from "react";
import { Modalize } from "react-native-modalize";
import Colors from "../../../constants/Colors";
import Context from "../../../context";
import ModalBody from "../../../components/ModalBody";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button";
import instanceAXIOS from "../../../services/api";
import { useQueryClient } from "react-query";
import Toast from "react-native-toast-message";

const { height, width } = Dimensions.get("window");

interface UpdateOrderOwnerProps {
  orderUuid: string;
}

const NewNote: FC<UpdateOrderOwnerProps> = ({ orderUuid }) => {
  const _modalizeRef = useRef<Modalize>(null);
  const queryClient = useQueryClient();
  const { setNewNoteModal, user } = useContext(Context);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (_modalizeRef) {
      setNewNoteModal(_modalizeRef);
    }
  }, [_modalizeRef]);

  const onSubmit = (data: any) => {
    instanceAXIOS
      .post("/OrderProductNote", {
        orderProductUuid: orderUuid,
        note: data?.note,
      })
      .then((res) => {
        queryClient.invalidateQueries(["orderDetails", orderUuid]);
        Toast.show({
          type: "success",
          props: {
            message: "Note created Successfully",
          },
        });
        _modalizeRef?.current?.close();
      })
      .catch((err) => {
        Toast.show({
          type: "error",
          props: {
            message: "Unable to create note",
          },
        });
        _modalizeRef?.current?.close();
      });
  };

  return (
    <Modalize
      ref={_modalizeRef}
      modalHeight={height * 0.9}
      modalStyle={styles.modal}
      scrollViewProps={{
        showsVerticalScrollIndicator: false,
        scrollEnabled: false,
      }}
      withReactModal
    >
      <ModalBody
        header
        headerText="Add Internal Note"
        body={
          <View style={styles.container}>
            <KeyboardAvoidingView
              behavior={"height"}
              keyboardVerticalOffset={Platform.OS === "ios" ? 0 : 20}
              style={styles.keyboardAvoidingView}
              contentContainerStyle={{ flex: 1 }}
            >
              <View style={styles.buttonContainer}>
                <Input
                  name={"note"}
                  placeholder="Note"
                  control={control}
                  errors={errors}
                  required
                  multiline
                  height={height * 0.3}
                />
              </View>
              <View style={styles.buttonRow}>
                <Button label="Save" onPress={handleSubmit(onSubmit)} />
              </View>
            </KeyboardAvoidingView>
          </View>
        }
      />
    </Modalize>
  );
};

const styles = StyleSheet.create({
  modal: {
    backgroundColor: Colors.light.background,
  },
  body: {
    height: height * 0.7,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.background,
  },
  list: {
    backgroundColor: "white",
    width: "98%",
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    marginLeft: 12,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  rowContainer: {
    width: width,
    justifyContent: "flex-start",
    zIndex: 10,
  },
  rowPosition: {
    flexDirection: "row",
    marginBottom: 10,
    height: 50,
    width: width,
    justifyContent: "center",
  },
  buttonContainer: {
    width: width,
    paddingTop: 10,
    paddingHorizontal: 16,
  },
  buttonRow: {
    position: "absolute",
    top: 300,
    width: width,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default NewNote;
