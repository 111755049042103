import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrderCountsByStatus = (status: string) => {
  return useQuery(["orderCountsByStatus", status], () =>
    getOrderCountsByStatus(status)
  );
};

const getOrderCountsByStatus = async (status: string) => {
  const { data } = await instanceAXIOS.get(`/OrderProducts/Counts/${status}`);
  return data;
};
