import { StyleSheet, TouchableOpacity } from "react-native";
import React from "react";
import { Text, View } from "../Themed";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Feather } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import { useNavigation } from "@react-navigation/native";

interface HeaderProps {
  goBack?: boolean;
  title?: string;
  profile?: boolean;
  profilePage?: boolean;
  rightAction?: () => void;
  rightActionIcon?: string;
  isModal?: boolean;
  goBackAction?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  goBack: _goBack = false,
  title = "PRESSED FLORAL",
  profile = false,
  profilePage = false,
  rightAction = null,
  rightActionIcon = "edit-2",
  isModal = false,
  goBackAction = null,
}) => {
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const { goBack } = useNavigation();
  return (
    <View style={[styles.body, { paddingTop: isModal ? 0 : insets.top }]}>
      {profile ? (
        <TouchableOpacity
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          style={[
            styles.rightButton,
            {
              top: isModal ? 38 : 6 + insets.top,
            },
          ]}
          onPress={() => navigation.navigate("ProfileNavigator")}
        >
          <Feather
            name="user"
            size={24}
            color={profilePage ? Colors.light.navy : Colors.light.brown}
          />
        </TouchableOpacity>
      ) : null}
      {rightAction ? (
        <TouchableOpacity
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          style={[
            styles.rightButton,
            {
              top: isModal ? 38 : 6 + insets.top,
            },
          ]}
          onPress={() => rightAction()}
        >
          <Feather
            name={rightActionIcon}
            size={24}
            color={Colors.light.brown}
          />
        </TouchableOpacity>
      ) : null}
      <Text style={styles.titleText}>{title}</Text>
      {_goBack ? (
        <TouchableOpacity
          onPress={() => {
            goBackAction ? goBackAction() :
            goBack();
          }}
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          style={[styles.buttonLeft, { top: isModal ? 38 : 6 + insets.top }]}
        >
          <Feather name="arrow-left" size={24} color={Colors.light.brown} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    height: 100,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  rightButton: {
    position: "absolute",
    right: 20,
  },
  titleText: {
    fontSize: 20,
    fontFamily: "spectral-500",
    color: Colors.light.brown,
  },
  buttonLeft: {
    position: "absolute",
    left: 20,
  },
});

export default Header;
