import Toast from "react-native-toast-message";
import { useMutation, useQueryClient } from "react-query";
import instanceAXIOS from "../../services/api";

export type sendMessageInput = {
  formData: any;
  message: string | null;
  orderConversationUserUuid: string;
  orderUuid: string;
  attachment?: any;
  fileName?: string;
  fileExtension?: string;
  isImage?: boolean;
  name?: string;
  conversationUuid: string;
};

const sendMessage = async (_data: sendMessageInput) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await instanceAXIOS.put(
    `/OrderConversation/Message`,
    _data.formData,
    config
  );
  return res.data;
};

export const useSendMessage = () => {
  const queryClient = useQueryClient();
  return useMutation((_data: sendMessageInput) => sendMessage(_data), {
    onMutate: (_data) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["conversation", _data.conversationUuid]);
      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        "conversation",
        _data.conversationUuid,
      ]);
      if (previousMessages) {
        queryClient.setQueryData(
          ["conversation", _data.conversationUuid],
          (old: any) => {
            return {
              ...old,
              pages: [
                {
                  currentPage: old?.pages?.length + 1,
                  items: [
                    {
                      message: _data.message,
                      attachment: { blobUri: _data.attachment },
                      fileName: _data.fileName,
                      fileExtension: _data.fileExtension,
                      isImage: _data.isImage,
                      orderConversationUserUuid:
                        _data.orderConversationUserUuid,
                      orderUuid: _data.orderUuid,
                      userUuid: null,
                      firstName: _data.name,
                      dateCreated: new Date().toISOString(),
                    },
                  ],
                  itemsPerPage: 20,
                  totalItems: 0,
                  totalPages: 0,
                },
                ...old?.pages,
              ],
            };
          }
        );
      }
    },
    onSuccess: (data: any, variables) => {
      return data;
    },
    onError: (err) => {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to send message",
        },
      });
    },
  });
};

export default useSendMessage;
