import { Feather } from "@expo/vector-icons";
import { useRoute } from "@react-navigation/native";
import { useForm } from "react-hook-form";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";
import Toast from "react-native-toast-message";
import ActionButton from "../../components/ActionButton";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Input from "../../components/Input";
import ModalBody from "../../components/ModalBody";
import { Text, View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import instanceAXIOS from "../../services/api";
import { RootTabScreenProps } from "../../types";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";

const { height, width } = Dimensions.get("window");

type createNoteInput = {
  orderProductUploadUuid: string;
  isPrivate: boolean;
  note: string;
};

export default function CreateNote({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const queryClient = useQueryClient();
  const route = useRoute();
  const { orderProductUuid } = route.params;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [internal, setInternal] = React.useState(false);

  useEffect(() => {
    setValue("orderProductUploadUuid", orderProductUuid);
    setValue("isPrivate", false);
  }, [orderProductUuid]);

  const setIsPrivate = (value: boolean) => {
    setInternal(value);
    setValue("isPrivate", value);
  };

  const onSubmit = (data: createNoteInput) => {
    instanceAXIOS
      .post("/OrderProductUploadNote", {
        orderProductUploadUuid: data.orderProductUploadUuid,
        isPrivate: data.isPrivate,
        note: data.note,
      })
      .then((response) => {
        queryClient.invalidateQueries("orderDetails");
        queryClient.invalidateQueries("orderSummary");
        navigation.goBack();
        Toast.show({
          type: "success",
          props: {
            message: "Comment created successfully",
          },
        });
      });
  };

  return (
    <ModalBody
      header
      headerText="Add Comment"
      goBack
      body={
        <View style={styles.container}>
          <KeyboardAvoidingView
            behavior={"height"}
            keyboardVerticalOffset={Platform.OS === "ios" ? 90 : 20}
            style={styles.keyboardAvoidingView}
            contentContainerStyle={{ flex: 1 }}
          >
            <Text style={styles.label}>Visibilty</Text>
            <View style={styles.rowContainer}>
              <View style={styles.rowPosition}>
                <View style={{ width: 180, opacity: internal ? 1 : 0.4 }}>
                  <ActionButton
                    onPress={() => setIsPrivate(true)}
                    label="Internal Comment"
                    color={Colors.light.green}
                    icon={
                      <Feather
                        name="lock"
                        size={14}
                        color={Colors.light.background}
                      />
                    }
                  />
                </View>
                <View style={{ width: 180, opacity: !internal ? 1 : 0.4 }}>
                  <ActionButton
                    onPress={() => setIsPrivate(false)}
                    label="Client Comment"
                    color={Colors.light.red}
                    icon={
                      <Feather
                        name="eye"
                        size={14}
                        color={Colors.light.background}
                      />
                    }
                  />
                </View>
              </View>
            </View>
            <View style={styles.buttonContainer}>
              <Input
                name={"note"}
                placeholder="Note"
                control={control}
                errors={errors}
                required
                multiline
                height={height * 0.3}
              />
            </View>
            <View style={styles.buttonRow}>
              <Button label="Save" onPress={handleSubmit(onSubmit)} />
            </View>
          </KeyboardAvoidingView>
        </View>
      }
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    marginLeft: 12,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  rowContainer: {
    width: width,
    justifyContent: "flex-start",
    zIndex: 10,
  },
  rowPosition: {
    flexDirection: "row",
    marginBottom: 10,
    height: 50,
    width: width,
    justifyContent: "center",
  },
  buttonContainer: {
    width: width,
    paddingTop: 10,
    paddingHorizontal: 16,
  },
  buttonRow: {
    position: "absolute",
    bottom: 80,
    height: 80,
    marginBottom: -16,
    flex: 1,
    width: width,
    justifyContent: "center",
    alignItems: "center",
  },
});
