import { useState } from "react";
import { StyleSheet, Text } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { useGetUserTasks } from "../../hooks/tasks/useGetUserTasks";
import { RootTabScreenProps } from "../../types";
import TabBar from "./TabBar";
import TaskItem from "./TaskItem";
import React from "react";
import LoadingState from "../../components/LoadingState";

export default function ManagerTasks({ route }: RootTabScreenProps<"TabOne">) {
  const [activeTab, setActiveTab] = useState("Todo");

  const {
    data: completedData,
    isLoading: completedDataIsLoading,
    error: completedDataError,
    fetchNextPage: fetchNextPageCompleted,
  } = useGetUserTasks({
    completed: true,
    pageSize: 20,
    pageNumber: 1,
  });
  const {
    data: todoData,
    isLoading: todoDataIsLoading,
    error: todoDataError,
    fetchNextPage: fetchNextPageTodo,
  } = useGetUserTasks({
    completed: false,
    pageSize: 20,
    pageNumber: 1,
  });

  const flatData = (_data: any) => {
    const flatArray: any = [];
    _data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <Text style={styles.titlePage}>Tasks</Text>
      <View style={{ height: 40 }}>
        <TabBar
          tabs={["Todo", "Completed"]}
          onUpdateTab={(tab) => {
            setActiveTab(tab);
          }}
        />
      </View>
      {todoDataIsLoading || completedDataIsLoading ? (
        <LoadingState />
      ) : (
        <>
          {activeTab === "Todo" ? (
            <>
              {todoData?.pages[0]?.totalItems > 0 ? (
                <FlatList
                  data={flatData(todoData)}
                  renderItem={({ item }) => (
                    <TaskItem
                      id={item?.uuid}
                      task={item?.task}
                      complete={item?.dateCompleted}
                      assignedDate={item?.dateCreated}
                      dueDate={item?.dateDue}
                      createdByName={`${item?.createdByUser?.firstName} ${item?.createdByUser?.lastName}`}
                      completedByName={`${item?.completedByUser?.firstName} ${item?.completedByUser?.lastName}`}
                    />
                  )}
                  keyExtractor={(item) => item?.id?.toString()}
                  onEndReached={() => {
                    fetchNextPageTodo();
                  }}
                />
              ) : (
                <LoadingState
                  empty
                  emptyText="All tasks completed"
                  emptyIcon="check"
                />
              )}
            </>
          ) : (
            <>
              {completedData?.pages[0]?.totalItems > 0 ? (
                <FlatList
                  data={flatData(completedData)}
                  renderItem={({ item }) => (
                    <TaskItem
                      id={item?.uuid}
                      task={item?.task}
                      complete={item?.dateCompleted}
                      assignedDate={item?.dateCreated}
                      dueDate={item?.dateDue}
                      createdByName={`${item?.createdByUser?.firstName} ${item?.createdByUser?.lastName}`}
                      completedByName={`${item?.completedByUser?.firstName} ${item?.completedByUser?.lastName}`}
                    />
                  )}
                  keyExtractor={(item) => item?.id?.toString()}
                  onEndReached={() => {
                    fetchNextPageCompleted();
                  }}
                />
              ) : (
                <LoadingState
                  empty
                  emptyText="No completed tasks"
                  emptyIcon="check"
                />
              )}
            </>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
    marginBottom: 20,
  },
});
