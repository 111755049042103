import { Platform, StyleSheet, View } from "react-native";
import React, { useContext, useEffect } from "react";
import BottomBarIcon from "./BottomBarIcon";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Colors from "../../constants/Colors";
import { useNavigation } from "@react-navigation/native";
import Animated, {
  Easing,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import Context from "../../context";

interface BottomBarProps {
  isClient?: boolean;
}

const BottomBar: React.FC<BottomBarProps> = ({ isClient = false }) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const [tab, setTab] = React.useState(0);
  const { tabVisible, unreadCount } = useContext(Context);
  const offset = useSharedValue(0);

  useEffect(() => {
    if (tabVisible) {
      offset.value = withTiming(0, {
        duration: 160,
        easing: Easing.bezier(0.33, 0.01, 0, 1),
      });
    } else {
      offset.value = withTiming(100, {
        duration: 250,
        easing: Easing.inOut(Easing.ease),
      });
    }
  }, [tabVisible]);

  const animatedBottomBarStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: interpolate(offset.value, [0, 100], [0, 100]),
        },
      ],
    };
  });

  return (
    <Animated.View
      style={[
        styles.body,
        animatedBottomBarStyle,
        {
          backgroundColor: Colors.light.background,
          paddingBottom: Platform.OS === "web" ? 20 : insets.bottom,
        },
      ]}
    >
      {!isClient ? (
        <>
          <BottomBarIcon
            name=""
            icon="package"
            color={Colors.light.navy}
            active={tab === 0}
            onPress={() => {
              if (tab === 0) {
                navigation.reset({
                  index: 0,
                  routes: [{ name: "OrdersNavigator" }],
                });
              } else {
                navigation.navigate("OrdersNavigator");
              }
              setTab(0);
            }}
          />
          <BottomBarIcon
            name=""
            icon="check-square"
            color={Colors.light.navy}
            active={tab === 1}
            onPress={() => {
              setTab(1);
              navigation.navigate("TaskNavigator");
            }}
          />
          <BottomBarIcon
            name=""
            icon="heart"
            color={Colors.light.navy}
            active={tab === 2}
            onPress={() => {
              setTab(2);
              navigation.navigate("MyFrames");
            }}
          />
          <BottomBarIcon
            name=""
            icon="message-square"
            color={Colors.light.navy}
            active={tab === 3}
            onPress={() => {
              setTab(3);
              navigation.navigate("ChatNav");
            }}
            unreadCount={unreadCount}
          />
          <BottomBarIcon
            name=""
            icon="mail"
            color={Colors.light.navy}
            active={tab === 4}
            onPress={() => {
              setTab(4);
              navigation.navigate("EmailNavigator");
            }}
          />
          <BottomBarIcon
            name=""
            icon="calendar"
            color={Colors.light.navy}
            active={tab === 5}
            onPress={() => {
              setTab(5);
              navigation.navigate("CalendarNavigator");
            }}
          />
        </>
      ) : (
        <>
          <BottomBarIcon
            name=""
            icon="package"
            color={Colors.light.navy}
            active={tab === 0}
            onPress={() => {
              setTab(0);
              navigation.navigate("ClientNavigator");
            }}
          />
          <BottomBarIcon
            name=""
            icon="message-square"
            color={Colors.light.navy}
            active={tab === 1}
            onPress={() => {
              setTab(1);
              navigation.navigate("ClientChatNavigator");
            }}
            unreadCount={unreadCount}
          />
        </>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  body: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  },
});

export default BottomBar;
