import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetUsersByRole = (
  pageNumber: number,
  pageSize: number,
  roles: string[],
  key: string
) => {
  const { isLoading, data, refetch, isFetched } = useQuery(key, () =>
    getUsersByRole(pageNumber, pageSize, roles)
  );
  return { isLoading, data, refetch, isFetched };
};

const getUsersByRole = async (
  pageNumber: number,
  pageSize: number,
  roles: string[]
) => {
  const { data } = await instanceAXIOS.post(`/User/WithRole`, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    roles: roles,
  });
  return data;
};
