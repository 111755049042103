import React, { useContext, useEffect } from "react";
import { StyleSheet, FlatList, TouchableOpacity } from "react-native";
import Header from "../../components/Header";
import { View, Text } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import Context from "../../context";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";

export default function Exports({ navigation }: RootTabScreenProps<"TabOne">) {
  const { setTabVisible } = useContext(Context);

  useEffect(() => {
    setTabVisible(false);
    return () => {
      setTabVisible(true);
    };
  }, []);

  const EXPORTS = [
    {
      name: "Order Product Export",
      screen: "ExportOptions",
    },
  ];

  return (
    <View style={styles.container} testID="ID_EXPORTS_VIEW">
      <Header goBack />
      <FlatList
        style={styles.flatList}
        data={EXPORTS}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => navigation.navigate(item.screen)}  
            style={styles.exportItem}
          >
            <View style={styles.exportRow}>
              <Text style={styles.exportItemText}>{item.name}</Text>
              <Feather
                name="chevron-right"
                size={20}
                color={Colors.light.brown}
              />
            </View>
            <View style={styles.exportItemBorder} />
          </TouchableOpacity>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  flatList: {
    width: "100%",
  },
  exportItem: {
    width: "100%",
  },
  exportRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 18,
    paddingVertical: 20,
  },
  exportItemText: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  exportItemBorder: {
    width: "100%",
    height: 1,
    opacity: 0.2,
    backgroundColor: Colors.light.brown,
  },
});
