import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { getTimeOrDate } from "../../../services/utils";
import { useNavigation } from "@react-navigation/native";

interface EmailListItemProps {
  email: any;
  accountEmail: string;
}

const EmailListItem: React.FC<EmailListItemProps> = ({
  email,
  accountEmail,
}) => {
  const navigaiton = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        navigaiton.navigate("ReadEmailMessage", {
          emailId: email?.id,
          emailToImpersonate: email?.accountEmail,
        });
      }}
      style={styles.container}
    >
      <View style={styles.subjectRow}>
        <Text numberOfLines={1} style={styles.sender}>
          {email.from}
        </Text>
        <Text style={styles.date}>{getTimeOrDate(email.date)}</Text>
      </View>
      <View style={styles.contentRow}>
        <Text numberOfLines={1} style={styles.subject}>
          {email.subject}
        </Text>
        <Text numberOfLines={2} style={styles.snippet}>
          {email.snippet}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    backgroundColor: "#fff",
  },
  subjectRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 3,
  },
  contentRow: {
    marginTop: 5,
    flexDirection: "column",
  },
  sender: {
    fontSize: 15,
    maxWidth: "80%",
    fontWeight: "bold",
    color: "#000",
  },
  date: {
    fontSize: 10,
    color: "#666",
  },
  subject: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 2,
    color: "#000",
  },
  snippet: {
    fontSize: 12,
    color: "#444",
  },
});

export default EmailListItem;
