import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetDashboardStates = () => {
  return useQuery(["dashboardStats"], () => getDashboardStats());
};

const getDashboardStats = async () => {
  const offset = new Date().getTimezoneOffset();
  const { data } = await instanceAXIOS.get(`/Stats/UserDashboard/${-offset}`);
  return data;
};
