import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetOrderPhotoArchive = (orderUuid: string) => {
  return useQuery(
    ["orderPhotoArchive", orderUuid],
    () => getOrderPhotoArchive(orderUuid),
    {
      cacheTime: 240000,
    }
  );
};

const getOrderPhotoArchive = async (orderUuid: string) => {
  try {
    const { data } = await instanceAXIOS.get(`/Orders/Uploads/${orderUuid}`);
    return data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return null;
  }
  return data;
};
