import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  FlatList,
  Alert,
  Pressable,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import Header from "../../components/Header";
import { ScrollView } from "react-native-gesture-handler";
import Colors from "../../constants/Colors";
import Button from "../../components/Button";
import DetailItem from "./DetailItem";
import OrderProductQuickDetails from "./OrderProductQuickDetails";
import UploadFrame from "../../components/UploadFrame";
import Toast from "react-native-toast-message";
import MoneyItem from "./MoneyItem";
import { Feather } from "@expo/vector-icons";
import { Modalize } from "react-native-modalize";
import TagModal from "../../components/TagModal";
import FlashList from "../../components/FlashList";
import DetailItemExpand from "./DetailItemExpand";
import { useGetOrder } from "../../hooks/orders/useGetOrder";
import instanceAXIOS from "../../services/api";
import moment from "moment";
import * as Clipboard from "expo-clipboard";
import { useNavigation } from "@react-navigation/native";
import { capitalizeFirstLetter, formatEventDate } from "../../services/utils";
const { width } = Dimensions.get("window");

export default function OrderDetails({ route }) {
  const { navigate } = useNavigation();
  const { orderProductUuid } = route.params;
  const [isEmailModal, setIsEmailModal] = React.useState(false);
  const [orderProductUuidForUpload, setOrderProductUuidForUpload] =
    React.useState(null);
  // const { data, refetch, isFetched, isLoading, error } =
  //   useGetOrderDetails(orderProductUuid);

  const _modalizeRef = React.useRef<Modalize>(null);
  const _opModalizeRef = React.useRef<Modalize>(null);

  const {
    data: orderDetails,
    refetch,
    isLoading,
    isFetched,
  } = useGetOrder(orderProductUuid);

  const removeTag = (tag) => {
    Alert.alert(
      "Remove Tag",
      `Are you sure you want to remove the tag: ${tag.tagName}?`,
      [
        {
          text: "Cancel",
          onPress: () => {},
        },
        {
          text: "Remove",
          onPress: () => {
            instanceAXIOS.delete(`/Orders/Tag/${tag.uuid}`).then((res) => {
              refetch();
            });
          },
        },
      ]
    );
  };

  const _checkRegistrationStatus = () => {
    const data = { ...orderDetails };
    data.email = orderDetails.clientEmail.toLowerCase();
    instanceAXIOS
      .post("/Authentication/CheckRegistrationStatus", data)
      .then((res) => {
        if (res?.data === true) {
          Toast.show({
            type: "success",
            props: {
              message: "User has registered",
            },
          });
        } else {
          Toast.show({
            type: "error",
            props: {
              message: "User has not registered",
            },
          });
        }
      })
      .catch((err) => {
        Toast.show({
          type: "error",
          props: {
            message: "User has not registered",
          },
        });
      });
  };

  const _sendCode = async () => {
    //send user the registration code
    const data = { ...orderDetails };
    data.email = data.clientEmail.toLowerCase();

    instanceAXIOS
      .post(
        `/Authentication/SendNewTwoFactorRegistrationCode/${data?.clientUserUuid}`,
        {}
      )
      .then((res) => {
        if (res?.data) {
          let _code = res?.data?.code;
          Alert.alert(
            "Code sent",
            `Registration code: ${res?.data} sent to ${data.email}`,
            [
              {
                text: "OK",
                onPress: () => {},
              },
            ]
          );
        } else {
          Alert.alert(
            "Already Registered",
            `User already registered with email: ${data.email}`,
            [
              {
                text: "OK",
                onPress: () => {},
              },
            ]
          );
        }
      })
      .catch((err) => {
        Alert.alert(
          "Already Registered",
          `User already registered with email: ${data.email}`,
          [
            {
              text: "OK",
              onPress: () => {},
            },
          ]
        );
      });
  };

  const sendEmail = (item) => {
    _opModalizeRef?.current?.close();
    setTimeout(() => {
      navigate("BulkEmail", {
        person: {
          firstName: orderDetails?.clientFirstName,
          lastName: orderDetails?.clientLastName,
        },
        orderProduct: { uuid: item?.uuid },
      });
    }, 300);
  };

  const _copyEmail = async () => {
    const data = { ...orderDetails };
    await Clipboard.setStringAsync(data?.clientEmail);
    Toast.show({
      type: "success",
      props: {
        message: "Email copied to clipboard",
      },
    });
  };

  const _updateEmail = async () => {
    const data = { ...orderDetails };
    data.email = data.clientEmail.toLowerCase();
    navigate("UpdateEmail", {
      userEmail: data.email,
      userUuid: data?.clientUserUuid,
    });
  };

  if (isLoading && !isFetched)
    return (
      <View style={styles.container}>
        <Header goBack />
        <View style={styles.loadingContainer}>
          <ActivityIndicator color={Colors.light.brown} />
        </View>
      </View>
    );

  return (
    <View style={styles.container}>
      <Header goBack />
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
        contentContainerStyle={styles.containerStyle}
      >
        <DetailItem
          title={"Order Number:"}
          value={`#${orderDetails?.shopifyOrderNumber}`}
        />
        <DetailItem
          title={"Order Name:"}
          value={
            orderDetails?.clientFirstName + " " + orderDetails?.clientLastName
          }
        />
        <TouchableOpacity onPress={() => _copyEmail()} style={styles.row}>
          <DetailItem
            title={"Order Email:"}
            value={orderDetails?.clientEmail}
            underline
          />
        </TouchableOpacity>
        <DetailItem
          title={"Order Date:"}
          value={moment(orderDetails?.orderDate).format("MM/DD/YY")}
        />
        <DetailItem
          title={"Event Date:"}
          value={formatEventDate(orderDetails?.eventDate)}
        />
        <DetailItemExpand
          title={"Shipping Address:"}
          value={[
            orderDetails?.orderShippingAddress1,
            orderDetails?.orderShippingAddress2,
            `${orderDetails?.orderShippingCity}, ${orderDetails?.orderShippingProvince}`,
            orderDetails?.orderShippingZip,
          ]}
        />
        <View style={styles.tagRow}>
          <Text style={styles.title}>Tags:</Text>
          <TouchableOpacity onPress={() => _modalizeRef?.current?.open()}>
            <Feather name="plus" size={24} color="black" />
          </TouchableOpacity>
        </View>
        <View>
          {orderDetails?.tags?.length > 0 ? (
            <FlashList
              data={orderDetails?.tags}
              renderItem={({ item }) => (
                <TouchableOpacity
                  onPress={() => {
                    removeTag(item);
                  }}
                >
                  <View style={styles.tagBody}>
                    <Feather name="x" size={14} color="black" />
                    <Text style={styles.tagTitle}>{item?.tagName}</Text>
                  </View>
                </TouchableOpacity>
              )}
              estimatedItemSize={50}
              contentContainerStyle={{
                paddingHorizontal: 20,
              }}
              ListEmptyComponent={() => <Text>No tags</Text>}
              horizontal
            />
          ) : (
            <Text style={styles.noTags}>No tags</Text>
          )}
        </View>
        <DetailItem
          onPress={() => {
            navigate("AddNote", {
              note: orderDetails?.note,
              orderUuid: orderDetails?.orderUuid,
            });
          }}
          title={"Shopify Note:"}
          value={""}
        />
        {orderDetails?.note && (
          <Text style={styles.note}>{orderDetails?.note}</Text>
        )}
        <DetailItem title={"Notes: (from customer)"} value={""} />
        {orderDetails?.orderProductNotes &&
          orderDetails?.orderProductNotes?.map((note, index) => (
            <Text key={index} style={styles.note}>
              {note}
            </Text>
          ))}
        <DetailItem title={"Socials:"} value={orderDetails?.socialMediaLinks} />
        {/* <View style={styles.buttonContainer}>
          <Button
            backgroundColor={Colors.light.messageBlue}
            label={"Direct Message"}
            onPress={() => {
              navigate("Conversation", {
                name: `${orderDetails?.clientFirstName} ${orderDetails?.clientLastName}`,
                orderNumber: orderDetails?.shopifyOrderNumber,
                orderUuid: orderDetails?.orderUuid,
              });
            }}
          />
        </View> */}
        <View style={styles.space20} />
        <Text
          style={styles.title}
        >{`All custom products in order: #${orderDetails?.shopifyOrderNumber}`}</Text>
        <FlatList
          data={orderDetails?.orderProducts}
          renderItem={({ item }) => (
            <OrderProductQuickDetails
              product={item.productTitle}
              varient={item.variantOption1}
              varient2={item.variantOption2}
              varient3={item.variantOption3}
              quantity={item.quantity}
              status={item.status}
              onPress={() => {
                navigate("OrderDetails", {
                  orderNumber: orderDetails?.shopifyOrderNumber,
                  eventDate: orderDetails?.eventDate,
                  orderStatus: item?.status,
                  orderDate: item?.orderDate,
                  orderProductUuid: item?.uuid,
                  nonStatusOrderProducts: orderDetails?.nonStatusOrderProducts,
                });
              }}
            />
          )}
        />
        <View style={styles.space20} />
        <Text
          style={styles.title}
        >{`Other products in order: #${orderDetails?.shopifyOrderNumber}`}</Text>
        <FlatList
          data={orderDetails?.nonStatusOrderProducts}
          renderItem={({ item }) => (
            <View key={item?.uuid} style={styles.items}>
              <Text style={styles.itemTitles}>
                {`${item?.productTitle} - ${item?.variantTitle} `}
              </Text>
              <Text style={styles.itemTitles}>
                {`Quantity: ${item?.quantity}`}
              </Text>
            </View>
          )}
        />

        <View style={styles.space20} />

        <View style={styles.buttonContainer}>
          <Button
            backgroundColor={Colors.light.brown}
            label={"Upload Internal"}
            onPress={() => {
              setIsEmailModal(false);
              _opModalizeRef?.current?.open();
            }}
            icon={<Feather name="camera" size={24} color="white" />}
          />
          <UploadFrame
            onRefresh={() => {
              refetch();
              Toast.show({
                type: "success",
                props: {
                  message: "Refreshing",
                },
              });
            }}
            name="Client Event Photos"
            uploads={orderDetails?.clientOrderProductUploads}
            orderProductUuid={orderDetails?.orderProducts[0]?.uuid}
            imageUploadType="Client"
            hideUpload
            hideComments
          />
          <Button
            backgroundColor={Colors.light.brown}
            label={"Upload client event photos"}
            onPress={() => {
              setIsEmailModal(false);
              _opModalizeRef?.current?.open();
            }}
            icon={<Feather name="camera" size={24} color="white" />}
          />
          <Button
            backgroundColor={Colors.light.brown}
            label={"Photo Archive"}
            onPress={() => {
              navigate("PhotoArchive", {
                orderUuid: orderDetails?.orderUuid,
              });
            }}
          />
        </View>
        <View style={styles.space20} />
        <MoneyItem
          title={"Payment status"}
          value={
            orderDetails?.orderPayment?.financialStatus
              ? capitalizeFirstLetter(
                  orderDetails?.orderPayment?.financialStatus
                )
              : ""
          }
        />
        <MoneyItem title={"Subtotal"} value={``} />
        <MoneyItem
          brown
          title={`${orderDetails?.orderProducts.length} items`}
          value={
            orderDetails?.orderPayment?.subtotalPrice !== undefined
              ? `$${orderDetails?.orderPayment?.subtotalPrice}`
              : "N/A"
          }
        />
        <MoneyItem title={"Discount"} value={""} />
        <FlatList
          data={orderDetails?.orderPayment?.discounts}
          renderItem={({ item }) => (
            <MoneyItem brown title={item?.code} value={`-$${item.amount}`} />
          )}
        />
        <MoneyItem
          title={"Shipping"}
          value={
            orderDetails?.orderPayment?.shippingPrice !== undefined
              ? `$${orderDetails?.orderPayment?.shippingPrice}`
              : "N/A"
          }
        />
        <MoneyItem
          title={"Tax"}
          value={
            orderDetails?.orderPayment?.tax !== undefined
              ? `$${orderDetails?.orderPayment?.tax}`
              : "N/A"
          }
        />
        <MoneyItem
          title={"Total"}
          value={
            orderDetails?.orderPayment?.totalPrice !== undefined
              ? `$${orderDetails?.orderPayment?.totalPrice}`
              : "N/A"
          }
        />
        <MoneyItem
          title={"Payed by customer"}
          value={
            orderDetails?.orderPayment?.totalPaidByClient !== undefined
              ? `$${orderDetails?.orderPayment?.totalPaidByClient}`
              : "N/A"
          }
        />
        <View style={styles.space20} />

        <View style={styles.buttonContainer}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Send email from template"}
            onPress={() => {
              setIsEmailModal(true);
              _opModalizeRef?.current?.open();
            }}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Check if user has registered"}
            onPress={_checkRegistrationStatus}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Send registration code"}
            onPress={_sendCode}
          />
        </View>
        <View style={styles.buttonPadding}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Update email"}
            onPress={() => {
              _updateEmail();
            }}
          />
        </View>
      </ScrollView>
      <Modalize
        ref={_modalizeRef}
        modalHeight={500}
        modalStyle={styles.modal}
        scrollViewProps={{
          showsVerticalScrollIndicator: false,
          scrollEnabled: false,
        }}
        withReactModal
      >
        <TagModal
          orderTags={orderDetails?.tags}
          modalizeRef={_modalizeRef}
          updateOrder={refetch}
          orderUuid={orderDetails?.orderUuid}
          removeTag={removeTag}
        />
      </Modalize>
      <Modalize
        ref={_opModalizeRef}
        onClose={() => {
          setOrderProductUuidForUpload(null);
        }}
        modalHeight={500}
        modalStyle={styles.modal}
        scrollViewProps={{
          showsVerticalScrollIndicator: false,
          scrollEnabled: false,
        }}
        withReactModal
        HeaderComponent={
          <View style={[styles.itemRow, styles.modalHeader]}>
            <Text style={styles.itemTitle}>
              {orderProductUuidForUpload
                ? "Upload Photos"
                : "You Must Select An Order Product"}
            </Text>
          </View>
        }
      >
        {!orderProductUuidForUpload ? (
          <FlatList
            data={orderDetails?.orderProducts}
            renderItem={({ item }) => (
              <Pressable
                onPress={() => {
                  if (isEmailModal) {
                    sendEmail(item);
                  } else {
                    setOrderProductUuidForUpload(item?.uuid);
                  }
                }}
                style={styles.orderProduct}
              >
                <Text style={styles.orderProductText}>
                  Product: {item?.productTitle}
                </Text>
                <Text style={styles.orderProductText}>
                  {item?.variantOption1}
                </Text>
              </Pressable>
            )}
          />
        ) : (
          <UploadFrame
            onRefresh={() => {
              refetch();
              Toast.show({
                type: "success",
                props: {
                  message: "Refreshing",
                },
              });
            }}
            hidePhotos
            name="Client Event Photos"
            uploads={orderDetails?.clientOrderProductUploads}
            orderProductUuid={orderDetails?.orderProducts[0]?.uuid}
            imageUploadType="Client"
            hideComments
          />
        )}
      </Modalize>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  items: {
    borderWidth: 1,
    borderColor: Colors.light.brown,
    padding: 20,
    alignItems: "flex-start",
    backgroundColor: Colors.light.lightBrownBackground,
    marginHorizontal: 20,
    borderRadius: 10,
    paddingVertical: 10,
    marginBottom: 10,
  },
  containerStyle: {
    paddingVertical: 20,
    paddingBottom: 120,
  },
  itemRow: {
    flexDirection: "row",
    borderBottomColor: "#ddd",
    paddingVertical: 2,
    padding: 20,
    alignItems: "center",
  },
  itemTitles: {
    fontFamily: "spectral-600",
    fontSize: 15,
  },
  itemTitle: {
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  itemValue: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 6,
    color: Colors.light.brown,
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  title: {
    fontFamily: "spectral-600",
    fontSize: 16,
    textAlign: "left",
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  space20: {
    height: 20,
  },
  tagRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 20,
  },
  modal: {
    backgroundColor: Colors.light.background,
  },
  tagBody: {
    flexDirection: "row",
    alignItems: "center",
    padding: 6,
    backgroundColor: "#F0EBE7",
    margin: 3,
    borderRadius: 5,
    justifyContent: "center",
  },
  tagTitle: {
    fontFamily: "spectral-600",
    fontSize: 10,
    textAlign: "left",
    marginLeft: 4,
  },
  noTags: {
    fontFamily: "spectral-600",
    fontSize: 16,
    textAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  modalHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingTop: 8,
    paddingBottom: 8,
  },
  orderProduct: {
    backgroundColor: Colors.light.navy,
    margin: 8,
    padding: 12,
    borderRadius: 8,
    justifyContent: "space-between",
  },
  orderProductText: {
    color: "white",
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  buttonPadding: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  note: {
    fontFamily: "spectral-600",
    fontSize: 14,
    textAlign: "left",
    paddingHorizontal: 20,
    marginBottom: 10,
    color: Colors.light.brown,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 100,
  },
});
