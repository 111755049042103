import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetProductsForOrder = (uuid: string) => {
  return useQuery(["orderProducts", uuid], () => getProductsForOrder(uuid), {
    cacheTime: 240000,
  });
};

const getProductsForOrder = async (uuid: string) => {
  const { data } = await instanceAXIOS.get(`/OrderProducts/ForOrder/${uuid}`);
  return data;
};
