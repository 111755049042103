import React, { FC, useEffect, useState } from "react";
import { StyleSheet, View, Alert } from "react-native";
import { useGetUser } from "../../hooks/users/useGetUser";
import Header from "../../components/Header";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import DropDownNew from "../../components/DropdownNew";
import { useGetUsersByRole } from "../../hooks/users/useGetUsersByRole";
import ActionButton from "../../components/ActionButton";
import { Feather } from "@expo/vector-icons";
import instanceAXIOS from "../../services/api";
import { useQueryClient } from "react-query";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";

interface EditUserProps {}

const EditUser: FC<EditUserProps> = ({ route }) => {
  const { uuid } = route.params;
  const queryClient = useQueryClient();
  const { data: user, isFetched } = useGetUser(uuid);
  const [previousManager, setPreviousManager] = useState("");
  const {
    data: managers,
    isFetched: managersFetched,
    refetch: fetchManagers,
  } = useGetUsersByRole(1, 50, ["Manager", "Admin"], "managers");
  const [theManagers, setTheManagers] = useState([]);
  const { goBack } = useNavigation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("firstName", user?.firstName);
    setValue("lastName", user?.lastName);
    setValue("email", user?.email);
    setValue("location", user?.location);
    setValue("manager", user?.assignedToUser?.uuid);
    setPreviousManager(user?.assignedToUser?.uuid);
  }, [isFetched]);

  useEffect(() => {
    if (managersFetched && user) {
      setTheManagers(
        managers?.items?.map((_user) => ({
          label: `${_user.firstName} ${_user.lastName}`,
          value: _user.uuid,
        }))
      );
      setValue("manager", user?.assignedToUser?.uuid);
    }
  }, [managersFetched, user]);

  const saveUser = (data) => {
    instanceAXIOS
      .post(`/User/UpdateUser`, {
        uuid: uuid,
        firstName: data?.firstName,
        lastName: data?.lastName,
        location: data?.location,
      })
      .then(() => {
        queryClient.invalidateQueries("assignedUsers");
        queryClient.invalidateQueries("getUser");
        queryClient.invalidateQueries("getUsersByRole");
        if (previousManager !== data?.manager) {
          assignUser(data.manager);
        } else {
      
          Toast.show({
            type: "success",
            props: {
              message: "User has been updated",
            },
          });
          goBack();

        }
      })
      .catch((error) => {
        console.log(error);
        Toast.show({
          type: "error",
          props: {
            message: "User has not been updated",
          },
        });
        goBack();
      });
  };

  const assignUser = async (assignedToUserUuid: string) => {
    instanceAXIOS
      .post(`/AssignedUser`, {
        userUuid: uuid,
        assignedToUserUuid: assignedToUserUuid,
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ["assignedUsers"],
        });
        Toast.show({
          type: "success",
          props: {
            message: "User has been assigned",
          },
        });
        goBack();
      });
  };

  const deleteUser = (data) => {
    instanceAXIOS
      .delete(`/User/${uuid}`)
      .then(() => {
        Toast.show({
          type: "success",
          props: {
            message: "User has been deleted",
          },
        });
        queryClient.invalidateQueries("assignedUsers");
        queryClient.invalidateQueries("getUser");
        queryClient.invalidateQueries("getUsersByRole");

        goBack();
      })
      .catch((e) => {
        Toast.show({
          type: "error",
          props: {
            message: "Cannot delete user",
          },
        });
        goBack();
      });
  };

  const unassignUser = (data) => {
    instanceAXIOS
      .delete(`/AssignedUser/${uuid}/${user?.assignedToUser?.uuid}`)
      .then(() => {
        queryClient.invalidateQueries("assignedUsers");
        queryClient.invalidateQueries("getUser");
        queryClient.invalidateQueries("getUsersByRole");
        Toast.show({
          type: "success",
          props: { message: "User has been unassigned" },
        });
        goBack();
      });
  };

  return (
    <>
      <Header
        isModal
        goBack
        title={user?.firstName ? `${user?.firstName} ${user?.lastName}` : ""}
      />
      <View style={styles.body}>
        <Input
          name={"firstName"}
          placeholder="First Name"
          control={control}
          errors={errors}
          // onBlur={saveFirstName}
        />
        <Input
          name={"lastName"}
          placeholder="Last Name"
          control={control}
          errors={errors}
          // onBlur={saveFirstName}
        />
        <Input
          name={"email"}
          placeholder="Email"
          control={control}
          errors={errors}
          // onBlur={saveEmail}
        />
        <View
          style={{
            zIndex: 1000,
          }}
        >
          <DropDownNew
            required
            name="location"
            label="Location"
            control={control}
            errors={errors}
            items={[
              { label: "Utah", value: "utah" },
              { label: "Georgia", value: "georgia" },
            ]}
          />
        </View>
        <View style={{ height: 10 }} />
        {theManagers?.length > 0 && user?.role !== "admin" && (
          <DropDownNew
            name="manager"
            label="Manager"
            control={control}
            errors={errors}
            items={theManagers}
          />
        )}
      </View>
      <View style={styles.buttonContainer}>
        <ActionButton
          icon={<Feather name="save" size={20} color="#fff" />}
          onPress={() => {
            handleSubmit(saveUser)();
          }}
          label={"Save"}
        />
        <ActionButton
          icon={<Feather name="trash-2" size={20} color="#fff" />}
          onPress={() =>
            Alert.alert("Unassign", "Are you sure?", [
              {
                text: "Cancel",
                style: "cancel",
              },
              {
                text: "OK",
                onPress: () => {
                  handleSubmit(unassignUser)();
                },
              },
            ])
          }
          label={"Unassign"}
        />
        <ActionButton
          icon={<Feather name="trash-2" size={20} color="#fff" />}
          onPress={() =>
            Alert.alert("Delete User", "Are you sure?", [
              {
                text: "Cancel",
                style: "cancel",
              },
              {
                text: "OK",
                onPress: () => {
                  handleSubmit(deleteUser)();
                },
              },
            ])
          }
          label={"Delete"}
        />
      </View>
    </>
  );
};
export default EditUser;

const styles = StyleSheet.create({
  body: {
    backgroundColor: "#fff",
    paddingHorizontal: 20,
    flex: 1,
  },
  buttonContainer: {
    flexDirection: "row",
    position: "absolute",
    paddingHorizontal: 20,
    bottom: 30,
    left: 0,
    right: 0,
  },
});
