import { Feather } from "@expo/vector-icons";
import React, { useContext } from "react";
import {
  Dimensions,
  FlatList,
  Linking,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
} from "react-native";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import UploadFrame from "../../components/UploadFrame";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import { useGetOrderDetails } from "../../hooks/orders/useGetOrderDetails";
import UpdateOrderOwner from "./UpdateOrderOwner";
import Context from "../../context";
import UpdateOrderStatus from "./UpdateOrderStatus";
import { useNavigation } from "@react-navigation/native";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import NewNote from "./NewNote";
import OrderProductQuickDetails from "../OrderScreen/OrderProductQuickDetails";
import StatusPill from "../../components/StatusPill";
import StatusHistory from "./StatusHistory";
import { capitalizeFirstLetter, formatDate } from "../../services/utils";
import * as Clipboard from "expo-clipboard";
const { width } = Dimensions.get("window");
import { navigate } from "../../navigation/RootNavigation";
export default function OrderDetails({ route }: RootTabScreenProps<"TabOne">) {
  const { orderNumber, orderStatus, orderProductUuid, nonStatusOrderProducts } =
    route.params;
  const navigation = useNavigation();
  const { assignedToModal, newNoteModal, user } = useContext(Context);
  const updateStatusModal = React.useRef(null);
  const { data, refetch, isFetched, isLoading } =
    useGetOrderDetails(orderProductUuid);

  const Detail = ({
    title,
    value,
    underline = false,
    edit = false,
    onPress = null,
    pill = false,
  }) => {
    return (
      <View style={styles.row}>
        <Text style={styles.metaDataTitle}>{`${title}: `}</Text>
        {pill ? (
          <Pressable disabled={!onPress} onPress={() => onPress()}>
            <StatusPill status={value} />
          </Pressable>
        ) : (
          <Pressable
            disabled={!onPress}
            onPress={() => onPress()}
            style={styles.detailPressable}
          >
            <Text
              style={[
                styles.metaData,
                {
                  textDecorationLine: underline ? "underline" : "none",
                },
              ]}
            >
              {value}
            </Text>
            {edit && (
              <Feather
                name="edit-2"
                size={14}
                color={Colors.light.brown}
                style={styles.icon}
              />
            )}
          </Pressable>
        )}
      </View>
    );
  };

  const _copyEmail = async () => {
    await Clipboard.setStringAsync(data?.clientEmail);
    Toast.show({
      type: "success",
      props: {
        message: "Email copied to clipboard",
      },
    });
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!isFetched ? false : isLoading}
            onRefresh={() => {
              refetch();
            }}
          />
        }
        style={styles.pageContainer}
        contentContainerStyle={styles.contentContainer}
      >
        <Detail
          title="Order Number"
          value={`#${data?.shopifyOrderNumber || orderNumber}`}
        />
        <Detail
          title="Order Name"
          value={`${capitalizeFirstLetter(
            data?.clientFirstName
          )} ${capitalizeFirstLetter(data?.clientLastName)}`}
        />
        <Detail
          title="Order Email"
          value={data?.clientEmail}
          onPress={_copyEmail}
          underline
        />
        <Detail title="Event Date" value={`${formatDate(data?.eventDate)}`} />
        <Detail
          title="Status"
          value={data?.status || orderStatus}
          pill
          onPress={() => updateStatusModal?.current?.open()}
        />
        <Detail
          title="Assigned To"
          value={`${
            capitalizeFirstLetter(data?.assignedToUserFirstName) || ""
          } ${capitalizeFirstLetter(data?.assignedToUserLastName) || ""}`}
          edit
          onPress={() => assignedToModal.current?.open()}
        />
        <View style={styles.space} />
        <View style={styles.detailsContainer}>
          <Text style={[styles.metaDataTitle, { paddingLeft: 24 }]}>
            Product:
          </Text>
          <OrderProductQuickDetails
            product={data?.productTitle}
            varient={data?.variantOption1}
            varient2={data?.variantOption2}
            quantity={data?.quantity}
            status={data?.status}
          />
        </View>
        <View style={styles.detailsContainer}>
          <Text style={[styles.metaDataTitle, { paddingLeft: 24 }]}>
            Other Items in order:
          </Text>
          <FlatList
            data={nonStatusOrderProducts}
            renderItem={({ item }) => (
              <OrderProductQuickDetails
                product={item.productTitle}
                // varient={item.variantOption1}
                // varient2={item.variantOption2}
                quantity={item.quantity}
                status={item.status}
                short
              />
            )}
            keyExtractor={(item) => item.orderProductUuid}
          />
        </View>
        <View style={styles.space} />
        <View style={[styles.detailsContainer, { alignItems: "center" }]}>
          <Button
            backgroundColor={Colors.light.messageBlue}
            label={"Direct Message"}
            onPress={() => {
              navigate("Conversation", {
                name: `${data?.clientFirstName} ${data?.clientLastName}`,
                orderNumber: data?.shopifyOrderNumber,
                orderUuid: data?.orderUuid,
              });
            }}
          />
        </View>
        <View style={styles.buttonPadding}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Send Email From Template"}
            onPress={() => {
              navigation.navigate("BulkEmail", {
                person: {
                  firstName: data?.clientFirstName,
                  lastName: data?.clientLastName,
                },
                orderProduct: { uuid: orderProductUuid },
              });
            }}
          />
        </View>
        <View style={styles.detailsContainer}>
          <StatusHistory statusHistory={null} />
          <UploadFrame
            onRefresh={() => {
              refetch();
              Toast.show({
                type: "success",
                props: {
                  message: "Refreshing",
                },
              });
            }}
            name="Upload Bouquet"
            uploads={data?.orderProductUploads.filter(
              (upload) => upload.uploadType === "bouquet"
            )}
            orderProductUuid={orderProductUuid}
            imageUploadType="Bouquet"
          />
          <UploadFrame
            onRefresh={() => {
              refetch();
              Toast.show({
                type: "success",
                props: {
                  message: "Refreshing",
                },
              });
            }}
            name="Upload Frame"
            uploads={data?.orderProductUploads.filter(
              (upload) => upload.uploadType === "frame"
            )}
            orderProductUuid={orderProductUuid}
            imageUploadType="Frame"
            disabled={
              data?.orderProductUploads.filter(
                (upload) => upload.uploadType === "bouquet"
              )?.length === 0
            }
            disabledMessage="Please upload a bouquet first"
          />
        </View>
      </ScrollView>
      <UpdateOrderOwner
        orderUuid={orderProductUuid}
        currentOwner={data?.assignedToUserUuid}
      />
      <UpdateOrderStatus
        modalRef={updateStatusModal}
        currentStatus={orderStatus}
        orderProductUuid={orderProductUuid}
      />
      <NewNote orderUuid={orderProductUuid} />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonPadding: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 4,
    paddingLeft: 24,
    alignItems: "center",
    flexWrap: "wrap",
  },
  icon: {
    marginLeft: 6,
  },
  pageContainer: { flex: 1, width: width },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 100,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  metaData: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 4,
    color: Colors.light.brown,
  },
  metaDataTitle: {
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  space: {
    height: 20,
  },
  detailsContainer: {
    width: "100%",
  },
  detailPressable: {
    flexDirection: "row",
    alignItems: "center",
  },
});
