import Toast from "react-native-toast-message";
import { useMutation } from "react-query";
import instanceAXIOS from "../../services/api";

export type UploadInput = {
  isPrivate: boolean;
  fileName: string;
  orderProductUuid: string;
  fileExtension: string;
  isImage: boolean;
  uploadType: string;
  isClient?: boolean;
};

const getUploadKey = async (_data: UploadInput) => {
  let clientData;
  if (_data.isClient) {
    clientData = {
      fileName: _data.fileName,
      orderProductUuid: _data.orderProductUuid,
      fileExtension: _data.fileExtension,
      isImage: _data.isImage,
    };
  }
  const res = await instanceAXIOS.post(
    `/Uploads${_data?.isClient ? "/Client" : ""}`,
    _data?.isClient ? clientData : _data
  );
  return res.data;
};

export const useGetUploadKey = () => {
  return useMutation((_data: UploadInput) => getUploadKey(_data), {
    onSuccess: (data: any) => {
      return data;
    },
    onError: (error) => {
      Toast.show({
        type: "error",
        props: {
          message: "Unable to upload",
        },
      });
    },
  });
};

export default getUploadKey;
