import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
  Linking,
  Dimensions,
  Platform,
} from "react-native";
import { Modalize } from "react-native-modalize";
import ActionButton from "../../components/ActionButton";
import Header from "../../components/Header";
import Input from "../../components/Input";
import LoadingState from "../../components/LoadingState";
import ModalHeader from "../../components/ModalHeader";
import StatusPill from "../../components/StatusPill";
import UploadFrame from "../../components/UploadFrame";
import Colors from "../../constants/Colors";
import { useGetOrderDetails } from "../../hooks/orders/useGetOrderDetails";
import { useUpdateShippingAddress } from "../../hooks/orders/useUpdateShippingAddress";
import { useUpdateSocial } from "../../hooks/users/useUpdateSocial";
import { formatEventDate } from "../../services/utils";
import ProgressBar from "./ProgressBar";
import Step from "./Step";
import window from "../../constants/Layout";
import { Feather } from "@expo/vector-icons";
import Context from "../../context";

const { height, width } = Dimensions.get("window");

const data = [
  {
    id: 1,
    status: "Order Received",
    shortCode: "orderReceived",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Thank you for choosing Pressed Floral. We are eager to serve you and preserve your life’s special moments through fine art.",
  },
  {
    id: 2,
    status: "Blooms Received",
    shortCode: "bouquetReceived",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Congratulations! Your Blooms are now safely in our care. Refer to the photo on your account for the condition of your blooms upon arrival, and any notes made.",
  },
  {
    id: 3,
    status: "Quality Check",
    shortCode: "checkedOn",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Your blooms have been cared for multiple times since arrival to nourish our careful preservation process.",
  },
  {
    id: 4,
    status: "Drying",
    shortCode: "progress",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Your blooms are now in our extensive drying process to ensure all moisture has been removed so that your blooms can safely be sealed into a fine piece of art!",
  },
  {
    id: 5,
    status: "Preparing to Design",
    shortCode: "almostReadyToFrame",
    completed: false,
    summary:
      "Your blooms have finished our preservation process. We are excited to move forward in our process. Your blooms will be held in a climate-controlled environment until they are ready to be designed.",
  },
  {
    id: 6,
    status: "Ready to Design",
    shortCode: "readyToFrame",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Your blooms are now in the queue to be designed! Please refer to the latest Bi-Weekly Bloom Update Email to find your specific timeline. You will be given the opportunity to approve or disapprove of your frame design at the next step. Please note that our designers are professionals, we trust their eye for design. They have been trained to incorporate only the best blooms from your arrangement to ensure the quality of the frame meets our standards as well as yours. We are excited to begin creating your finished piece!",
  },
  {
    id: 7,
    status: "Design Completed",
    shortCode: "frameCompleted",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Your designer has completed your frame’s design! Please review the photo proof. If you wish to make any changes to your design, please let your designer know.",
  },
  {
    id: 8,
    status: "Preparing for Shipment",
    shortCode: "preparingToBeShipped",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "Now that your custom design is approved, the frame will be properly sealed and undergo multiple rounds of quality checks. Once it is up to our standards, we will securely package your piece to be prepared for shipment or pickup. At this time, please reach out to our fulfillment team regarding any questions or concerns.",
  },
  {
    id: 9,
    status: "Shipped",
    shortCode: "shipped",
    completed: false,
    dateCompleted: "2021-01-01",
    summary:
      "You will receive a separate email with tracking information as soon as your custom piece leaves our shop.",
  },
];
const ClientorderProductProducts: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const { order } = route.params;
  const [statusData, setStatusData] = useState(data);
  const [status, setStatus] = useState(null);
  const statusDetailsRef = useRef<Modalize>(null);
  const [detailsText, setDetailsText] = useState(0);
  const editShippingAddressRef = useRef<Modalize>(null);
  const socialsRef = useRef<Modalize>(null);
  const updateShippingAddress = useUpdateShippingAddress();
  const updateSocial = useUpdateSocial();
  const { setTabVisible } = useContext(Context);

  const {
    data: orderProduct,
    isLoading,
    isFetched,
    refetch,
  } = useGetOrderDetails(order?.uuid);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    control: socialControl,
    handleSubmit: socialHandleSubmit,
    setValue: socialSetValue,
    formState: { errors: socialErrors },
  } = useForm();

  useEffect(() => {
    setValue(
      "orderShippingAddressUuid",
      orderProduct?.orderShippingAddressUuid
    );
    setValue("orderShippingFirstName", orderProduct?.orderShippingFirstName);
    setValue("orderShippingLastName", orderProduct?.orderShippingLastName);
    setValue("orderShippingAddress1", orderProduct?.orderShippingAddress1);
    setValue("orderShippingAddress2", orderProduct?.orderShippingAddress2);
    setValue("orderShippingCity", orderProduct?.orderShippingCity);
    setValue("orderShippingState", orderProduct?.orderShippingState);
    setValue("orderShippingZip", orderProduct?.orderShippingZip);
    setValue("orderShippingCountry", orderProduct?.orderShippingCountry);
    socialSetValue("socialMediaLinks", orderProduct?.socialMediaLinks);
  }, [orderProduct]);

  const onSubmit = (data: any) => {
    updateShippingAddress.mutate(data);
    editShippingAddressRef?.current?.close();
  };

  const onSocialSubmit = (data: any) => {
    updateSocial.mutate(data);
    socialsRef?.current?.close();
  };

  useEffect(() => {
    let _status = orderProduct?.status;
    if (_status === "disapproved" || _status === "noResponse") {
      _status = "frameCompleted";
    } else if (_status === "approved" || _status === "readyToSeal") {
      _status = "preparingToBeShipped";
    }
    data.filter((item) => {
      if (item.shortCode === _status) {
        item.completed = true;
        setStatus(item);
      }
    });
  }, [orderProduct]);

  const _call = () => {
    Linking.openURL(`tel:${8016911821}`);
  };

  const _email = () => {
    const subject = encodeURIComponent(
      `Order Question - Order: #${orderProduct?.shopifyOrderNumber}`
    );
    const body = encodeURIComponent(
      `I have a question about my order. #${orderProduct?.shopifyOrderNumber}`
    );
    Linking.openURL(
      `mailto:info@pressedfloral.com?subject=${subject}&body=${body}`
    );
  };

  const _emailFlorist = () => {
    const subject = encodeURIComponent(
      `Order Question - Order: #${orderProduct?.shopifyOrderNumber}`
    );
    const body = encodeURIComponent(
      `I have a question about my order. #${orderProduct?.shopifyOrderNumber}`
    );
    Linking.openURL(
      `mailto:florist@pressedfloral.com?subject=${subject}&body=${body}`
    );
  };

  useEffect(() => {
    setStatusData(data);
  }, [data]);

  const _getPhotos = (photoType: string) => {
    let _photos = orderProduct?.orderProductUploads;
    _photos = _photos?.filter((item) => item.uploadType === photoType);
    return _photos?.length > 0 ? _photos : null;
  };

  return (
    <View style={styles.body}>
      <Header goBack />
      {!isLoading && isFetched ? (
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={isFetched ? isLoading : false}
              onRefresh={refetch}
            />
          }
          style={styles.scroll}
        >
          <View style={styles.innerContainer}>
            <>
              {orderProduct?.status !== "cancelled" ? (
                <>
                  <Text style={styles.progressContainer}>Progress:</Text>
                  {Platform.OS !== "web" ? (
                    <ProgressBar
                      data={statusData}
                      step={status?.id}
                      onPress={(index) => {
                        statusDetailsRef.current?.open();
                        setDetailsText(index);
                      }}
                    />
                  ) : (
                    <View
                      style={{
                        width: 600,
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <ProgressBar
                        data={statusData}
                        step={status?.id}
                        onPress={(index) => {
                          statusDetailsRef.current?.open();
                          setDetailsText(index);
                        }}
                      />
                    </View>
                  )}
                  <Step data={status} />
                  <Text style={styles.summaryText}>
                    {orderProduct?.status === "disapproved"
                      ? "We will review comments and will notify you when we have uploaded a new frame design. Estimated within 48 hours"
                      : status?.summary}
                  </Text>
                  {orderProduct?.status === "frameCompleted" ? (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate("ClientFrameApproval", {
                          uuid: orderProduct?.uuid,
                          frames: _getPhotos("frame"),
                          orderUuid: orderProduct?.orderUuid,
                          order: orderProduct,
                        });
                      }}
                      style={styles.button}
                    >
                      <View style={styles.row2}>
                        <Feather
                          name="alert-triangle"
                          size={20}
                          color="white"
                        />
                        <Text style={styles.approvalText}>
                          Frame Approval Requested
                        </Text>
                        <Feather name="chevron-right" size={18} color="white" />
                      </View>
                      <Text style={styles.approvalSubText}>
                        Click to review frame
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                </>
              ) : (
                <Text style={styles.progressContainer}>Order Cancelled</Text>
              )}
            </>
            <View style={styles.dataContainer}>
              <View style={styles.row}>
                <Text style={styles.textTitle}>Order:</Text>
                <Text style={styles.textValue}>
                  {`#${orderProduct?.shopifyOrderNumber}`}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.textTitle}>Product:</Text>
                <Text style={styles.textValue}>
                  {orderProduct?.productTitle}
                </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.quantity}>
                  <Text
                    style={styles.textTitle2}
                  >{`Quantity: ${orderProduct?.quantity}`}</Text>
                </View>
              </View>
              {orderProduct?.variantOption1 ? (
                <Text style={styles.textSubValue}>
                  {orderProduct?.variantOption1}
                </Text>
              ) : null}
              {orderProduct?.variantOption2 ? (
                <Text style={styles.textSubValue}>
                  {orderProduct?.variantOption2}
                </Text>
              ) : null}
              {orderProduct?.variantOption3 ? (
                <Text style={styles.textSubValue}>
                  {orderProduct?.variantOption3}
                </Text>
              ) : null}
              <View style={styles.row}>
                <Text style={styles.textTitle}>Event Date:</Text>
                <Text style={styles.textValue}>
                  {formatEventDate(orderProduct?.eventDate)}
                </Text>
              </View>
              <TouchableOpacity
                disabled={
                  orderProduct?.status === "shipped" ||
                  orderProduct?.status === "preparingToBeShipped" ||
                  orderProduct?.status === "approved"
                }
                onPress={() => {
                  editShippingAddressRef?.current?.open();
                }}
                style={styles.row}
              >
                <View style={styles.row}>
                  <Text style={styles.textTitle}>Shipping Address:</Text>
                  {orderProduct?.status !== "shipped" &&
                  orderProduct?.status !== "preparingToBeShipped" &&
                  orderProduct?.status !== "approved" ? (
                    <Feather
                      name="edit-2"
                      size={18}
                      color={Colors.light.brown}
                      style={{ marginLeft: 8 }}
                    />
                  ) : null}
                </View>
              </TouchableOpacity>
              <Text
                style={styles.textSubValueAddress}
              >{`${orderProduct?.orderShippingFirstName} ${orderProduct?.orderShippingLastName}`}</Text>
              <Text style={styles.textSubValueAddress}>{`${
                orderProduct?.orderShippingAddress1 || ""
              }`}</Text>
              {orderProduct?.orderShippingAddress2 ? (
                <Text style={styles.textSubValueAddress}>{`${
                  orderProduct?.orderShippingAddress2 || ""
                }`}</Text>
              ) : null}
              <Text style={styles.textSubValueAddress}>
                {`${orderProduct?.orderShippingCity || ""}, ${
                  orderProduct?.orderShippingProvince || ""
                }`}
              </Text>
              <Text style={styles.textSubValueAddress}>{`${
                orderProduct?.orderShippingCountry === "United States"
                  ? "US"
                  : orderProduct?.orderShippingCountry
              }, ${orderProduct?.orderShippingZip || ""}`}</Text>
              <TouchableOpacity
                onPress={() => {
                  socialsRef?.current?.open();
                }}
                style={styles.row}
              >
                <View style={styles.row}>
                  <Text style={styles.textTitle}>Socials:</Text>
                  <Feather
                    name="edit-2"
                    size={18}
                    color={Colors.light.brown}
                    style={{ marginLeft: 8 }}
                  />
                </View>
              </TouchableOpacity>
              <Text style={styles.textValue}>
                {orderProduct?.socialMediaLinks}
              </Text>
            </View>
            <View style={styles.containerMore}>
              <Text style={styles.textOther}>
                Custom Products in this Order:
              </Text>
              {orderProduct?.pressedFloralOrderLineItems?.map((item: any) => (
                <View style={styles.productContainerOther}>
                  <View style={styles.productRow}>
                    <Text style={[styles.productTitle, { color: "black" }]}>
                      Product:
                    </Text>
                    <Text style={[styles.productMetaData, { color: "black" }]}>
                      {item?.productTitle}
                    </Text>
                  </View>
                  <View style={styles.variantContainer}>
                    <Text style={[styles.productMetaData, { color: "black" }]}>
                      {`Quantity: ${item?.quantity}`}
                    </Text>
                    {orderProduct?.variantOption1 ? (
                      <Text
                        style={[styles.productMetaData, { color: "black" }]}
                      >
                        {item?.variantOption1}
                      </Text>
                    ) : null}
                    {orderProduct?.variantOption2 ? (
                      <Text
                        style={[styles.productMetaData, { color: "black" }]}
                      >
                        {item?.variantOption2}
                      </Text>
                    ) : null}
                    {orderProduct?.variantOption3 ? (
                      <Text
                        style={[styles.productMetaData, { color: "black" }]}
                      >
                        {item?.variantOption3}
                      </Text>
                    ) : null}
                  </View>
                </View>
              ))}
            </View>
            {orderProduct?.status !== "cancelled" ? (
              <>
                <UploadFrame
                  name={"Bouquet"}
                  orderProductUuid={orderProduct?.uuid}
                  imageUploadType={"Bouquet"}
                  uploads={_getPhotos("bouquet")}
                  hideUpload={true}
                />
                <UploadFrame
                  name={"Frame"}
                  orderProductUuid={orderProduct?.uuid}
                  imageUploadType={"Frame"}
                  uploads={_getPhotos("frame")}
                  hideUpload={true}
                />
                <UploadFrame
                  name={"Reordered Blooms"}
                  orderProductUuid={orderProduct?.uuid}
                  imageUploadType={"Florist"}
                  uploads={_getPhotos("florist")}
                  hideUpload={true}
                />
                <UploadFrame
                  name={"Upload your event photos here"}
                  orderProductUuid={orderProduct?.uuid}
                  imageUploadType={"Client"}
                  hideComments={true}
                  uploads={_getPhotos("client")}
                />
              </>
            ) : null}
            <Text
              style={[
                styles.textSubValueAddress,
                {
                  paddingHorizontal: 20,
                },
              ]}
            >
              To update your order please contact us:
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 20,
              }}
            >
              <ActionButton
                label={"Email"}
                color={Colors.light.navy}
                icon={<Feather name="mail" size={18} color={"white"} />}
                onPress={() => {
                  _email();
                }}
              />
              <ActionButton
                label={"Call"}
                color={Colors.light.navy}
                icon={<Feather name="phone" size={18} color={"white"} />}
                onPress={() => {
                  _call();
                }}
              />
            </View>
            <Text
              style={[
                styles.textSubValueAddress,
                {
                  paddingHorizontal: 20,
                },
              ]}
            >
              Reorder blooms:
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 20,
              }}
            >
              <ActionButton
                label={"Email"}
                color={Colors.light.navy}
                icon={<Feather name="mail" size={18} color={"white"} />}
                onPress={() => {
                  _emailFlorist();
                }}
              />
            </View>
            <View style={{ height: 100 }} />
          </View>
        </ScrollView>
      ) : (
        <LoadingState />
      )}
      <Modalize
        ref={statusDetailsRef}
        modalHeight={height * 0.8}
        HeaderComponent={<ModalHeader headerText={""} />}
        modalStyle={{
          backgroundColor: Colors.light.background,
        }}
        FooterComponent={
          <TouchableOpacity
            onPress={() => {
              statusDetailsRef.current?.close();
            }}
            style={styles.modalBackButtonContainer}
          >
            <Feather name="chevron-left" size={24} color={Colors.light.brown} />
            <Text style={styles.modalBackButtonText}>Back</Text>
          </TouchableOpacity>
        }
      >
        <View style={styles.modalContent}>
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: Colors.light.brown,
              marginBottom: 20,
              alignSelf: "center",
            }}
          >
            {`STEP: ${data[detailsText]?.id}`}
          </Text>

          <View style={styles.pillContainer}>
            <StatusPill size={"large"} status={data[detailsText]?.shortCode} />
          </View>
          <Text style={styles.summaryContainer}>
            {data[detailsText]?.summary}
          </Text>
        </View>
      </Modalize>
      <Modalize
        ref={editShippingAddressRef}
        modalHeight={height * 0.8}
        HeaderComponent={<ModalHeader headerText={"Update Shipping Address"} />}
        modalStyle={{
          backgroundColor: Colors.light.background,
        }}
        onOpen={() => {
          setTabVisible(false);
        }}
        onClose={() => {
          setTabVisible(true);
        }}
        FooterComponent={
          <TouchableOpacity
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            style={styles.modalBackButtonContainer}
          >
            <Feather name="save" size={24} color={Colors.light.brown} />
            <Text style={styles.modalBackButtonText}>Update</Text>
          </TouchableOpacity>
        }
      >
        <View style={styles.modalContent}>
          <Input
            name={"orderShippingFirstName"}
            placeholder={"First Name"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingLastName"}
            placeholder={"Last Name"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingAddress1"}
            placeholder={"Address Line 1"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingAddress2"}
            placeholder={"Address Line 2"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingCity"}
            placeholder={"City"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingProvince"}
            placeholder={"Province"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingCountry"}
            placeholder={"Country"}
            control={control}
            errors={errors}
          />
          <Input
            name={"orderShippingZip"}
            placeholder={"Zip"}
            control={control}
            errors={errors}
          />
        </View>
      </Modalize>
      <Modalize
        ref={socialsRef}
        modalHeight={height * 0.8}
        HeaderComponent={<ModalHeader headerText={"Add Socials"} />}
        modalStyle={{
          backgroundColor: Colors.light.background,
        }}
        FooterComponent={
          <TouchableOpacity
            onPress={() => {
              socialHandleSubmit(onSocialSubmit)();
            }}
            style={styles.modalBackButtonContainer}
          >
            <Feather name="save" size={24} color={Colors.light.brown} />
            <Text style={styles.modalBackButtonText}>Update</Text>
          </TouchableOpacity>
        }
      >
        <View style={styles.modalContent}>
          <Text
            style={{
              fontSize: 16,
              color: Colors.light.brown,
              fontFamily: "spectral-500",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Insert here your social media tags and any other vendors you would
            like tagged if featured on our Instagram
          </Text>
          <Input
            name={"socialMediaLinks"}
            placeholder={"Socials"}
            control={socialControl}
            errors={socialErrors}
          />
          <Text
            style={{
              fontSize: 12,
              color: Colors.light.brown,
              fontFamily: "spectral-500",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            By filling out this field you give permission for Pressed Floral to
            post your event photos and tag any of the above usernames
          </Text>
        </View>
      </Modalize>
    </View>
  );
};

export default ClientorderProductProducts;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: Colors.light.background,
  },
  scroll: {
    marginTop: 30,
  },
  innerContainer: {
    maxWidth: Platform.OS !== "web" ? window.window.width : 800,
    alignSelf: "center",
    width: "100%",
  },
  container: {
    marginTop: 40,
    fontSize: 18,
    marginLeft: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  approvalSubText: {
    fontSize: 16,
    fontFamily: "spectral-400",
    color: "white",
    marginLeft: 10,
    textAlign: "center",
    marginTop: 4,
  },
  productContainerOther: {
    width: "60%",
    borderRadius: 10,
    marginBottom: 4,
    backgroundColor: Colors.light.messageOther,
  },
  productTitle: {
    fontFamily: "spectral-700",
    fontSize: 16,
    marginLeft: 4,
    color: "#fff",
    paddingRight: 0,
  },
  productMetaData: {
    fontFamily: "spectral-600",
    fontSize: 14,
    color: "#fff",
    paddingLeft: 8,
  },
  productRow: {
    flexDirection: "row",
    backgroundColor: "transparent",
    padding: 10,
    paddingBottom: 0,
  },
  row2: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  summaryText: {
    fontFamily: "spectral-600",
    fontSize: 16,
    paddingHorizontal: 30,
    marginTop: 10,
    color: Colors.light.brown,
    marginBottom: 0,
    textAlign: "center",
  },
  approvalText: {
    fontSize: 16,
    fontFamily: "spectral-700",
    color: "white",
    marginLeft: 10,
  },
  progressContainer: {
    marginLeft: 30,
    fontSize: 20,
    fontFamily: "spectral-700",
    color: Colors.light.brown,
    marginBottom: 20,
  },
  icon: {
    marginLeft: 4,
  },
  button: {
    marginTop: 30,
    backgroundColor: Colors.light.red,
    marginHorizontal: 30,
    padding: 10,
    borderRadius: 12,
  },
  containerMore: {
    marginTop: 20,
    marginHorizontal: 30,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
  },
  variantContainer: {
    padding: 10,
    paddingTop: 0,
    marginLeft: 10,
    backgroundColor: "transparent",
  },
  flex: { flex: 1 },
  dataContainer: {
    marginTop: 20,
    marginHorizontal: 30,
    backgroundColor: Colors.light.messageOther,
    borderRadius: 10,
    padding: 20,
  },
  row: {
    marginTop: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  textTitle: {
    fontSize: 16,
    fontFamily: "spectral-700",
    color: Colors.light.brown,
  },
  textTitle2: {
    fontSize: 16,
    fontFamily: "spectral-700",
    color: Colors.light.background,
    flexDirection: "row",
  },
  textValue: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.lightBrown,
    marginLeft: 10,
  },
  textSubValue: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.lightBrown,
    marginLeft: 10,
  },
  textSubValueAddress: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.lightBrown,
    marginLeft: 10,
  },
  textOther: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.lightBrown,
    marginLeft: 10,
    marginBottom: 10,
  },
  modalBackButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 12,
    marginHorizontal: 30,
    marginBottom: 30,
  },
  modalBackButtonText: {
    fontSize: 20,
    fontFamily: "spectral-700",
    color: Colors.light.brown,
    marginLeft: 2,
  },
  modalContent: {
    paddingHorizontal: 30,
    maxWidth: 600,
    alignSelf: "center",
    width: "100%",
  },
  pillContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  summaryContainer: {
    fontFamily: "spectral-600",
    fontSize: 16,
    color: Colors.light.brown,
    marginBottom: 10,
    textAlign: "center",
  },
  quantity: {
    backgroundColor: Colors.light.green,
    marginLeft: 8,
    borderRadius: 4,
    paddingHorizontal: 8,
    marginTop: -6,
    borderWidth: 2,
    borderColor: Colors.light.background,
    flexDirection: "row",
  },
});
