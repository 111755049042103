import { Dimensions, StyleSheet, TouchableOpacity } from "react-native";
import Header from "../../components/Header";
import { View, Text } from "../../components/Themed";
import React, { useContext } from "react";
import Context from "../../context";
import Colors from "../../constants/Colors";
import Dropdown from "../../components/Dropdown";
import { useForm } from "react-hook-form";
import { useGetAssignedUsers } from "../../hooks/users/useGetAssignedUsers";
import DatePicker from "react-native-date-picker";
import { useOrderProductFilter } from "../../hooks/orders/useOrderProductFilter";
import FlashList from "../../components/FlashList";
import TileListItem from "../../components/TileListItem";
import LoadingState from "../../components/LoadingState";
import { formatDate, formatDateTime } from "../../services/utils";

const { width } = Dimensions.get("window");

export default function FilterFramesScreen() {
  const { user } = useContext(Context);
  const {
    control,
    formState: { errors },
  } = useForm();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [openStart, setOpenStart] = React.useState(false);
  const [openEnd, setOpenEnd] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = React.useState(new Date());
  const { data: staff } = useGetAssignedUsers(user?.uuid);

  const {
    data: orders,
    isLoading,
    fetchNextPage,
  } = useOrderProductFilter(selectedUser, startDate, endDate);

  React.useEffect(() => {
    staff &&
      user &&
      setUsers(
        staff?.map((x) => ({
          label: `${x.firstName} ${x.lastName}`,
          value: x.uuid,
        }))
      );
    staff?.length > 0 && setSelectedUser(staff[0].uuid);
  }, [user, staff]);

  const onStartChange = (event, selectedDate) => {
    setStartDate(selectedDate);
  };

  const onEndChange = (event, selectedDate) => {
    setEndDate(selectedDate);
  };

  const flatData = () => {
    const flatArray: any = [];
    orders?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <Text style={styles.titlePage}>My Frames</Text>
      <View style={styles.innerContainer}>
        {users && (
          <Dropdown
            name={"assignedToUserUuid"}
            label={"Staff"}
            control={control}
            errors={errors}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            value={selectedUser}
            items={users}
            setValue={setSelectedUser}
            required={false}
            width={width - 20}
          />
        )}
        <View style={styles.datePickers}>
          <View>
            <Text style={styles.label}>Start Date</Text>
            <TouchableOpacity
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              onPress={() => {
                setOpenStart(true);
              }}
            >
              <Text>{formatDate(startDate)}</Text>
            </TouchableOpacity>
            <DatePicker
              modal
              mode="date"
              open={openStart}
              date={startDate}
              onConfirm={(date) => {
                setOpenStart(false);
                setStartDate(date);
                onStartChange(null, date);
              }}
              onCancel={() => {
                setOpenStart(false);
              }}
            />
          </View>
          <View>
            <Text style={styles.label}>End Date</Text>
            <TouchableOpacity
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              onPress={() => {
                setOpenEnd(true);
              }}
            >
              <Text>{formatDate(endDate)}</Text>
            </TouchableOpacity>
            <DatePicker
              modal
              mode="date"
              open={openEnd}
              date={endDate}
              onConfirm={(date) => {
                setOpenEnd(false);
                setEndDate(date);
                onEndChange(null, date);
              }}
              onCancel={() => {
                setOpenEnd(false);
              }}
            />
          </View>
        </View>
        <FlashList
          data={flatData()}
          renderItem={({ item, index }) => (
            <TileListItem
              orderNumber={item.shopifyOrderNumber}
              orderStatus={item.status}
              orderDate={formatDate(item.orderDate)}
              assignedToName={`${item.assignedToUserFirstName} ${item.assignedToUserLastName}`}
              orderName={`${item.clientFirstName} ${item.clientLastName}`}
              eventDate={formatDate(item.eventDate)}
              orderNotes={index}
              orderProductUuid={item.uuid}
              image={item?.frameValetKey?.blobUri}
            />
          )}
          numColumns={2}
          estimatedItemSize={60}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyIcon="package"
              emptyText={selectedUser ? "No Orders Found" : "Select A Person"}
            />
          }
          loadingComponent={<LoadingState />}
          contentContainerStyle={styles.flatlist}
          isLoading={isLoading}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            fetchNextPage();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
  },
  innerContainer: {
    paddingHorizontal: 20,
  },
  datePickers: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  flatlist: {
    paddingBottom: 200,
  },
  label: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    marginRight: 10,
  }
});
