import { useQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetUser = (uuid: string) => {
  return useQuery(["getUser", uuid], () => getUser(uuid), {
    cacheTime: 240000,
  });
};

const getUser = async (uuid: string) => {
  const { data } = await instanceAXIOS.get(`/User/${uuid}`);
  return data;
};
