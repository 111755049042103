import Toast from "react-native-toast-message";
import { useInfiniteQuery } from "react-query";
import instanceAXIOS from "../../services/api";

export const useGetTasksByUser = (getTasksByUserInput: GetTasksByUserInput) => {
  const getTasksByUser = async (pageParam: { pageParam: number }) => {
    try {
      const res = await instanceAXIOS.post(
        `/GetUserTasks/${getTasksByUserInput.userUuid}`,
        {
          pageNumber: parseInt(pageParam?.pageParam) || 1,
          pageSize: getTasksByUserInput.pageSize,
          completed: getTasksByUserInput.completed,
        }
      );
      return res.data;
    } catch (err) {
      Toast.show({
        type: "error",
        props: {
          message: "There was an error fetching tasks",
        },
      });
      throw err;
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(["userTasks", getTasksByUserInput], getTasksByUser, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || lastPage.length < getTasksByUserInput.pageSize) {
        return undefined;
      }
      return pages.length + 1;
    },
  });

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};

export interface GetTasksByUserInput {
  userUuid: string;
  pageNumber: number;
  pageSize: number;
  completed: boolean;
}
