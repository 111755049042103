import { createRoot } from "react-dom/client";
import { AppRegistry, Platform } from "react-native";
import App from "./App";
import { registerRootComponent } from "expo";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

AppRegistry.registerComponent("App", () => App);

if (Platform.OS === "web") {
  const rootTag = createRoot(document.getElementById("root"));
  rootTag.render(<App />);
} else {
  registerRootComponent(App);
}
