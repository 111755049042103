import {
  Alert,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useRef } from "react";
import { Modalize } from "react-native-modalize";
import ModalHeader from "../../../components/ModalHeader";
import Colors from "../../../constants/Colors";
import FlashList from "../../../components/FlashList";
import LoadingState from "../../../components/LoadingState";
import StatusPill from "../../../components/StatusPill";
import { useUpdateOrderStatus } from "../../../hooks/orders/useUpdateOrderStatus";
import { useGetStatusLabel } from "../../../hooks/useGetStatusInfo";

const { height } = Dimensions.get("window");

interface UpdateOrderStatusProps {
  modalRef: any;
  currentStatus: string;
  orderProductUuid: string;
}

const statusData = [
  { label: "Order Received", value: "orderReceived" },
  { label: "Bouquet Received", value: "bouquetReceived" },
  { label: "Checked On", value: "checkedOn" },
  { label: "Progress", value: "progress" },
  { label: "Almost Ready to Frame", value: "almostReadyToFrame" },
  { label: "Ready to Frame", value: "readyToFrame" },
  { label: "No Response", value: "frameCompleted" },
  { label: "Approved", value: "approved" },
  { label: "Disapproved", value: "disapproved" },
  { label: "Ready To Seal", value: "readyToSeal" },
  { label: "Preparing to be Shipped", value: "preparingToBeShipped" },
  { label: "Shipped", value: "shipped" },
  { label: "Cancelled", value: "cancelled" },
];

const UpdateOrderStatus: React.FC<UpdateOrderStatusProps> = ({
  modalRef,
  currentStatus,
  orderProductUuid,
}) => {
  const updateOrderStatus = useUpdateOrderStatus();

  const addSpaceToCamelCase = (str: string) => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };

  const _updateStatus = (nextStatus: string) => {
    let statusLabel = addSpaceToCamelCase(nextStatus);

    Alert.alert(
      "Update Order Status",
      `Are you sure you want to update the status of this order to ${statusLabel}?`,
      [
        {
          text: "Cancel",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "Update",
          onPress: () => {
            _updateReady(nextStatus);
          },
        },
      ],
      { cancelable: false }
    );
  };

  const _updateReady = (nextStatus: string) => {
    updateOrderStatus.mutate({
      orderProductUuid: orderProductUuid,
      status: nextStatus,
    });
    modalRef.current?.close();
  };

  return (
    <Modalize
      ref={modalRef}
      modalHeight={height * 0.9}
      modalStyle={styles.modal}
      scrollViewProps={{
        showsVerticalScrollIndicator: false,
        scrollEnabled: false,
      }}
      withReactModal
      HeaderComponent={
        <ModalHeader
          headerText={"Update Status " + useGetStatusLabel(currentStatus)}
        />
      }
    >
      <View style={styles.body}>
        <View style={styles.list}>
          <FlashList
            data={statusData.filter(
              (item) =>
                item.value.toLowerCase() !== currentStatus.toLowerCase() &&
                item.value !== "cancelled"
            )}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => _updateStatus(item.value)}
                style={styles.button}
              >
                <StatusPill status={item.value} label={item.value} />
              </TouchableOpacity>
            )}
            estimatedItemSize={20}
            isLoading={false}
            loadingComponent={<LoadingState />}
            ListEmptyComponent={
              <LoadingState empty emptyIcon="user" emptyText="No users found" />
            }
          />
        </View>
      </View>
    </Modalize>
  );
};

const styles = StyleSheet.create({
  modal: {
    backgroundColor: Colors.light.background,
  },
  body: {
    height: height * 0.7,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.background,
  },
  list: {
    width: "98%",
    flex: 1,
  },
  button: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
});

export default UpdateOrderStatus;
