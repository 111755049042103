import React from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  View,
} from "react-native";
import window from "../../constants/Layout";
import Toast from "react-native-toast-message";
import Toaster from "../../components/Toaster";
import ModalHeader from "../ModalHeader";
import Colors from "../../constants/Colors";

interface Props {
  body: any;
  header?: boolean;
  headerText?: string;
  goBack?: boolean;
}

const ModalBody: React.FC<Props> = ({
  body,
  header,
  headerText,
  goBack = false,
}) => {
  const toastConfig = {
    success: (internalState) => (
      <Toaster type="success" message={internalState.props.message} />
    ),
    default: (internalState) => (
      <Toaster type="default" message={internalState.props.message} />
    ),
    error: (internalState) => (
      <Toaster type="error" message={internalState.props.message} />
    ),
    warning: (internalState) => (
      <Toaster type="warning" message={internalState.props.message} />
    ),
  };

  return (
    <>
      {header ? <ModalHeader headerText={headerText} goBack={goBack} /> : null}
      <KeyboardAvoidingView style={styles.container} behavior={"height"}>
        <Toast config={toastConfig} />
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.inner}>{body}</View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    flex: 1,
    padding: window.window.width * 0.1,
  },
  inner: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ModalBody;
