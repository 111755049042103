import { OneSignal } from "react-native-onesignal";
import DeviceInfo from "react-native-device-info";

export const registerForPushNotifications = async (uuid: string | null) => {
  if (!uuid) {
    OneSignal.logout();
    return;
  }
  let deviceId = await DeviceInfo.getUniqueId();

  OneSignal.login(deviceId);
  OneSignal.User.addTags({ uuid });
};

export default registerForPushNotifications;
