import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  GestureHandlerRootView,
  PinchGestureHandler,
  PanGestureHandler,
} from "react-native-gesture-handler";
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { Image } from "expo-image";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as FileSystem from "expo-file-system";
import { ResizeMode, Video } from "expo-av";
//pan gesture handler to view image in full screen
// import VideoPlayer from 'expo-video-player'
import VideoPlayer from "expo-video-player";

import Colors from "../../constants/Colors";
import reactQuery from "../../services/reactQuery";
import ImageViewer from "react-native-image-zoom-viewer";
import Context from "../../context";

const { height, width } = Dimensions.get("window");

interface ImagePreviewProps {}

const ImagePreview: React.FC<ImagePreviewProps> = ({ route }) => {
  const offset = useSharedValue(1);
  const scale = useSharedValue(1);
  const focalX = useSharedValue(0);
  const focalY = useSharedValue(0);
  const { setTabVisible } = useContext(Context);
  const navigation = useNavigation();
  const { image, isVideo, order, fileExtension, keepTabAway = false } = route.params;
  const [isLoaded, setIsLoaded] = useState(false);
  const videoRef = React.useRef(null);
  const [rettryCount, setRetryCount] = useState(0);
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

  useEffect(() => {
    if (isVideo) {
      getVideo();
    } else {
      setImagePreviewVisible(true);
    }
  }, [isVideo]);

  useEffect(() => {
    setTabVisible(false);
    return () => {
      keepTabAway ? setTabVisible(false) : setTabVisible(true);
    };
  }, []);

  const getVideo = async () => {
    let tmp = await FileSystem.getInfoAsync(
      FileSystem.documentDirectory + order?.uuid + "-video.mp4"
    );
    if (!tmp.exists || tmp?.size === 0) {
      _download();
    } else {
      setIsLoaded(true);
    }
  };

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: focalX.value },
        { translateY: focalY.value },
        { scale: scale.value },
        { translateX: -focalX.value },
        { translateY: -focalY.value },
      ],
    };
  });

  const _download = async () => {
    const videoUrl = image;
    const destinationUri =
      FileSystem.documentDirectory + order?.uuid + "-video.mp4";

    const downloadObject = FileSystem.createDownloadResumable(
      videoUrl,
      destinationUri
    );

    const result = await downloadObject.downloadAsync();

    if (result?.status === 200) {
      setIsLoaded(true);
    }
  };

  const playPause = async () => {
    const result = await videoRef?.current?.getStatusAsync();
    if (result?.isPlaying) {
      videoRef?.current?.pauseAsync();
      videoRef?.current?.setVolumeAsync(1, 0);
    } else {
      videoRef?.current?.playAsync();
      videoRef?.current?.setVolumeAsync(1, 0);
    }
  };

  const _refetchDetailsAndDownloadAgain = async () => {
    if (rettryCount === 0) {
      setIsLoaded(false);
      await reactQuery.refetchQueries(["getOrderDetails", order?.uuid]);
      _download();
      setRetryCount(1);
    }
  };

  return (
    <View style={styles.body}>
      <TouchableOpacity
        hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
        style={styles.shadowContainer}
        onPress={() => {
          navigation.goBack();
        }}
      >
        <View style={styles.icon}>
          <Feather name="x" size={24} color="white" />
        </View>
      </TouchableOpacity>

      <Animated.View style={[styles.container, animatedStyles]}>
        {!isVideo ? (
          <View style={{ flex: 1, width: "100%" }}>
            <ImageViewer
              saveToLocalByLongPress={false}
              imageUrls={[
                {
                  url: image,
                  props: {},
                },
              ]}
            />
          </View>
        ) : (
          <>
            {isLoaded ? (
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => {
                  playPause();
                }}
                style={{
                  width: width,
                  height: height,
                }}
              >
                <VideoPlayer
                  slider={{
                    thumbTintColor: Colors.light.background,
                    minimumTrackTintColor: Colors.light.blue,
                    maximumTrackTintColor: Colors.light.background,
                    style: {
                      width: width,
                      height: height,
                      marginBottom: 40,
                    },
                  }}
                  errorCallback={(error) => {
                    _refetchDetailsAndDownloadAgain();
                  }}
                  fullscreen={{
                    visible: false,
                  }}
                  timeVisible={true}
                  textStyle={{
                    color: Colors.light.background,
                    fontSize: 12,
                    fontWeight: "bold",
                    marginBottom: 40,
                  }}
                  icon={{
                    fullscreen: (
                      <Feather
                        name="maximize"
                        size={24}
                        color={Colors.light.background}
                      />
                    ),
                  }}
                  videoProps={{
                    shouldPlay: true,
                    resizeMode: ResizeMode.CONTAIN,
                    rotation: 180,

                    source: {
                      uri:
                        FileSystem.documentDirectory +
                        order?.uuid +
                        "-video.mp4",
                    },
                  }}
                />
              </TouchableOpacity>
            ) : (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size="large" color="white" />
              </View>
            )}
          </>
        )}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: "black",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  image: {
    flex: 1,
    width: "100%",
  },
  shadowContainer: {
    zIndex: 2,
    borderRadius: 20,
    position: "absolute",
    top: 50,
    left: 10,
  },
  icon: {
    height: 40,
    width: 40,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
  },
});

export default ImagePreview;
