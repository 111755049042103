import React, { ReactElement } from "react";
import { FlashList as ShopifyFlashList } from "@shopify/flash-list";
import { View } from "../Themed";
import { FlatList, Platform } from "react-native";

interface FlashListProps {
  data: any;
  renderItem: (item: any) => ReactElement;
  estimatedItemSize: number;
  ListEmptyComponent: ReactElement;
  isLoading?: boolean;
  loadingComponent?: ReactElement;
  [key: string]: any;
}

const FlashList: React.FC<FlashListProps> = ({
  data,
  renderItem,
  estimatedItemSize,
  ListEmptyComponent,
  isLoading = false,
  loadingComponent = null,
  ...rest
}) => {
  const flashListProps = {
    data,
    renderItem,
    estimatedItemSize,
    ...rest,
  };

  return (
    <>
      {!isLoading ? (
        <>
          {data?.length > 0 ? (
            <>
              {Platform.OS === "android" ? (
                <FlatList
                  data={data}
                  renderItem={renderItem}
                  keyExtractor={(item, index) => `${item.uuid}-${index}`}
                  ListEmptyComponent={ListEmptyComponent}
                  {...rest}
                />
              ) : (
                  <ShopifyFlashList {...flashListProps} />
                )}
              </>
          ) : (
            ListEmptyComponent
          )}
        </>
      ) : (
        loadingComponent
      )}
    </>
  );
};

export default FlashList;
