import { StyleSheet, View } from "react-native";
import React from "react";
import { Text } from "../Themed";
import { OrderStatus } from "../../types";
import {
  useGetStatusLabel,
  useGetStatusColor,
} from "../../hooks/useGetStatusInfo";

interface StatusPillProps {
  status: OrderStatus;
  small?: boolean;
  count?: number;
}

const StatusPill: React.FC<StatusPillProps> = ({ status, small, count }) => {
  return (
    <View
      style={[
        styles.body,
        {
          backgroundColor: useGetStatusColor(status),
        },
      ]}
    >
      <Text style={[styles.text, { fontSize: small ? 9 : 11 }]}>
        {useGetStatusLabel(status)}
        {count !== undefined ? ` - ${count}` : null}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    height: 24,
    paddingHorizontal: 11,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "white",
    fontWeight: "500",
    fontFamily: "spectral-700",
  },
});

export default StatusPill;
