import React, { FC, useContext } from "react";
import { StyleSheet, View, Text } from "react-native";
import Colors from "../../../../constants/Colors";
import Context from "../../../../context";
import { getInitials } from "../../../../services/utils";
import { Image } from "expo-image";

interface AvatarBubbleProps {
  users: any;
  isCustomer: boolean;
}

const AvatarBubble: FC<AvatarBubbleProps> = ({ users, isCustomer = false }) => {
  const { user } = useContext(Context);

  const userMinusMe = users?.filter(
    (_user: any) => _user?.userUuid !== user?.uuid
  );

  return (
    <View style={styles.body}>
      {userMinusMe?.length === 1 ? (
        <View
          style={{
            height: 54,
            width: 54,
            backgroundColor: Colors.light.lightBrown,
            borderRadius: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {userMinusMe[0]?.userAvatarUrl ? (
            <Image
              source={{
                uri: userMinusMe[0]?.userAvatarUrl,
                cacheKey: userMinusMe[0]?.userUuid,
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 25,
              }}
              placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
              contentFit="cover"
              transition={100}
            />
          ) : (
            <Text
              style={{
                fontSize: 20,
                fontFamily: "spectral-600",
                color: Colors.light.background,
              }}
            >
              {getInitials(
                `${userMinusMe[0]?.firstName} ${userMinusMe[0]?.lastName}`
              )}
            </Text>
          )}
        </View>
      ) : null}
      {userMinusMe?.length === 2 ? (
        <View
          style={{
            height: 54,
            width: 54,
            backgroundColor: Colors.light.lightBrownBackground,
            borderRadius: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {userMinusMe?.map((user: any, index: number) =>
            user?.userAvatarUrl ? (
              <Image
                source={{
                  uri: user?.userAvatarUrl,
                  cacheKey: user?.userUuid,
                }}
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 25,
                  top: index === 0 ? 2 : 21,
                  left: index === 0 ? 2 : null,
                  right: index === 1 ? 2 : null,
                  position: "absolute",
                  borderColor: Colors.light.background,
                  borderWidth: 2,
                }}
                placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
                contentFit="cover"
                transition={100}
              />
            ) : (
              <View
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 25,
                  top: index === 0 ? 2 : 21,
                  left: index === 0 ? 2 : null,
                  right: index === 1 ? 2 : null,
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.light.lightBrown,
                  borderColor: Colors.light.background,
                  borderWidth: 2,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,

                    fontFamily: "spectral-700",
                    color: Colors.light.background,
                  }}
                >
                  {getInitials(`${user?.firstName} ${user?.lastName}`)}
                </Text>
              </View>
            )
          )}
        </View>
      ) : null}
      {userMinusMe?.length >= 3 ? (
        <View
          style={{
            height: 54,
            width: 54,
            backgroundColor: Colors.light.lightBrownBackground,
            borderRadius: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {userMinusMe?.map((user: any, index: number) =>
            index < 3 ? (
              user?.userAvatarUrl ? (
                <Image
                  source={{
                    uri: user?.userAvatarUrl,
                    cacheKey: user?.userUuid,
                  }}
                  style={{
                    height: 28,
                    width: 28,
                    borderRadius: 25,
                    zIndex: index,
                    top: index === 0 ? 10 : 2,
                    left: index === 0 ? null : 4,
                    right: index === 1 ? null : 0,
                    position: "absolute",
                    borderColor: Colors.light.background,
                    borderWidth: 2,
                  }}
                  placeholder={"L9PPouRkQ.I9t3?wxuDhMdV@yEog"}
                  contentFit="cover"
                  transition={100}
                />
              ) : (
                <View
                  style={{
                    height: 28,
                    width: 28,
                    borderRadius: 25,
                    top: index === 0 ? 10 : 2,
                    left: index === 0 ? null : 4,
                    right: index === 1 ? null : 0,
                    zIndex: index,
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: Colors.light.lightBrown,
                    borderColor: Colors.light.background,
                    borderWidth: 2,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "spectral-800",
                      color: Colors.light.background,
                    }}
                  >
                    {getInitials(`${user?.firstName} ${user?.lastName}`)}
                  </Text>
                </View>
              )
            ) : null
          )}
          <View
            style={{
              height: 28,
              width: 28,
              borderRadius: 25,
              top: 26,
              left: 8,
              zIndex: -1,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors.light.lightBrown,
              borderColor: Colors.light.background,
              borderWidth: 2,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontFamily: "spectral-800",
                color: Colors.light.background,
              }}
            >
              {`+${userMinusMe.length - 2}`}
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};
export default AvatarBubble;

const styles = StyleSheet.create({
  body: {
    marginTop: -4,
    height: 60,
    width: 60,
    borderRadius: 30,
    backgroundColor: Colors.light.lightBrownBackground,
    justifyContent: "center",
    alignItems: "center",
  },
});
